import { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Layout from '../../../components/Layout';

import Loading from '../../../components/Loading';
import { navRoutes as NR } from '../../../constants';
import { Exercises } from '../../../api-calls';
import { useAuth } from '../../../context/auth';
import { useGeneralState } from '../../../context/general-state';
import Formats from './Formats';
import { replaceMediaKeysWithUrls } from '../../../helpers';

import { useCreateCompletedStep } from '../../../api-calls/exercises.queries';
import { useExercise } from '../../../context/exercise';

const initStep = {
  audioKey: '',
  correctOption: null,
  imageKey: '',
  options: null,
  subtitle: '',
  title: '',
};

const ExerciseStepContent = () => {
  const { stepId, exerciseId } = useParams();
  const navigate = useNavigate();
  const auth = useAuth();
  const { state: generalState } = useGeneralState();
  const { exercise } = useExercise();

  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(initStep);

  const location = useLocation();
  const to = location.state?.to;
  const { mutate: createCompletedStep } = useCreateCompletedStep({
    id: stepId,
    learnerId: auth.user.selectedLearnerId,
    withCoach: true,
  });
  const handleNext = async () => {
    if (!Number(stepId) || !auth.user.selectedLearnerId) {
      return navigate(NR.GENERAL.NOT_FOUND);
    }

    createCompletedStep(
      {
        id: stepId,
        learnerId: auth.user.selectedLearnerId,
        withCoach: true,
      },
      {
        onSuccess: () => {
          navigate(to || NR.COACH.LEARNERS_STUCK_AT);
        },
      }
    );
  };

  useEffect(() => {
    let mount = true;
    async function fetchData() {
      setLoading(true);

      const { data, error } = await Exercises.getStepById({
        stepId: stepId,
        exerciseId,
      });

      if (mount) {
        if (error?.statusCode === 404) {
          navigate(NR.GENERAL.NOT_FOUND);
        }

        setStep({
          ...replaceMediaKeysWithUrls(data, generalState?.preferredVoice),
        });
        setLoading(false);
      }
    }

    if (!Number(stepId) || !Number(exerciseId)) {
      navigate(NR.GENERAL.NOT_FOUND);
    } else {
      fetchData();
    }

    return () => {
      mount = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepId, exerciseId]);

  if (loading) return { Component: <Loading />, layout: 'general' };

  return Formats({
    exercise,
    handleNext,
    step,
    stuckAtReview: true,
  });
};

const ExerciseStep = (props) => {
  const { Component, layout = 'general' } = ExerciseStepContent(props);

  return (
    <Layout
      layout={layout}
      headerProps={{
        type: 'progress',
      }}
      image="secondaryColor"
    >
      {Component}
    </Layout>
  );
};

export default ExerciseStep;

import React, { useEffect, useState } from 'react';

import Icon from '../../../components/Icon';
import * as T from '../../../components/Typography';

import * as S from './style';

const CopyInviteLink = ({ textToCopy }) => {
  const [copied, setCopied] = useState(false);

  const copyCodeToClipboard = async () => {
    setCopied(false);
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopied(true);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Failed to copy text: ', err);
    }
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 750);
    }
  }, [copied]);
  return (
    <S.CopyLinkWrapper onClick={copyCodeToClipboard}>
      <S.CopyIconWrapper>
        <Icon
          icon={copied ? 'tick' : 'copy2'}
          color={copied ? 'green' : 'black'}
          width={copied ? '16' : '25'}
          height={copied ? '16' : '25'}
          ml="1"
        />
      </S.CopyIconWrapper>
      <S.CopyTextWrapper>
        <T.P weight="bold">Copy invite link</T.P>
        <T.P weight="regular">{textToCopy}</T.P>
      </S.CopyTextWrapper>
    </S.CopyLinkWrapper>
  );
};

export default CopyInviteLink;

import {
  useFetch,
  formatQueryError,
  usePatch,
  usePost,
} from '../utils/queries';
import { useQuery, useMutation } from 'react-query';
import { LEARNERS_BASE, EXERCISES_BASE } from '../constants';
import axios from 'axios';
import { queryClient } from './queryClient';

function useGetLearnerStats({ learnerId, options }) {
  const context = useFetch(`${LEARNERS_BASE}/${learnerId}/stats`, undefined, {
    ...options,
    queryKey: [`GET_LEARNER_STATS`, { id: learnerId }],
  });
  return { ...context, stats: context.data, error: formatQueryError(context) };
}

function useGetLearnerInfo({ id }) {
  const context = useFetch(`${LEARNERS_BASE}/${id}/user-info`, undefined);
  return {
    ...context,
    userInfo: context.data,
    error: formatQueryError(context),
  };
}

function useGetLearnersCount({ search }) {
  return useQuery(['GET_LEARNERS_COUNT', search], () => {
    return axios.get(`${LEARNERS_BASE}/count?search=${search}`);
  });
}

function useGetLearnersQuery(
  { searchValue, page, limit, learnerStatus, sortField, sortOrder },
  reactQueryOptions = {}
) {
  return useQuery(
    [
      'GET_LEARNERS',
      {
        searchValue,
        page,
        limit,
        status: learnerStatus,
        sortField,
        sortOrder,
      },
    ],
    () => {
      return axios.get(`${LEARNERS_BASE}/`, {
        params: {
          search: searchValue,
          page,
          limit,
          status: learnerStatus,
          sortField,
          sortOrder,
        },
      });
    },
    reactQueryOptions
  );
}

function useGetLearners({ page, limit, search = '' }) {
  const context = useFetch(
    `${LEARNERS_BASE}/`,
    { page, limit, search },
    {
      queryKey: `GET_LEARNERS`,
    }
  );

  return {
    ...context,
    learners: context.data,
    error: formatQueryError(context),
  };
}

function useGetLearnerNotes({ learnerId }) {
  const context = useFetch(`${LEARNERS_BASE}/${learnerId}/notes`, {
    learnerId,
  });

  return {
    ...context,
    notes: context.data,
    error: formatQueryError(context),
  };
}

function useAddLearnerNote({ learnerId, note }) {
  return usePost(
    `${LEARNERS_BASE}/${learnerId}/notes`,
    {
      note,
    },
    {
      options: {
        invalidateKeys: [
          {
            key: [`${LEARNERS_BASE}/${learnerId}/notes`, { learnerId }],
          },
          {
            key: [`${LEARNERS_BASE}/`],
          },
        ],
      },
    }
  );
}

function useUpdateLearnerNote({ note, noteId, learnerId }) {
  return usePatch(
    `${LEARNERS_BASE}/${learnerId}/notes/${noteId}`,
    {
      note,
    },
    {
      options: {
        invalidateKeys: [
          {
            key: [`${LEARNERS_BASE}/${learnerId}/notes`, { learnerId }],
          },
          {
            key: [`${LEARNERS_BASE}/`],
          },
        ],
      },
    }
  );
}

function useUpdateLearnerContactInfo({
  userId,
  preferredWaysSession,
  contactNumber,
  contactEmail,
  preferredWaysContact,
}) {
  return usePatch(
    `${LEARNERS_BASE}/${userId}/intro-meeting-info`,
    {
      preferredWaysSession,
      contactNumber,
      contactEmail,
      preferredWaysContact,
    },
    {
      options: {
        invalidateAll: true,
      },
    }
  );
}

function useGetLearnersByCoachId({ coachId }) {
  const context = useFetch(`${LEARNERS_BASE}/coach-id/${coachId}`, undefined);
  return {
    ...context,
    learners: context.data,
    error: formatQueryError(context),
  };
}

function useCoachConfirmsLearner() {
  return useMutation(
    async ({ learnerId, notes, confirm }) => {
      const { data } = await axios.post(
        `${LEARNERS_BASE}/${learnerId}/confirm`,
        {
          confirm,
          notes,
        }
      );
      return data;
    },
    {
      onSettled: () => queryClient.invalidateQueries(),
    }
  );
}

const useCreateStuckAt = ({ learnerId, stepId, pageUrl }) => {
  return usePost(
    `${LEARNERS_BASE}/${learnerId}/stuck-at`,
    {
      learnerId,
      stepId,
      pageUrl,
    },
    {
      options: {
        invalidateKey: [`${EXERCISES_BASE}`],
      },
    }
  );
};

const useUpdateActiveStatus = ({
  learnerId,
  activeStatus,
  activeStatusDate,
  inactiveReason,
}) => {
  return usePost(
    `${LEARNERS_BASE}/${learnerId}/active-status`,
    {
      learnerId,
      activeStatus,
      activeStatusDate,
      inactiveReason,
    },
    {
      options: {
        invalidateAll: true,
      },
    }
  );
};

const useUpdateLearnerMilestoneUpdate = ({ learnerId, milestoneId }) => {
  return usePatch(
    `${LEARNERS_BASE}/${learnerId}/milestones-updates`,
    {
      milestoneId,
    },
    {
      invalidateKeys: [
        {
          key: [`${EXERCISES_BASE}`, learnerId],
        },
        {
          key: ['GET_LEARNER_STATS', { id: learnerId }],
        },
      ],
    }
  );
};

const useGetLearnerCurrentMilestone = ({ learnerId }, options) => {
  const context = useFetch(
    `${LEARNERS_BASE}/${learnerId}/milestone`,
    undefined,
    {
      ...options,
    }
  );

  return {
    ...context,
    currentMilestone: context.data,
    error: formatQueryError(context),
  };
};

const useGetLearnerProfile = ({ learnerUserId }, options) => {
  const context = useFetch(
    `${LEARNERS_BASE}/${learnerUserId}/profile`,
    undefined,
    {
      queryKey: [`GET_LEARNER_PROFILE`, { id: learnerUserId }],
      ...options,
    }
  );

  return {
    ...context,
    learnerProfile: context.data,
    error: formatQueryError(context),
  };
};

const useGetLearnerStuckAt = ({ learnerUserId }, options) => {
  const context = useFetch(
    `${LEARNERS_BASE}/${learnerUserId}/stuck-at`,
    undefined,
    {
      ...options,
    }
  );

  return {
    ...context,
    stuckAt: context.data,
    error: formatQueryError(context),
  };
};

export {
  useGetLearnerStats,
  useGetLearners,
  useGetLearnerInfo,
  useUpdateLearnerContactInfo,
  useCoachConfirmsLearner,
  useGetLearnersByCoachId,
  useCreateStuckAt,
  useUpdateActiveStatus,
  useUpdateLearnerMilestoneUpdate,
  useGetLearnerCurrentMilestone,
  useGetLearnerProfile,
  useGetLearnerStuckAt,
  useGetLearnersQuery,
  useGetLearnersCount,
  useGetLearnerNotes,
  useAddLearnerNote,
  useUpdateLearnerNote,
};

import { Loading } from '../../../components';
import { useState, useEffect, useCallback } from 'react';
import { Facilitators } from '../../../api-calls';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/auth';
import { navRoutes } from '../../../constants';
import { TRAINING_DATA } from './training-content';

const Training = () => {
  const {
    user: { id },
  } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const getCurrentTrainingStep = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await Facilitators.getCompletedTrainingStepById({
        id,
      });

      const step = data?.completedTrainingStep;
      if (step === TRAINING_DATA.length) {
        return navigate(navRoutes.FACILITATOR.TRAINING_COMPLETE);
      }
      return navigate(
        navRoutes.FACILITATOR.TRAINING_STEP.replace(':stepId', step + 1)
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [id, navigate]);

  useEffect(() => {
    getCurrentTrainingStep();
  }, [getCurrentTrainingStep]);

  if (loading) {
    return <Loading />;
  }

  return null;
};

export default Training;

import { useState, useEffect, useCallback } from 'react';
import { Grid, Typography as T } from '../../../components';
import { useAuth } from '../../../context/auth';
import { Facilitators } from '../../../api-calls';
import { useLocation, useNavigate } from 'react-router-dom';
import { navRoutes, userRoles } from '../../../constants';
import { JoinOurCommunity } from 'components/JoinOurCommunity';
import { Divider } from 'antd';
import { FurtherResources } from 'components/FurtherResources';
import useCustomTrainingStepHook from 'pages/Coach/Training/useCustomTrainingStepHook';
import { CompleteTrainingCongratsDialog } from 'components/CompleteTrainingCongratsDialog';
import { TRAINING_DATA } from './training-content';

const { Row, Col } = Grid;

const TrainingComplete = () => {
  const {
    user: { id },
  } = useAuth();
  const { customTrainingResources } = useCustomTrainingStepHook();
  const navigate = useNavigate();
  const [, setLoading] = useState(false);
  const [step, setStep] = useState();
  const [showCongratsDialog, setShowCongratsDialog] = useState(false);
  const { state } = useLocation();

  useEffect(() => {
    if (!!state?.showCongrats) {
      setShowCongratsDialog(true);
      window.history.replaceState({}, document.title);
    }
  }, [state]);

  const hideCongratsDialog = useCallback(() => {
    setShowCongratsDialog(false);
  }, []);

  const getCurrentTrainingStep = async () => {
    try {
      setLoading(true);
      const { data } = await Facilitators.getCompletedTrainingStepById({
        id,
      });
      setStep(data?.completedTrainingStep);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCurrentTrainingStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (step < TRAINING_DATA.length)
    return navigate(navRoutes.FACILITATOR.TRAINING);

  return (
    <Row dir="column" jc="center">
      <Col w={[4, 10, 10]}>
        <T.H1 weight="bold" color="neutralMain">
          🎉 Training complete!
        </T.H1>
      </Col>
      <Col w={[4, 10, 10]} mt="2" mb="5">
        <T.P>
          You’ve now completed all the essential facilitator training. You’re
          ready to start onboarding some coaches and learners! Remember you can
          review any of the training as much as you like by clicking the
          Training section in your menu above.
        </T.P>
      </Col>

      <Col w={[4, 10, 10]}>
        <T.H2 weight="bold" color="neutralMain" mb="2">
          Here are the key points you need to know
        </T.H2>
        <T.P>
          You’ve now completed all the essential facilitator training. You’re
          ready to start onboarding some coaches and learners! Remember you can
          review any of the training as much as you like by clicking the
          Training section in your menu above.
        </T.P>
      </Col>
      <Col w={[4, 10, 10]}>
        <Divider />
      </Col>
      <Col w={[4, 10, 10]}>
        <JoinOurCommunity />
      </Col>
      <Col w={[4, 10, 10]}>
        <Divider />
      </Col>
      <Col w={[4, 10, 10]}>
        <FurtherResources customTrainingResources={customTrainingResources} />
      </Col>
      {showCongratsDialog ? (
        <CompleteTrainingCongratsDialog
          visible={showCongratsDialog}
          onCancel={hideCongratsDialog}
          role={userRoles.FACILITATOR}
        />
      ) : null}
    </Row>
  );
};

export default TrainingComplete;

import axios from 'axios';
import handleError from './format-error';
import { queryClient } from './queryClient';

import { ORGANISATIONS_BASE } from '../constants';

const updateOrganisationStatus = async ({ id, body, options } = {}) => {
  try {
    const { data } = await axios.patch(
      `${ORGANISATIONS_BASE}/${id}/status`,
      body
    );

    await queryClient.refetchQueries({
      queryKey: ['GET_ORGANISATIONS_COUNT', 'GET_ORGANISATIONS'],
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const signupOrganisation = async ({ id, body, options } = {}) => {
  try {
    const { data } = await axios.patch(
      `${ORGANISATIONS_BASE}/signup/${id}`,
      body
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getOrganisationByInviteToken = async ({ token, options } = {}) => {
  try {
    const { data } = await axios.get(
      `${ORGANISATIONS_BASE}/signup-token/${token}`
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getOrganisationTrainingProgress = async ({ organisationId, options }) => {
  try {
    const { data } = await axios.get(
      `${ORGANISATIONS_BASE}/${organisationId}/progress`
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export {
  updateOrganisationStatus,
  signupOrganisation,
  getOrganisationByInviteToken,
  getOrganisationTrainingProgress,
};

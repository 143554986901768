import { useEffect, useReducer } from 'react';
import { useAuth } from 'context/auth';
import { getOrganisationTrainingProgress } from 'api-calls/organisations';
import { userRoles } from 'constants/index';
import { useOrganisationDetails } from 'context/organisation-details';

const initialState = {
  completedCustomisation: false,
  facilitatorsCount: 0,
  coachesCount: 0,
  loading: true,
  progressError: '',
};
function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }
  return { ...state, ...value };
}

const useGetOrgProgress = () => {
  const [state, setState] = useReducer(reducer, {
    ...initialState,
  });
  const { user } = useAuth();
  const { organisationDetails } = useOrganisationDetails();

  const {
    completedCustomisation,
    facilitatorsCount,
    coachesCount,
    progressError,
    loading,
  } = state;

  const hasFacilitator =
    (user?.isFacilitator && user?.role === userRoles.ORGANISATION_ADMIN) ||
    !!facilitatorsCount;

  const getOrgProgress = async () => {
    try {
      setState({ loading: true, progressError: '' });
      const { data, error } = await getOrganisationTrainingProgress({
        organisationId: organisationDetails?.id,
      });
      if (error) {
        setState({ progressError: error, loading: false });
      } else {
        setState({
          ...data,
          coachesCount: Number(data?.coachesCount),
          facilitatorsCount: Number(data?.facilitatorsCount),
          loading: false,
          progressError: '',
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      setState({ loading: false, progressError: '' });
    }
  };

  useEffect(() => {
    if (organisationDetails?.id) {
      getOrgProgress();
    } else if (user.role === userRoles.ADMIN) {
      setState({
        completedCustomisation: true,
        facilitatorsCount: 0,
        coachesCount: 1,
        loading: false,
        progressError: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationDetails?.id]);

  return {
    completedCustomisation,
    facilitatorsCount,
    coachesCount,
    progressError,
    loading,
    hasFacilitator,
  };
};

export default useGetOrgProgress;

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tabs } from 'antd';
import UseDebounce from '../../Hooks/UseDebounce';

import { navRoutes, mediaTypes } from './../../constants';
import { useGetMediaCms, useGetMediaTags } from '../../api-calls/media.queries';
import Table from './Table';
import Search from './Search';
const { TabPane } = Tabs;

const MediaPage = () => {
  const [type, stepType] = useState(mediaTypes.IMAGE);
  const [exactMatch, setExactMatch] = useState(false);

  const [query, setQuery] = useState('');
  const debouncedSearch = UseDebounce(query, 500);
  const { media, isLoading, isError, error } = useGetMediaCms({
    type: type,
    query: debouncedSearch || '',
    exactMatch,
  });

  const { tags } = useGetMediaTags();
  const navigate = useNavigate();

  const handleTabChange = (key) => {
    if (key === 'new') {
      navigate(navRoutes.CMS.MEDIA_UPDATE.replace(':id', 'new'), {
        replace: true,
      });
    } else {
      stepType(key);
    }
  };

  if (isError) {
    return (
      <>
        <div>something went wrong</div>
        <div>{error.message}</div>
      </>
    );
  }

  return (
    <>
      <Tabs
        defaultActiveKey={mediaTypes.IMAGE}
        onChange={handleTabChange}
        tabBarExtraContent={
          <Search
            query={query}
            setQuery={setQuery}
            exactMatch={exactMatch}
            setExactMatch={setExactMatch}
          />
        }
        key={type}
        activeKey={type}
        style={{
          marginTop: 10,
          paddingLeft: 14,
          paddingRight: 14,
        }}
      >
        <TabPane tab="Images" key={mediaTypes.IMAGE} />
        <TabPane tab="Audio" key={mediaTypes.AUDIO} />
        {process.env.REACT_APP_IS_MEDIA_UPLOAD_ENABLED && (
          <TabPane tab="New File" key="new" />
        )}
      </Tabs>
      <Table data={media} tags={tags} loading={isLoading} />
    </>
  );
};

export default MediaPage;

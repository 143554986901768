import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import * as CS from '../style';
import * as T from '../../Typography';

export default function RowRadioButtonsGroup({
  label,
  helper,
  optional,
  labelColor,
  color,
  options = [],
  value,
  onChange,
  m,
  disabled,
  w,
  error,
  row = false,
  name,
}) {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <CS.Field w={w} disabled={disabled} {...m}>
      <FormControl>
        <FormLabel id={`group-label-${label}`}>
          {label && (
            <CS.Label htmlFor={label}>
              <T.P weight="bold" color={labelColor || color} m="0">
                {label}
              </T.P>
              {optional && <CS.OptionalTag ml="1">(optional)</CS.OptionalTag>}
            </CS.Label>
          )}
          {helper && (
            <T.P isSmall color={'neutral80'} mb="2" ml="2">
              {helper}
            </T.P>
          )}
        </FormLabel>
        <CS.RadioGroupWrapper>
          <RadioGroup
            aria-labelledby={`group-label-${label}`}
            name={name || `${label}-group-radio-buttons-group`}
            value={value}
            onChange={handleChange}
            row={row}
          >
            {options.map((option) => (
              <FormControlLabel
                key={`${label}-${option.value}`}
                value={option.value}
                control={<Radio />}
                label={<T.P color="neutral90">{option.label}</T.P>}
              />
            ))}
          </RadioGroup>
        </CS.RadioGroupWrapper>
      </FormControl>
      {error && (
        <T.P color="error" m="0" mt="1" mx={2}>
          {error}
        </T.P>
      )}
    </CS.Field>
  );
}

/* eslint-disable prettier/prettier */
import { useReducer } from 'react';

import * as T from '../../../../components/Typography';
import { BasicInput } from '../../../../components/Inputs';
import { Checkbox } from 'antd';
import * as S from './style';
import { Icon } from '../../../../components';
import { string, number, array, object } from 'yup';

import { MediaKeyInput } from '../../../Components';

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }

  return { ...state, ...value };
}

const getInitialState = (data) => ({
  form: data
    ? {
        ...data,
        options: data.options
          ? data.options.map((e, i) => ({ ...e, id: i }))
          : [{ id: 0, option: '' }],
        preFilledAnswer: data.preFilledAnswer
          ? data.preFilledAnswer.map((e, i) => ({ text: e, id: i, hide: !e }))
          : [{ id: 0, text: '', hide: false }],
      }
    : {
        options: [{ id: 0, option: '' }],
        correctAnswer: '',
        preFilledAnswer: [{ id: 0, text: '', hide: false }],
      },
  httpError: '',
  validationErrs: {},
  loading: false,
  submitLoading: false,
});

const DragAndDrop2 = ({ data, onChange, errors }) => {
  const [state, setState] = useReducer(reducer, getInitialState(data));
  const setFormData = (data) =>
    setState((prevState) => {
      const _preFilledAnswer =
        data?.preFilledAnswer || prevState?.form?.preFilledAnswer;
      const form = {
        ...prevState.form,
        ...data,
      };
      onChange({
        ...form,
        preFilledAnswer: _preFilledAnswer?.map((e) => e.text || null),
      });
      return { form };
    });

  const addOption = () => {
    const maxId = Math.max(...state.form.options.map((e) => e.id), 0);
    setFormData({
      options: [
        ...state.form.options,
        { id: maxId + 1, option: '', hide: false },
      ],
    });
  };
  const removeOption = (id) => {
    setFormData({
      options: state.form.options.filter((e) => e.id !== id),
    });
  };

  const addPreFilled = () => {
    const maxId = Math.max(...state.form.preFilledAnswer.map((e) => e.id), 0);
    setFormData({
      preFilledAnswer: [
        ...state.form.preFilledAnswer,
        { id: maxId + 1, text: '', hide: false },
      ],
    });
  };
  const removePreFilled = (id) => {
    setFormData({
      preFilledAnswer: state.form.preFilledAnswer.filter((e) => e.id !== id),
    });
  };
  const onOptionChange = (value, id) => {
    setFormData({
      options: state.form.options.map((e) => {
        if (e.id !== id) return e;
        return { ...e, option: value };
      }),
    });
  };
  const onPreFillCheckBoxChange = (value, id) => {
    setFormData({
      preFilledAnswer: state.form.preFilledAnswer.map((e) => {
        if (e.id !== id) return e;
        return { ...e, text: value ? '' : e.text, hide: value };
      }),
    });
  };

  const onPreFillTextChange = (value, id) => {
    setFormData({
      preFilledAnswer: state.form.preFilledAnswer.map((e) => {
        if (e.id !== id) return e;
        return { ...e, text: value };
      }),
    });
  };

  return (
    <div>
      <BasicInput
        label="Correct Answer"
        value={state.form.correctAnswer}
        handleChange={(correctAnswer) => setFormData({ correctAnswer })}
        m={{ mt: 5 }}
      />

      <MediaKeyInput
        label="Audio key"
        value={state.form.audioKey}
        handleChange={(audioKey) => setFormData({ audioKey })}
        m={{ mt: 5 }}
        error={errors.audioKey}
      />

      <T.P mb={2} mt={8} size="large" weight="bold">
        Options
      </T.P>

      {errors?.options && typeof errors.options === 'string' && (
        <T.P mb={2} mt={2} color="error">
          {errors.options}
        </T.P>
      )}

      {state.form.options.map((e, i) => {
        return (
          <>
            <BasicInput
              label={`Option ${i + 1}`}
              value={e.option}
              handleChange={(value) => {
                onOptionChange(value, e.id);
              }}
              m={{ mt: 5 }}
              error={errors?.options && errors?.options[i]?.option}
            />

            {state.form.options.length === 1 && i === 0 ? null : (
              <S.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => removeOption(e.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </S.Button>
            )}
          </>
        );
      })}
      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={addOption}
        disabled={state.form.options.find((e) => !e.option)}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add Option
      </S.Button>

      <T.P mb={2} mt={8} size="large" weight="bold">
        Pre Filled Options
      </T.P>

      {errors?.preFilledAnswer &&
        typeof errors.preFilledAnswer === 'string' && (
          <T.P mb={2} mt={2} color="error">
            {errors.preFilledAnswer}ß
          </T.P>
        )}

      {state.form.preFilledAnswer.map((e, i) => {
        return (
          <>
            <BasicInput
              label={`Pre-filled ${i + 1}`}
              value={e.text}
              handleChange={(value) => {
                onPreFillTextChange(value, e.id);
              }}
              m={{ mb: 2, mt: 5 }}
              disabled={e.hide}
              error={errors?.preFilledAnswer && errors?.preFilledAnswer[i]}
            />
            <Checkbox
              checked={e.hide}
              onChange={(value) =>
                onPreFillCheckBoxChange(value.target.checked, e.id)
              }
            >
              keep empty
            </Checkbox>
            {state.form.preFilledAnswer.length === 1 && i === 0 ? null : (
              <S.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => removePreFilled(e.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </S.Button>
            )}
          </>
        );
      })}

      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={addPreFilled}
        disabled={state.form.preFilledAnswer.find((e) => !e.text && !e.hide)}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add Pre Fill Option
      </S.Button>
    </div>
  );
};

const validationSchema = {
  options: array()
    .of(
      object().shape({
        id: number().required(),
        option: string().required('required field'),
      })
    )
    .required()
    .min(1),
  preFilledAnswer: array().of(string().notRequired()).notRequired(),
  correctAnswer: string().required('required field'),
  audioKey: string().required('required field'),
};
export { validationSchema };

export default DragAndDrop2;

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/auth';
import { types } from '../../../constants';
import { Col, Row } from '../../../components/Grid';
import { Coaches } from '../../../api-calls';
import { COACH } from '../../../constants/nav-routes';
import { BasicButton } from '../../../components/Button';
import Checkbox from '../../../components/Inputs/Checkbox/';
import * as helper from '../../../helpers';
import * as T from '../../../components/Typography';

const Onboarding1 = () => {
  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const coachId = user.id;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [availabilityHoursPerWeek, setAvailabilityHoursPerWeek] =
    useState(false);
  const [bestDayTime, setBestDayTime] = useState(false);

  const handleHoursPerWeek = (checked, value) => {
    setAvailabilityHoursPerWeek(checked ? value : '');
  };

  const handleTimeOfDay = (checked, value) => {
    setBestDayTime(checked ? value : '');
  };

  const handleClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { error } = await Coaches.updateCoach({
      coachId,
      availabilityHoursPerWeek,
      bestDayTime,
    });
    setLoading(false);
    if (!error) {
      setUser({ ...user, availabilityHoursPerWeek, bestDayTime });
      navigate(COACH.ONBOARDING_6);
    } else {
      setError(error.message);
    }
  };

  const { ONE_TO_TWO_HOURS, THREE_TO_FOUR_HOURS, FOUR_PLUS_HOURS } =
    types.availabilityHoursPerWeek;

  const { MORNING, AFTERNOON, EVENING, ANYTIME } = types.bestDayTime;

  return (
    <>
      <Row>
        <Col w={[4, 8, 8]}>
          <T.H2 color="neutralMain">Timing and capacity</T.H2>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 8, 8]} mt="3">
          <T.P color="neutralMain">
            So we know how many learners to refer to you, let us know your
            capacity and we can work how best to manage your time
          </T.P>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 8, 8]} mt="5">
          <T.H3 color="neutralMain">Timing and capacity (per week)</T.H3>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 8, 8]} mt="4">
          <Checkbox
            font="regular"
            handleChange={(checked) =>
              handleHoursPerWeek(checked, ONE_TO_TWO_HOURS)
            }
            checked={availabilityHoursPerWeek === ONE_TO_TWO_HOURS}
            label={ONE_TO_TWO_HOURS}
            plain
          />
        </Col>
        <Col w={[4, 8, 8]} mt="4">
          <Checkbox
            font="regular"
            handleChange={(checked) =>
              handleHoursPerWeek(checked, THREE_TO_FOUR_HOURS)
            }
            checked={availabilityHoursPerWeek === THREE_TO_FOUR_HOURS}
            label={THREE_TO_FOUR_HOURS}
            plain
          />
        </Col>
        <Col w={[4, 8, 8]} mt="4">
          <Checkbox
            font="regular"
            handleChange={(checked) =>
              handleHoursPerWeek(checked, FOUR_PLUS_HOURS)
            }
            checked={availabilityHoursPerWeek === FOUR_PLUS_HOURS}
            label={FOUR_PLUS_HOURS}
            plain
          />
        </Col>
      </Row>
      <Row>
        <Col w={[4, 8, 8]} mt="5">
          <T.H3 color="neutralMain">What time of day is best for you?</T.H3>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 8, 8]} mt="4">
          <Checkbox
            font="regular"
            handleChange={(checked) => handleTimeOfDay(checked, MORNING)}
            checked={bestDayTime === MORNING}
            label={helper.toTitleCase(MORNING)}
            plain
          />
        </Col>
        <Col w={[4, 8, 8]} mt="4">
          <Checkbox
            font="regular"
            handleChange={(checked) => handleTimeOfDay(checked, AFTERNOON)}
            checked={bestDayTime === AFTERNOON}
            label={helper.toTitleCase(AFTERNOON)}
            plain
          />
        </Col>
        <Col w={[4, 8, 8]} mt="4">
          <Checkbox
            font="regular"
            handleChange={(checked) => handleTimeOfDay(checked, EVENING)}
            checked={bestDayTime === EVENING}
            label={helper.toTitleCase(EVENING)}
            plain
          />
        </Col>
        <Col w={[4, 8, 8]} mt="4">
          <Checkbox
            font="regular"
            handleChange={(checked) => handleTimeOfDay(checked, ANYTIME)}
            checked={bestDayTime === ANYTIME}
            label={helper.toTitleCase(ANYTIME)}
            plain
          />
        </Col>
      </Row>
      <Row ai="end" style={{ flex: 1 }} mt="5">
        <Col w={[4, 8, 8]}>
          {error && <T.P color="error">{error}</T.P>}
          <BasicButton
            variant="primary"
            maxWidth="426px"
            loading={loading}
            to={COACH.ONBOARDING_6}
            handleClick={handleClick}
            disabled={!bestDayTime || !availabilityHoursPerWeek}
          >
            Continue
          </BasicButton>
        </Col>
      </Row>
    </>
  );
};

export default Onboarding1;

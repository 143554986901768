import {
  useEffect,
  createContext,
  useState,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { useQueryClient } from 'react-query';
import { Users } from './../api-calls';
import {
  useLogout,
  useResetStateAfterSwitchAccount,
} from './../api-calls/users.queries';

import ReactGA from 'react-ga4';
import { userRoles } from '../constants';

const initialUserState = {
  id: null,
  email: '',
  firstName: '',
  lastName: '',
  role: '',
  selectedLearnerId: null, // for coach when selected learner
  facilitatorId: null, // for coaches
  isFacilitator: false, // for organisation admins
  organisationId: null,
};

const AuthContext = createContext({
  user: initialUserState,
  setUser: () => {},
  logout: async () => {},
  setOverlayColor: () => {},
  resetStateAfterSwitchAccount: async () => {},
});

const getUserInfoFromStorage = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user && user.id) {
    if (user.role === userRoles.LEARNER || user.role === userRoles.COACH) {
      ReactGA.set({
        ...user,
      });
    }
    return user;
  } else {
    return initialUserState;
  }
};

const storeUserInfoIntoStorage = (user) => {
  localStorage.setItem('user', JSON.stringify(user));
};

const clearDataFromStorage = (user) => {
  localStorage.removeItem('user');
  sessionStorage.removeItem('SEEN_SPLASH');
};

const AuthProvider = (props) => {
  const queryClient = useQueryClient();
  const [user, setUser] = useState(getUserInfoFromStorage);
  const { mutateAsync: logoutApi } = useLogout();
  const { mutateAsync: resetState } = useResetStateAfterSwitchAccount();

  const _setUser = useCallback((data) => {
    // set User in local storage
    storeUserInfoIntoStorage(data);
    // set user in state
    if (
      data?.activeOrganisationsWithActivatedStatus?.find(
        (u) =>
          u.role === userRoles.FACILITATOR &&
          u.organisationId === data?.organisationId
      )
    ) {
      data.isFacilitator = true;
    }
    setUser(data);
  }, []);

  const getUserInfo = useCallback(
    async ({ organisationId = null, userId, role } = {}) => {
      const { data } = await Users.getLoggedInUserInfo({
        organisationId,
        userId,
        role,
      });

      if (data) {
        _setUser(data);
      } else {
        clearDataFromStorage();
        setUser(initialUserState);
      }
    },
    [_setUser]
  );

  const resetStateAfterSwitchAccount = useCallback(async () => {
    await resetState();
  }, [resetState]);

  const getCSRFToken = useCallback(async () => {
    await Users.getCSRFToken();
  }, []);

  useEffect(() => {
    getUserInfo();
    getCSRFToken();
  }, [getUserInfo, getCSRFToken]);

  const logout = useCallback(
    async ({ isLearner = false }) => {
      _setUser({ logout: true, isLearner });
      await logoutApi();

      clearDataFromStorage();
      queryClient.removeQueries();
    },
    [logoutApi, _setUser, queryClient]
  );

  const setOverlayColor = useCallback(
    (color) => {
      _setUser({ ...user, overlayColor: color });
    },
    [user, _setUser]
  );

  const value = useMemo(
    () => ({
      user,
      getUserInfo,
      setUser: _setUser,
      logout,
      setOverlayColor,
      selectedLearnerId:
        user?.role === userRoles.LEARNER ? user.id : user?.selectedLearnerId,
      resetStateAfterSwitchAccount,
    }),
    [
      user,
      getUserInfo,
      _setUser,
      logout,
      setOverlayColor,
      resetStateAfterSwitchAccount,
    ]
  );

  return <AuthContext.Provider value={value} {...props} />;
};

const useAuth = () => {
  const value = useContext(AuthContext);
  return value;
};

export { AuthProvider, useAuth };
export default AuthContext;

import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../../context/auth';
import { navRoutes } from './../../../constants';
import { Col, Row } from '../../../components/Grid';
import { BasicButton } from '../../../components/Button';
import StartCoaching from '../../../components/StartCoaching';
import Modal from '../../../components/Modal';
import InfoCard from '../../../components/InfoCard';
import * as T from '../../../components/Typography';
import * as S from './style';
import useCustomTrainingStepHook from '../Training/useCustomTrainingStepHook';
import { Loading, OnboardingModal, StatsChart } from 'components';
import {
  useGetCoachDetails,
  useGetCoachStuckAtLearners,
  useUpdateCoach,
} from 'api-calls/coaches.queries';
import { useGetLearners } from 'api-calls/learners.queries';
import { COACH, COMMON } from 'constants/nav-routes';
import { JoinOurCommunity } from 'components/JoinOurCommunity';
import TrainingBanner from 'components/TrainingBanner';
import { getGreetings } from 'helpers';
import { Stack } from '@mui/material';
import theme from 'theme';
import { ONBOARDING_COACH_STATIC_DATA } from 'components/OnboardingModal/onboardingModalStaticData';
import FeedbackModal from '../../../components/FeedbackModal';
import NewLearners from './NewLearners';

const Dashboard = () => {
  const { learners = [], isLoading: isLearnersLoading } = useGetLearners({});
  const { learnersStuckAt, isLoading: isStuckAtLoading } =
    useGetCoachStuckAtLearners();
  const { user } = useAuth();

  const [modalClicked, setModalClicked] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);

  const { coach, isLoading: isCoachLoading } = useGetCoachDetails({
    coachId: user.id,
  });
  const { mutate } = useUpdateCoach({
    coachId: user.id,
  });
  const { activeStep, trainingContent, isAllStepCompleted, isAPILoading } =
    useCustomTrainingStepHook();

  const activeStepData = trainingContent[activeStep];

  const currentLearners = learners?.filter((e) => e.onboarded && e.confirmed);
  const toOnboardLearners = learners?.filter(
    (e) => !e.onboarded && e.confirmed
  );
  const newLearners = learners?.filter((e) => !e.confirmed);

  const stuckLearners = learnersStuckAt?.reduce((acc, curr) => {
    if (!acc.find((item) => item.learnerId === curr.learnerId)) {
      acc.push(curr);
    }
    return acc;
  }, []);

  const storeModalIntoStorage = () => {
    localStorage.setItem('modal', JSON.stringify(true));
    setModalClicked(true);
  };

  useEffect(() => {
    const _modal = localStorage.getItem('modal');
    setModalClicked(_modal);
  }, [modalClicked]);

  useEffect(() => {
    if (coach && !coach?.onboarded) {
      setShowOnboarding(true);
    }
  }, [coach]);

  useEffect(() => {
    if (isModalVisible) {
      let root = document.getElementById('root');
      root?.setAttribute('aria-hidden', 'false');
    }
  }, [isModalVisible]);

  const handleCancel = () => {
    storeModalIntoStorage();
    setIsModalVisible(false);
  };

  const closeOnboarding = useCallback(() => {
    setShowOnboarding(false);
  }, []);

  const updateCoachOnboarded = useCallback(() => {
    closeOnboarding();
    mutate({
      coachId: user.id,
      onboarded: true,
    });
  }, [user, mutate, closeOnboarding]);

  const addMarginToCommunity =
    !!stuckLearners?.length ||
    !!newLearners?.length ||
    !!toOnboardLearners?.length;

  if (isAPILoading || isCoachLoading || isLearnersLoading || isStuckAtLoading) {
    return <Loading />;
  }

  return (
    <S.DashboardWrapper>
      <S.HeaderWrapper mb="7">
        <T.H1 id="welcome-message" color="neutralMain">
          {getGreetings()}, {user.firstName}!
        </T.H1>
        <StartCoaching />
      </S.HeaderWrapper>
      <S.ContentWrapper>
        <S.LeftColumn>
          {activeStepData ? (
            <TrainingBanner
              isAllStepCompleted={isAllStepCompleted}
              stepIndex={activeStep}
              stepData={activeStepData}
            />
          ) : null}
          <T.H2 mt="6" mb="3">
            Statistics
          </T.H2>
          <S.StatisticsWrapper>
            <StatsChart
              backgroundMode="light"
              statChartType="totalLearnersCount"
              value={coach?.totalLearners}
            />
            <StatsChart
              backgroundMode="dark"
              statChartType="milestone"
              value={coach?.completedMilestones || 0}
            />
            <StatsChart
              backgroundMode="light"
              statChartType="coachLearningTime"
              value={coach?.totalCoachingTime?.hours || 0}
            />
          </S.StatisticsWrapper>
          {currentLearners?.length ? (
            <>
              <T.H2 mt="6" mb="3">
                Current learners
              </T.H2>
              {currentLearners?.slice(0, 4).map((learner, idx) => (
                <InfoCard
                  key={idx}
                  mb={idx === currentLearners.length - 1 ? '0' : '3'}
                  firstColumnLabel="Learner ID"
                  firstColumnValue={learner.learnerUniqueId}
                  secondColumnLabel="Name"
                  secondColumnValue={learner.firstName}
                  buttonText="View"
                  to={COMMON.LEARNER.replace(':userId', learner.id)}
                />
              ))}
            </>
          ) : null}
        </S.LeftColumn>
        <S.RightColumn>
          <Stack spacing={'24px'}>
            {stuckLearners?.length ? (
              <div>
                <Stack
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  direction={'row'}
                  marginBottom={'12px'}
                >
                  <T.H2>Stuck learners ({stuckLearners?.length})</T.H2>
                  {stuckLearners?.length > 4 && (
                    <T.Link
                      to={navRoutes.COACH.LEARNERS_STUCK_AT}
                      color={theme.colors.primaryMain}
                      underline
                    >
                      See All
                    </T.Link>
                  )}
                </Stack>
                {stuckLearners?.slice(0, 4)?.map((learner, idx) => (
                  <>
                    <InfoCard
                      key={idx}
                      mb={idx === stuckLearners.length - 1 ? '0' : '3'}
                      firstColumnLabel="Learner ID"
                      firstColumnValue={learner.learnerUniqueId}
                      secondColumnLabel="Name"
                      secondColumnValue={learner.firstName}
                      buttonText={'View'}
                      to={navRoutes.COMMON.LEARNER.replace(
                        ':userId',
                        learner.learnerId
                      )}
                    />
                  </>
                ))}
              </div>
            ) : null}
            <NewLearners newLearners={newLearners} />
            {toOnboardLearners?.length ? (
              <div>
                <T.H2 mb="1">Learners to be onboarded</T.H2>
                {toOnboardLearners?.map((learner, idx) => (
                  <InfoCard
                    key={idx}
                    mb={idx === toOnboardLearners.length - 1 ? '0' : '3'}
                    firstColumnLabel="Learner ID"
                    firstColumnValue={learner.learnerUniqueId}
                    secondColumnLabel="Name"
                    secondColumnValue={learner.firstName}
                    buttonText={'Onboard'}
                    to={COACH.NEW_LEARNER.replace(
                      ':firstName',
                      learner.firstName
                    ).replace(':userId', learner.id)}
                    actionType={'onboard'}
                  />
                ))}
              </div>
            ) : null}

            <JoinOurCommunity column mt={addMarginToCommunity ? '5' : ''} />
          </Stack>
        </S.RightColumn>
      </S.ContentWrapper>

      <Modal
        visible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        onCancel={handleCancel}
      >
        <Row>
          <Col w={[4, 12, 12]} mb={4}>
            <T.H2 color="neutralSurface" weight="bold" mb={3}>
              You’ve finished training! You’re now ready to take on a learner
              and we will be in touch soon.
            </T.H2>
            <T.P color="neutralSurface" weight="regular">
              This might take a while but if there’s anything else you need in
              the meantime you can always contact Shannon Trust at{' '}
              <T.Link
                to={'mailto:chloe.bradbury@shannontrust.com'}
                external
                color="white"
                hoverColor="neutralMain"
                underline
              >
                chloe.bradbury@shannontrust.com
              </T.Link>
            </T.P>
          </Col>
        </Row>

        <BasicButton
          handleClick={handleCancel}
          variant="secondary"
          id="back-to-dashboard"
          aria-label="back-to-dashboard"
        >
          <T.P color="white" weight="bold">
            Okay, got it!
          </T.P>
        </BasicButton>
      </Modal>

      <OnboardingModal
        isModalOpen={showOnboarding}
        onClose={closeOnboarding}
        handleClick={updateCoachOnboarded}
        data={ONBOARDING_COACH_STATIC_DATA}
      />

      <FeedbackModal />
    </S.DashboardWrapper>
  );
};

export default Dashboard;

import { useState, useEffect } from 'react';

import {
  Grid,
  Typography as T,
  Button,
  Modal,
  Loading,
} from '../../../components';
import { useGetLearnerGoals } from '../../../api-calls/learnersGoals.queries';

import * as S from './style';
import { getMediaUrl } from '../../../helpers';
import { audio } from '../../../constants';
import { useGeneralState } from '../../../context/general-state';
import AddGoal from './AddGoal';
import { BackButton } from 'components/BackButton';
import { LEARNER } from 'constants/nav-routes';
import CompletedGoalsSection from './CompletedGoalsSection';
import UncompletedGoalsSection from './UncompletedGoalsSection';
import theme from 'theme';

const { Col, Row } = Grid;
const categorizeGoals = (data) => {
  return data.reduce(
    (result, g) => {
      if (g.completed) {
        return {
          _goals: result._goals,
          _completedGoals: [...result._completedGoals, g],
        };
      } else {
        return {
          _completedGoals: result._completedGoals,
          _goals: [...result._goals, g],
        };
      }
    },
    { _goals: [], _completedGoals: [] }
  );
};

const Goals = () => {
  const { data, isLoading, isFetching, isFetched, refetch } =
    useGetLearnerGoals({ enabled: false });

  const [goals, setGoals] = useState({ unCompleted: [], completed: [] });
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openCompleteModal, setOpenCompleteModal] = useState(false);

  const { state: generalState } = useGeneralState();
  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFetched) {
      const { _goals, _completedGoals } = categorizeGoals(data);
      setGoals({ completed: _completedGoals, unCompleted: _goals });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  const handleTaskCheck = async ({ goalId, taskId, completed, cb }) => {
    /* This code is needed for auto completing a goal. Commenting this logic for now as we've decided not to use this feature (https://github.com/yalla-coop/shannon-trust/issues/1107)
    const currentGoal = goals.unCompleted.filter((g) => g.id === goalId)[0];
    let isLastOne =
    currentGoal.tasks.filter((t) => t.completed).length ===
    currentGoal.tasks.length - 1;
    
    if (isLastOne && completed === false) {
      // mark the goal as completed
      await cb();
      setOpenCompleteModal(true);
      const _unCompletedGoals = goals.unCompleted.filter(
        (g) => g.id !== goalId
        );
        setGoals({
          unCompleted: _unCompletedGoals,
          completed: [currentGoal, ...goals.completed],
        });
        return null;
      }
      */

    const newGoals = goals.unCompleted.map((g) => {
      if (g.id === goalId) {
        const newTasks = g.tasks.map((t) => {
          if (t.id === taskId) {
            return { ...t, completed: !t.completed };
          } else {
            return t;
          }
        });

        return { ...g, tasks: newTasks };
      } else {
        return g;
      }
    });
    setGoals({ ...goals, unCompleted: newGoals });
  };

  const handleDeleteGoal = async ({ goalId, completed, cb }) => {
    await cb();
    refetch();
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      <S.BackWrapper>
        <BackButton link={LEARNER.DASHBOARD} />
      </S.BackWrapper>
      <S.PageWrapper>
        {/* page title */}
        <S.PlayButtonWrapper>
          <Button.PlayButton
            width="56px"
            height="56px"
            backgroundColor={theme.colors.neutralSurface}
            audioUrl={getMediaUrl(
              audio.myGoals,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: 27.2 }}
            fallbackText="My goals"
          />

          <T.H1 ml="3">My goals</T.H1>
        </S.PlayButtonWrapper>
        {/* add a new goal section */}
        <AddGoal
          goals={[...goals.completed, ...goals.unCompleted]}
          setGoals={setGoals}
          refetch={refetch}
          setOpenAddModal={setOpenAddModal}
        />
        {/* uncompleted goals section */}

        {goals.unCompleted.length > 0 && (
          <UncompletedGoalsSection
            goals={goals.unCompleted}
            refetch={refetch}
            handleTaskCheck={handleTaskCheck}
            setGoals={setGoals}
            handleDeleteGoal={handleDeleteGoal}
            setOpenModal={setOpenCompleteModal}
          />
        )}

        {/* completed goals section */}

        {goals.completed.length > 0 && (
          <CompletedGoalsSection
            goals={goals.completed}
            refetch={refetch}
            handleTaskCheck={handleTaskCheck}
            setGoals={setGoals}
          />
        )}
      </S.PageWrapper>
      {/* Add complete modal */}

      <Modal
        visible={openAddModal}
        setIsModalVisible={setOpenAddModal}
        aria-label="goal-completed"
        aria-modal="true"
        role="dialog"
        id="add-goal"
        title="Title"
        bgColor={'quinaryLight'}
      >
        <Row>
          <Col w={[4, 12, 12]}>
            <T.H2 mb="4">Goal added successfully!</T.H2>
            <T.P>
              You will find the goal on Not completed goals, you can click on it
              to see the goal details
            </T.P>
          </Col>
          <Col w={[4, 12, 12]} mt={4}>
            <Button.BasicButton
              handleClick={() => setOpenAddModal(false)}
              icon="tick"
              variant="tertiary"
            />
          </Col>
        </Row>
      </Modal>

      {/* Complete goal modal */}
      <Modal
        bgColor="primaryMain"
        visible={openCompleteModal}
        setIsModalVisible={setOpenCompleteModal}
        aria-label="goal-completed"
        aria-modal="true"
        role="dialog"
        id="completed-goal"
        title="Title"
      >
        <Row>
          <Col w={[4, 12, 12]} jc="center" mt={3}>
            <Button.PlayButton
              color="white"
              audioUrl={getMediaUrl(
                audio.goalCompleted,
                true,
                generalState?.preferredVoice
              )}
              fallbackText="Goal completed! This has now moved to your completed goals"
            >
              Goal completed! This has now moved to your completed goals
            </Button.PlayButton>
          </Col>

          <Col w={[4, 12, 12]} mt={4}>
            <Button.BasicButton
              handleClick={() => setOpenCompleteModal(false)}
              icon="tick"
              variant="secondary"
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default Goals;

import { Spin } from 'antd';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import setMargin from './../../../helpers/set-margin';
import isColorBright from 'helpers/isColorBright';

export const decideBackgroundBasic = (
  variant,
  theme,
  bgColor,
  opacity = '',
  overlayColor
) => {
  if (overlayColor && (bgColor === 'primaryMain' || variant === 'primary')) {
    return overlayColor !== 'default'
      ? `${theme.colors.neutralMain}${opacity}`
      : `${theme.colors.primaryMain}${opacity}`;
  }
  if (bgColor) {
    return `${theme.colors[bgColor]}${opacity}` || `${bgColor}${opacity}`;
  }
  switch (variant) {
    case 'primary':
      return `${theme.colors.primaryMain}${opacity}`;
    case 'secondary':
      return `${theme.colors.neutralMain}${opacity}`;
    case 'tertiary':
      return `${theme.colors.neutralLight}${opacity}`;
    default:
      return 'transparent';
  }
};

export const decideBackgroundHoverBasic = (
  variant,
  theme,
  opacity = '',
  disabled
) => {
  if (disabled) {
    return null;
  }
  switch (variant) {
    case 'primary':
      return `${theme.colors.secondaryMain}${opacity}`;
    case 'secondary':
      return `${theme.colors.neutralMain80}${opacity}`;
    case 'tertiary':
      return `${theme.colors.neutralLight}${opacity}`;

    default:
      return 'transparent';
  }
};

export const decideBackgroundBasicSound = (
  variant,
  theme,
  bgColor,
  opacity = '',
  overlayColor
) => {
  if (overlayColor && (bgColor === 'primaryMain' || variant === 'primary')) {
    return overlayColor !== 'default'
      ? `${theme.colors.neutralMain}${opacity}`
      : `${theme.colors.primaryMain}${opacity}`;
  }
  if (bgColor) {
    return `${theme.colors[bgColor]}${opacity}` || `${bgColor}${opacity}`;
  }
  switch (variant) {
    case 'primary':
      return `${theme.colors.primaryMain}${opacity}`;
    case 'secondary':
      return `${theme.colors.secondaryMain}${opacity}`;
    case 'tertiary':
      return `${theme.colors.secondaryLight}${opacity}`;
    default:
      return 'transparent';
  }
};

export const decideBackgroundHoverBasicSound = (variant, theme) => {
  switch (variant) {
    case 'primary':
      return `${theme.colors.primaryMain90}`;
    case 'secondary':
      return `${theme.colors.neutralMain80}`;
    case 'tertiary':
      return `${theme.colors.neutral30}`;
    default:
      return 'transparent';
  }
};

const paddings = {
  small: '8px 16px',
  medium: '12px 24px',
  large: '16px',
};

const basicButtonCommonStyle = (props) =>
  css`
    display: flex;
    justify-content: ${props?.left ? 'left' : 'center'};
    align-items: center;
    text-align: ${props?.ta || 'left'} !important;

    position: relative;
    border: ${props?.borderColor
      ? `1px solid ${props?.theme.colors[props?.borderColor]}`
      : 'none'};
    border-radius: ${props?.borderRadius || '16px'};
    font-size: 16px;
    font-weight: ${props.weight || 'bold'};
    letter-spacing: 0.04rem;
    padding: ${props?.padding || paddings[props?.size || 'large']};
    transition: all 0.3s linear;

    /* selected */
    outline-style: ${props?.selected ? 'solid' : 'none'};
    outline-width: ${props?.selected ? '2px' : 'none'};
    outline-color: ${props?.selected
      ? props?.theme.colors[props?.focusOutlineColor || 'primaryMain']
      : 'none'};

    /* clicking style */
    :active {
      opacity: 0.9;
    }

    :hover {
      transform: scale(1.02);
    }

    :focus {
      box-shadow: ${props?.noOutline
        ? props?.theme.shadows.elevation1
        : 'inset 0px 0px 0px 2px rgba(5, 23, 48, 1)'};
      outline-style: ${props?.noOutline ? 'none' : 'solid'};
      outline-width: ${props?.noOutline ? 'none' : '3px'};
      outline-color: ${props?.noOutline
        ? 'none'
        : props?.theme.colors[props?.focusOutlineColor || 'primaryMain']};
      outline-offset: 3px;
    }

    cursor: ${props?.disabled || props?.loading ? 'not-allowed' : 'pointer'};
  `;

export const BasicButton = styled.button`
  ${setMargin};
  ${basicButtonCommonStyle};
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth};` : '')}
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '64px'};
  padding-top: 8px;
  padding-bottom: 8px;

  color: ${({ variant, theme, bgColor, opacity, overlayColor, customColor }) =>
    !!customColor
      ? theme.colors[customColor] || customColor
      : isColorBright(
          decideBackgroundBasicSound(
            variant,
            theme,
            bgColor,
            opacity,
            overlayColor
          )
        )
      ? theme.colors.neutralMain
      : theme.colors.white};
  background: ${({ variant, theme, bgColor, opacity, overlayColor }) =>
    decideBackgroundBasic(variant, theme, bgColor, opacity, overlayColor)};
  box-shadow: ${({ theme, variant }) =>
    variant === 'transparent' ? 'none' : theme.shadows.elevation1};

  /* for disabled style */
  opacity: ${({ disabled, loading }) => (disabled || loading ? 0.5 : 1)};
  ${({ theme }) => theme.media.mobile} {
    ${({ maxWidthM }) => (maxWidthM ? `max-width: ${maxWidthM};` : '')}
  }
`;

export const BasicButtonWithPlaySound = styled.button`
  ${setMargin};
  ${basicButtonCommonStyle};

  background: ${({ variant, theme, bgColor, opacity, overlayColor }) =>
    decideBackgroundBasicSound(variant, theme, bgColor, opacity, overlayColor)};
  color: ${({ variant, theme, bgColor, opacity, overlayColor }) =>
    isColorBright(
      decideBackgroundBasicSound(variant, theme, bgColor, opacity, overlayColor)
    )
      ? theme.colors.neutralMain
      : theme.colors.white};
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth};` : '')}
  min-width: ${({ width }) => width || '326px'};
  box-shadow: ${({ theme, variant }) =>
    variant !== 'transparent' && theme.shadows.elevation1};

  /* for disabled style */
  opacity: ${({ disabled, loading }) => (disabled || loading ? 0.8 : 1)};
  padding: 0;

  ${({ theme }) => theme.media.mobile} {
    min-width: ${({ width }) => width || '217px'};
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
`;

export const InnerTextAlign = styled.span`
  padding-top: 3px;
`;

export const Loading = styled(Spin)`
  span {
    line-height: 24px;
    margin-left: 10px;
    color: ${({ theme, color }) =>
      theme.colors[color] || color || theme.colors.white};
  }
`;

import axios from 'axios';
import handleError from './format-error';

const FACILITATORS_BASE = '/facilitators';

const updateFacilitatorStatus = async ({ id, body, options } = {}) => {
  try {
    const { data } = await axios.patch(
      `${FACILITATORS_BASE}/${id}/status`,
      body
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getFacilitatorById = async ({ facilitatorId, options } = {}) => {
  try {
    const { data } = await axios.get(`${FACILITATORS_BASE}/${facilitatorId}`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};
const getFacilitatorByProbationArea = async ({
  ProbationArea,
  options,
} = {}) => {
  try {
    const { data } = await axios.get(
      `${FACILITATORS_BASE}/probation-area/${ProbationArea}`
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getCompletedTrainingStepById = async ({ id, options } = {}) => {
  try {
    const { data } = await axios.get(
      `${FACILITATORS_BASE}/${id}/training-step`
    );

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const updateTrainingStep = async ({ id, step, options } = {}) => {
  try {
    const { data } = await axios.patch(
      `${FACILITATORS_BASE}/${id}/training-step`,
      { step }
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getFacilitatorInvite = async (inviteToken) => {
  try {
    const { data } = await axios.get(
      `${FACILITATORS_BASE}/signup-token/${inviteToken}`
    );
    return { data };
  } catch (error) {
    const err = handleError(error);
    return { error: err };
  }
};

const signup = async (form) => {
  try {
    const { data } = await axios.post(`${FACILITATORS_BASE}/signup/`, form);
    return { data };
  } catch (error) {
    const err = handleError(error);
    return { error: err };
  }
};

export {
  getFacilitatorById,
  getFacilitatorByProbationArea,
  getCompletedTrainingStepById,
  updateTrainingStep,
  updateFacilitatorStatus,
  getFacilitatorInvite,
  signup,
};

import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import handleError from '../api-calls/format-error';

/*
fetching data with useQuery:
useQuery({queryKey: [url, params], queryFn: () => fetcher()})
we use a combination of URL and params as a key, so that we don’t need to create new strings for keys
*/

const useFetch = (url, params, config = {}) => {
  const context = useQuery(
    [config?.queryKey || url, params],
    async () => {
      try {
        const { data } = await axios.get(url, { params });
        return data;
      } catch (error) {
        throw Error(error);
      }
    },
    {
      ...config,
      // pause requests if there is no key
      enabled: config?.enabled ?? !!url,
    }
  );

  return context;
};

/*
manipulating data (create / update / delete) with useMutation:
- we use the same query key [url, params] for matching purposes
- we have a generic abstraction for handling mutations
*/

function useGenericMutation(func, url, params, otherOptions = {}) {
  const queryClient = useQueryClient();

  const options = otherOptions?.options;

  return useMutation(func, {
    // successful request
    onMutate: async (data) => {
      // cancel any ongoing requests
      await queryClient.cancelQueries([url, params]);
      // store current data into variable
      const previousData = queryClient.getQueryData([url, params]);
      // if updater fn is there, set state with it otherwise override with new data
      queryClient.setQueryData([url, params], data);
      return previousData;
    },
    // If the mutation fails, use the context returned from onMutate to roll back to previous data
    onError: (err, _, context) => {
      queryClient.setQueryData([url, params], context);
    },
    // request finished
    onSettled: () => {
      // invalidate the query to keep the fresh state
      if (options?.invalidateAll) {
        // invalidate all ongoing queries
        queryClient.resetQueries(); // this will reset all queries to their initial state
        return queryClient.invalidateQueries();
      } else if (options?.invalidateKey) {
        // invalidate specific key
        queryClient.invalidateQueries(options.invalidateKey);
      } else if (options?.invalidateKeys) {
        // invalidate multiple keys
        options.invalidateKeys.forEach((item) => {
          queryClient.invalidateQueries(item.key);
        });
      }
      queryClient.invalidateQueries([url, params]);
    },
  });
}

function usePatch(url, params, { options } = {}) {
  return useGenericMutation((data) => axios.patch(url, data), url, params, {
    options,
  });
}

function usePost(url, params, { options } = {}) {
  return useGenericMutation((data) => axios.post(url, data), url, params, {
    options,
  });
}

function useDelete(url, params, { options } = {}) {
  return useGenericMutation((data) => axios.delete(url, data), url, params, {
    options,
  });
}

function formatQueryError(context) {
  return context.isError ? handleError(context.error) : null;
}

export {
  useFetch,
  usePost,
  usePatch,
  useDelete,
  formatQueryError,
  useGenericMutation,
};

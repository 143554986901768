import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Icon, Typography as T, Modal, Grid, Button } from '..';
import { navRoutes as R } from '../../constants';
import { Learners } from '../../api-calls';
import * as S from './style';

const { Row, Col } = Grid;

const StartCoachingButton = ({ id, user, learnerUniqueId, margin }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [learner, setLearner] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    let mount = true;

    const getLearner = async () => {
      setLoading(true);

      const { data } = await Learners.getLearnerByUniqueId({
        learnerUniqueId: learnerUniqueId,
      });

      if (mount) {
        if (data) {
          setLearner(data);
        }
        setLoading(false);
      }
    };

    if (learnerUniqueId) {
      getLearner();
    }

    return () => {
      mount = false;
    };
  }, [learnerUniqueId]);

  const learnerId = learner?.id ? learner.id : id || user?.selectedLearnerId;

  const handleStartCoaching = () => {
    navigate(R.COACH.LEARNER_DASHBOARD.replace(':id', learnerId));
  };

  return (
    <>
      <S.StartCoachingWrapper
        onClick={() =>
          learner.hasSelfLearning
            ? setIsModalVisible(true)
            : handleStartCoaching()
        }
        margin={margin}
      >
        <S.IconWrapper>
          <Icon icon={'userWithArrow'} width="32px" height="32px" />
        </S.IconWrapper>
        <T.P weight="bold" ml={3} color="neutralMain">
          Start coaching
        </T.P>
      </S.StartCoachingWrapper>
      {learner.hasSelfLearning && (
        <Modal visible={isModalVisible} setIsModalVisible={setIsModalVisible}>
          <Row>
            <Col w={[4, 12, 12]} jc="center">
              <T.P size="med" weight="bold" color="white">
                {`${learner.firstName} has done some self-learning since the last session. Want to review?`}
              </T.P>
            </Col>
            <>
              <Col w={[4, 12, 12]} mt={4}>
                <Button.BasicButton
                  to={R.COACH.REVIEW_LEARNER_SELF_LEARNING.replace(
                    ':id',
                    learnerId
                  )}
                  icon="tick"
                  loading={loading}
                  disabled={loading || !learnerId}
                  variant="secondary"
                  iconProps={{ width: '12', height: '12' }}
                />
              </Col>
              <Col w={[4, 12, 12]} mt={2}>
                <Button.BasicButton
                  to={R.COACH.LEARNER_DASHBOARD.replace(':id', learnerId)}
                  icon="cross"
                  loading={loading}
                  disabled={loading}
                  variant="tertiary"
                  iconProps={{
                    width: '12',
                    height: '12',
                    color: 'neutralMain',
                  }}
                />
              </Col>
            </>
          </Row>
        </Modal>
      )}
    </>
  );
};

export default StartCoachingButton;

import styled from '@emotion/styled';

import { Inputs } from '../../components';
import { Checkbox } from 'antd';

const { BasicInput } = Inputs;
const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 250px;

  button {
    cursor: pointer;
    width: 50px;
    background: none;
    border: none;
  }
`;

const Search = ({ query, setQuery, exactMatch, setExactMatch }) => {
  const handleChange = (v) => {
    setQuery(v);
  };

  return (
    <Wrapper onSubmit={(e) => e.preventDefault()}>
      <BasicInput
        handleChange={handleChange}
        placeholder="search in descriptions/keys"
      />
      <Checkbox
        checked={exactMatch}
        onChange={(value) => setExactMatch(value.target.checked)}
        style={{
          marginLeft: 'auto',
        }}
      >
        Exact match
      </Checkbox>
    </Wrapper>
  );
};

export default Search;

export const TRAINING_DATA = [
  {
    index: 1,
    title: 'How Turning Pages works',
    desc: 'Learn about the history and structure of Turning Pages',
    user: 'FACILITATOR',
    clickable: true,
    routeKey: 'TRAINING_STEP',
    param: ':stepId',
  },
  {
    index: 2,
    title: 'How to use this tool',
    desc: 'Learn how to navigate and utilise the Turning Pages tool effectively.',
    user: 'FACILITATOR',
    clickable: true,
    routeKey: 'TRAINING_STEP',
    param: ':stepId',
  },
  {
    index: 3,
    title: 'Adding / Recruiting coaches',
    desc: 'Learn how to recruit and add coaches to the Turning Pages program',
    user: 'FACILITATOR',
    clickable: true,
    routeKey: 'TRAINING_STEP',
    param: ':stepId',
  },
  {
    index: 4,
    title: 'Managing coaches',
    desc: 'Learn to manage coaches and access performance data',
    user: 'FACILITATOR',
    clickable: true,
    routeKey: 'TRAINING_STEP',
    param: ':stepId',
  },
  {
    index: 5,
    title: 'Identifying Learners',
    desc: 'Explore strategies to identify potential learners',
    user: 'FACILITATOR',
    clickable: true,
    routeKey: 'TRAINING_STEP',
    param: ':stepId',
  },
  {
    index: 6,
    title: 'Onboarding Learners',
    desc: 'Learn how to onboard learners and introduce the Turning Pages tool',
    user: 'FACILITATOR',
    clickable: true,
    routeKey: 'TRAINING_STEP',
    param: ':stepId',
  },
  // {
  //   index: 7,
  //   title: 'Monitoring and reporting',
  //   desc: 'Learn how to generate reports from the Turning Pages tool',
  //   user: 'FACILITATOR',
  //   clickable: true,
  //   routeKey: 'TRAINING_STEP',
  //   param: ':stepId',
  // },
];

export const TRAINING_CONTENT = [
  {
    index: 1,
    title: 'How Turning Pages works',
    introduction: `Turning Pages was first developed back in 2015 and has successfully been used to help thousands of people improve their reading. It was designed specifically for use with adults and is phonics based. If you are not familiar with phonics then don’t worry, you can find lots of helpful tutorials and guidance within this tool.\n\n Turning Pages consists of five manuals, which increase in difficulty. We refer to it as a pick up and go resource as it provides a great deal of structure and lots of helpful information and tips to guide coaches in using it. To create this digital version of Turning Page we have transposed all of the activities from our manualised versions of the programme.\n\nAnyone that has a reading level below entry level 3 can benefit from participating in this programme.`,
    user: 'FACILITATOR',
    videoURLs: [
      {
        url: 'https://www.youtube.com/embed/Arom6pKXKhw?si=hq5gEM1bPpmcDA6L',
        title: 'Welcome',
      },
    ],
  },
  {
    index: 2,
    title: 'How to use this tool',
    introduction:
      'In this short video tutorial we will begin by showing you how to use this tool.',
    user: 'FACILITATOR',
    videoURLs: [
      {
        url: 'https://www.youtube.com/embed/vp0oU2EVDo8?si=iFxs7HBb-PJ5TjJ3',
        title: 'Getting Started',
      },
      {
        url: 'https://www.youtube.com/embed/MQq293CgWvg?si=5qMixuysCDBDxmz0',
        title: 'Managing the Dashboard',
      },
      {
        url: 'https://www.youtube.com/embed/iBbh8pQqvTc?si=yOopERPLThub8vYI',
        title: 'Adding and Allocating Learners',
      },
    ],
  },
  {
    index: 3,
    title: 'Adding / Recruiting coaches',
    introduction:
      'In this short video tutorial we will show you the processes available both for recruiting and adding coaches.',
    user: 'FACILITATOR',
    videoURLs: [
      {
        url: 'https://www.youtube.com/embed/dVFNzn3A0qg?si=caEH3BDMdGmfcOnO',
        title: 'Adding Coaches',
      },
    ],
  },
  {
    index: 4,
    title: 'Managing coaches',
    introduction:
      'Within the tool you will have access to a range of information that will support you in managing your reading coaches. As well as adding/updating their personal information, you will be able to see what training coaches have been completed, which coaches have been assigned which learners and various performance data e.g. how many sessions have taken place, what progress has been made.',
    user: 'FACILITATOR',
  },
  {
    index: 5,
    title: 'Identifying Learners',
    introduction:
      'Whilst many people will ask for help and support to improve their reading, many will try to keep this issue hidden, often through shame and embarrassment. In this short tutorial we will discuss how your organisation or service will be able to promote this support and encourage people to take part. As a basic set of tips we would recommend:',
    user: 'FACILITATOR',
    items: [
      {
        type: 'TickList',
        list: [
          'Having questions regarding literacy included in assessment paperwork.',
          'Having leaflets, flyers and posters available or promote via social media.',
          'Ensuring all staff/volunteers are clear on the type of support available, who it is for and how this can be accessed.',
          'Encourage staff/volunteers to broach with their clients and discuss their responses.',
          'Host events which could promote reading.',
        ],
      },
    ],
    videoURLs: [
      {
        url: 'https://www.youtube.com/embed/mlSZi5taq7k?si=bC57vkI1onk_rW0w',
        title: 'Identifying Learners TPD',
      },
    ],
  },
  {
    index: 6,
    title: 'Onboarding Learners',
    introduction:
      'In this short video we will show you how to on-board a learner. This will include information on how to introduce the tool and to shows learners how it can be accessed and used.\n\nIn addition, the onboarding process provides an opportunity to discuss setting some personal goals with each learner and taking a baseline measure of their progress. This can help track their outcomes later down the line.',
    user: 'FACILITATOR',
    videoURLs: [
      {
        url: 'https://www.youtube.com/embed/YEpioTCqlJE?si=gzITkiB5IRSwlojb',
        title: 'Onboarding',
      },
    ],
  },
  // {
  //   index: 7,
  //   title: 'Monitoring and reporting',
  //   introduction:
  //     'In this short video we will show you how to pull reports from the tool, which can be used to monitor ongoing performance.',
  //   user: 'FACILITATOR',
  //   videoURLs: [{ url: 'https://www.youtube.com/embed/LfsXExFeBg0' }],
  // },
];

export const LINK_LIST = [
  {
    label: 'Link 1',
    url: 'http://www.example.com',
  },
  {
    label: 'Link 2',
    url: 'http://www.example.com',
  },
  {
    label: 'Link 3',
    url: 'http://www.example.com',
  },
];

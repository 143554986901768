import React from 'react';

const LeftChevron = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '8'}
    height={height || '14'}
    fill="none"
    viewBox="0 0 8 14"
  >
    <path
      stroke={color || 'currentColor'}
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.875"
      d="M6.813 1.375 1.188 7l5.625 5.625"
    />
  </svg>
);

export default LeftChevron;

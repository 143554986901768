import { useState } from 'react';
import * as T from '../../../components/Typography';
import { userRoles } from '../../../constants';
import { Row, Col } from '../../../components/Grid';
import { Icon, ProfileStats, TextWithIcon } from '../../../components';
import { useAuth } from '../../../context/auth';
import { BasicButton } from './../../../components/Button';
import EditSection from './EditSection';
import { toTitleCase } from '../../../helpers';
import { Loading } from '../../../components';
import { useGetDefaultFacilitator } from '../../../api-calls/facilitator.queries';
import { useGetCoachDetails } from '../../../api-calls/coaches.queries';
import * as S from './style';
import { Divider } from 'components/Divider';
import { useMediaQuery } from 'react-responsive';
import theme from 'theme';
import { useOrganisationDetails } from 'context/organisation-details';
import useCustomTrainingStepHook from '../Training/useCustomTrainingStepHook';
import { navRoutes } from '../../../constants';
import { useDeleteUserPersonalInfo } from '../../../api-calls/users.queries';
import DeleteConfirmModal from './DeleteConfirmModal';
import SuccessModal from './SuccessModal';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.mobile})`,
  });

  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);

  const [isDeleteConfirmModalVisible, setIsDeleteConfirmModalVisible] =
    useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [showEditSection, setShowEditSection] = useState(false);
  const { user, logout } = useAuth();
  const {
    facilitator,
    error: getFacilitatorError,
    isLoading: getFacilitatorLoading,
  } = useGetDefaultFacilitator();
  const { coach, isError, error, isLoading } = useGetCoachDetails({
    coachId: user.id,
  });

  const {
    mutateAsync: deleteUserPersonalInfo,
    isLoading: isDeletingUserPersonalInfoLoading,
  } = useDeleteUserPersonalInfo({});

  const { organisationDetails } = useOrganisationDetails();
  const { isAllStepCompleted, activeStep } = useCustomTrainingStepHook();

  const handleLogout = async () => {
    try {
      await logout({ isLearner: false });
    } catch (e) {
      if (e) {
        setErrorMessage(
          e.message ||
            'Sorry there was an error logging you out. Please try again later.'
        );
      }
    }
  };

  const handleDelete = async () => {
    try {
      await deleteUserPersonalInfo({ id: user.id });
      setSuccessMessage('Your account has been deleted');
      setIsDeleteConfirmModalVisible(false);
      await handleLogout();
    } catch (e) {
      if (e) {
        setErrorMessage(
          e?.response?.data?.message ||
            'Sorry there was an error while deleting this user. Please try again later.'
        );
      }
    }
  };

  if (isLoading) return <Loading />;

  return (
    <>
      <Row jc={'center'}>
        <Col w={[4, 8, 8]} mb="4">
          <T.H2>Profile</T.H2>
        </Col>
      </Row>
      <Row jc={'center'}>
        <Col w={[4, 8, 8]}>
          <S.StatsWrapper>
            <ProfileStats
              type="coachingOnTheApp"
              value={coach.totalCoachingTime}
            />
            <ProfileStats
              backgroundColor="white"
              type="noOfLearners"
              learners={Number(coach.totalLearners)}
            />
          </S.StatsWrapper>
        </Col>
      </Row>
      <Row jc={'center'}>
        <Col w={[4, 8, 8]}>
          <Divider />
        </Col>
      </Row>
      <Row jc={'center'}>
        <Col w={[4, 8, 8]} mb="4">
          <T.H2>{showEditSection ? 'Edit Details' : 'Details'}</T.H2>
        </Col>
      </Row>
      {!showEditSection ? (
        <Row jc={'center'}>
          <Col w={[4, 8, 8]}>
            <S.CardWrapper>
              <Row>
                <Col w={[4, 9, 8]}>
                  <T.P color="neutral80"> Name</T.P>
                </Col>

                <Col w={[4, 9, 8]}>
                  <T.P color="neutralMain" weight="bold">
                    {coach.fullName || '-'}
                  </T.P>
                </Col>
              </Row>

              <Row mt="4">
                <Col w={[4, 9, 8]}>
                  <T.P color="neutral80"> Email address</T.P>
                </Col>

                <Col w={[4, 9, 8]}>
                  <T.P color="neutralMain" weight="bold">
                    {coach.email || '-'}
                  </T.P>
                </Col>
              </Row>
              <Row mt="4">
                <Col w={[4, 9, 8]}>
                  <T.P color="neutral80">Organisation name</T.P>
                </Col>

                <Col w={[4, 9, 8]}>
                  <T.P color="neutralMain" weight="bold">
                    {organisationDetails?.name || '-'}
                  </T.P>
                </Col>
              </Row>
              <Row mt="4">
                <Col w={[4, 9, 8]}>
                  <T.P color="neutral80"> City</T.P>
                </Col>

                <Col w={[4, 9, 8]}>
                  <T.P color="neutralMain" weight="bold">
                    {coach.city || '-'}
                  </T.P>
                </Col>
              </Row>
              <Row mt="4">
                <Col w={[4, 9, 8]}>
                  <T.P color="neutral80">phone number</T.P>
                </Col>

                <Col w={[4, 9, 8]}>
                  <T.P color="neutralMain" weight="bold">
                    {coach.phoneNumber || '-'}
                  </T.P>
                </Col>
              </Row>

              <S.CapacityTrainingWrapper mt="4">
                <Row>
                  <Col w={[4, 12, 12]}>
                    <T.P color="neutral80" display="flex">
                      <Icon
                        icon="clock"
                        width="16"
                        height="16"
                        color="neutral80"
                        mr="1"
                      />
                      Capacity
                    </T.P>
                  </Col>
                  <Col w={[4, 12, 12]}>
                    <T.P weight="bold" color="neutralMain">
                      {coach.availabilityHoursPerWeek || '-'} |{' '}
                      {(coach.bestDayTime && toTitleCase(coach.bestDayTime)) ||
                        '-'}
                    </T.P>
                  </Col>
                </Row>
                <Divider direction={isMobile ? 'horizontal' : 'vertical'} />
                <Row>
                  <Col w={[4, 12, 12]}>
                    <T.P color="neutral80" display="flex">
                      <Icon
                        icon="learningAndCoachingTime"
                        width="16"
                        height="16"
                        color="neutral80"
                        mr="1"
                      />{' '}
                      Training
                    </T.P>
                  </Col>
                  <Col w={[4, 12, 12]}>
                    {isAllStepCompleted ? (
                      <T.P weight="bold" color="neutralMain" display="flex">
                        <Icon
                          icon="check"
                          width="16"
                          height="16"
                          color="quinaryDark"
                          mr="1"
                        />{' '}
                        Completed
                      </T.P>
                    ) : (
                      <T.Link
                        to={navRoutes.COACH.TRAINING_STEP.replace(
                          ':stepId',
                          activeStep + 1
                        )}
                      >
                        <T.P weight="bold" color="neutralMain" display="flex">
                          Go to training
                        </T.P>
                      </T.Link>
                    )}
                  </Col>
                </Row>
              </S.CapacityTrainingWrapper>

              <Row mt="4" inner>
                <Col w={[4, 12, 12]}>
                  <BasicButton onClick={() => setShowEditSection(true)}>
                    Edit
                  </BasicButton>{' '}
                </Col>
              </Row>
            </S.CardWrapper>
          </Col>
        </Row>
      ) : (
        <Row jc={'center'}>
          <Col w={[4, 8, 8]}>
            <S.CardWrapper>
              <EditSection
                availableHoursPerWeek={coach.availabilityHoursPerWeek}
                bestTime={coach.bestDayTime}
                fName={coach.firstName}
                lName={coach.lastName}
                coachEmail={coach.email}
                city={coach.city}
                phoneNumber={coach.phoneNumber}
                setShowEditSection={setShowEditSection}
              />
            </S.CardWrapper>
          </Col>
        </Row>
      )}
      <Row jc={'center'}>
        <Col w={[4, 8, 8]}>
          <Divider />
        </Col>
      </Row>
      <Row jc={'center'}>
        <Col w={[4, 8, 8]}>
          <T.H2>I can’t coach anymore</T.H2>
        </Col>
      </Row>
      <Row jc={'center'}>
        <Col w={[4, 8, 8]} mt="2">
          <T.P>
            To arrange the handover of your learners and deactivate your account
            you need to email Shannon Trust
          </T.P>
        </Col>
      </Row>
      <Row jc={'center'}>
        <Col w={[4, 8, 8]} mt="4">
          <BasicButton
            to={`mailto:${facilitator?.email}`}
            external
            disabled={getFacilitatorLoading || getFacilitatorError}
            maxWidth="400px"
            variant="tertiary"
          >
            Email Shannon Trust
          </BasicButton>
        </Col>
      </Row>
      <Row jc={'center'}>
        <Col w={[4, 8, 8]}>
          <Divider />
        </Col>
      </Row>
      <Row jc={'center'}>
        <Col w={[4, 8, 8]}>
          <T.H2>Delete my account</T.H2>
        </Col>
      </Row>
      <Row jc={'center'}>
        <Col w={[4, 8, 8]} mt="2">
          <T.P>
            If you would like to delete your account and all personal data,
            please click the button below.
          </T.P>
        </Col>
      </Row>
      {user.role !== userRoles.ADMIN && (
        <Row jc={'center'} mt="4">
          <Col w={[4, 8, 8]} mt="2">
            <TextWithIcon
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              icon=""
              disabled={isDeletingUserPersonalInfoLoading}
              isButton={true}
              weight="bold"
              maxWidth="400px"
              text="Delete account"
              handleClick={() => {
                setIsDeleteConfirmModalVisible(true);
              }}
            />
          </Col>
        </Row>
      )}

      <SuccessModal
        successMessage={successMessage}
        isSuccessModalVisible={isSuccessModalVisible}
        setIsSuccessModalVisible={setIsSuccessModalVisible}
        onSuccessClick={() => {
          setIsSuccessModalVisible(false);
          navigate(navRoutes.GENERAL.HOME);
        }}
      />

      <DeleteConfirmModal
        isDeleteConfirmModalVisible={isDeleteConfirmModalVisible}
        setIsDeleteConfirmModalVisible={setIsDeleteConfirmModalVisible}
        onConfirm={() => {
          setIsDeleteConfirmModalVisible(false);
          handleDelete();
        }}
        onCancel={() => {
          setIsDeleteConfirmModalVisible(false);
        }}
      />
      {(isError || getFacilitatorError || errorMessage) && (
        <Row>
          <T.P color="error">
            {' '}
            {error?.message || getFacilitatorError.message || errorMessage}
          </T.P>
        </Row>
      )}
    </>
  );
};

export default Profile;

import OnboardModalStep1 from '../assets/modal/onboard-modal-step-1.png';
import OnboardModalStep2 from '../assets/modal/onboard-modal-step-2.png';
import OnboardModalStep3 from '../assets/modal/onboard-modal-step-3.png';
import OnboardModalStep4 from '../assets/modal/onboard-modal-step-4.png';
import OnboardModalStep5 from '../assets/modal/onboard-modal-step-5.png';
import OnboardModalStep6 from '../assets/modal/onboard-modal-step-6.png';

export const ONBOARDING_COACH_STATIC_DATA = [
  {
    isFirstTab: true,
    image: OnboardModalStep1,
    title: 'Welcome to Shannon trust',
    description: `Thank you for applying to become a coach with us at Shannon Trust. 
      Here is an introduction to Turning Pages and a step-by-step guide on how to use the tool. Click through each page to learn about helping learners and navigating your dashboard. `,
    width: '908px',
  },
  {
    image: null,
    title: 'What is Turning Pages?',
    description: (
      <>
        Shannon Trust first developed Turning Pages back in 2015 and have used
        this resource to support thousands of learners. An evaluation of the
        programme, conducted by Birmingham City University in 2016 showed that
        it is highly effective in supporting people to become readers. <br />
        Turning Pages is phonics based and has been developed specifically for
        adults. The programme consists of five different manuals, which
        increment in difficulty and contain milestones along the way. We can use
        Turning Pages with those who can’t read a word, or with anyone below
        entry level 3 literacy that is looking to improve their reading. <br />
        Turning Pages is a pick up and go resource, which means there are lots
        of instructions for coaches to support learners. In creating this
        digital tool we have broadened the amount of support that can be
        provided, for both learners and coaches.
      </>
    ),
    width: '908px',
  },
  {
    image: OnboardModalStep2,
    title:
      'This tool is where you have everything you need to help people learn to read.',
    description:
      'You’ll see that each milestone of Turning Pages is split into milestones. Your role is to guide the learners to follow through each exercise (alongside you or as self-study). You can also check their level to start at a milestone better suited to them!',
  },
  {
    image: OnboardModalStep3,
    title:
      'You can easily stay on top of the progress of everyone you’re supporting',
    description:
      'You’ll be able to see newly assigned learners and track your current learners so that you can easily stay on top of everyone you are supporting. If anyone alerts you that they are stuck, you’ll be able to review their progress and help them move to the next exercise.',
  },
  {
    image: OnboardModalStep3,
    title: 'Stuck learner feature',
    description:
      'You’ll be able to see newly assigned learners and track your current learners so that you can easily stay on top of everyone you are supporting. If anyone alerts you that they are stuck, you’ll be able to review their progress and help them move to the next exercise.',
  },
  {
    image: OnboardModalStep4,
    title: 'We will onboard you to the app and provide training',
    description:
      'We will onboard you to the programme with a series of training resources which you’ll always have access to if you need to refer back to anything. We want you to feel confident in your role!',
    isLastTab: true,
  },
];

export const ONBOARDING_FACILITATOR_STATIC_DATA = [
  {
    isFirstTab: true,
    image: OnboardModalStep1,
    title: 'Let’s take a tour to know more',
    description: `Thank you for becoming a facilitator! 
    Here is an introduction to Turning Pages and a step-by-step guide on how to use the tool. Click through each page to learn about managing learners and coaches.`,
    width: '908px',
  },
  {
    image: null,
    title: 'What is Turning Pages?',
    description: (
      <>
        Shannon Trust first developed Turning Pages back in 2015 and have used
        this resource to support thousands of learners. An evaluation of the
        programme, conducted by Birmingham City University in 2016 showed that
        it is highly effective in supporting people to become readers. <br />
        Turning Pages is phonics based and has been developed specifically for
        adults. The programme consists of five different manuals, which
        increment in difficulty and contain milestones along the way. We can use
        Turning Pages with those who can’t read a word, or with anyone below
        entry level 3 literacy that is looking to improve their reading. <br />
        Turning Pages is a pick up and go resource, which means there are lots
        of instructions for coaches to support learners. In creating this
        digital tool we have broadened the amount of support that can be
        provided, for both learners and coaches.
      </>
    ),
    width: '908px',
  },
  {
    image: OnboardModalStep2,
    title:
      'This tool is where coaches and learners have everything they need to help people learn to read.',
    description:
      'You’ll see that each manual of Turning Pages is split into milestones. Coaches guide learners through each exercise, working alongside each other or as self-study. Learners will be able to see their achievements as they progress. ',
  },
  {
    image: OnboardModalStep4,
    title: 'Guidance and support through training will be provided to coaches.',
    description:
      'Coaches will be onboarded to the programme with a series of training resources which they’ll always have access to they you need to refer back to anything. We want coaches to feel confident and self-sufficient. ',
  },
  {
    image: OnboardModalStep5,
    title: 'The tool makes it easy for you to manage the learners',
    description:
      'You can add new learners to the system, assign them to coaches and keep track of their status and progress. This gives you a good overview of everything happening in your organisation. ',
    isLastTab: true,
  },
];

export const ONBOARDING_ORGANISATION_ADMIN_STATIC_DATA = [
  {
    isFirstTab: true,
    image: OnboardModalStep1,
    title: 'Let’s take a tour to know more!',
    description: `Here is an introduction to Turning Pages and a step-by-step guide on how to use the tool. Click through each page to learn about managing the tool for your organisation and navigating your dashboard.`,
    width: '908px',
  },
  {
    image: null,
    title: 'What is Turning Pages?',
    description: (
      <>
        Shannon Trust first developed Turning Pages back in 2015 and have used
        this resource to support thousands of learners. An evaluation of the
        programme, conducted by Birmingham City University in 2016 showed that
        it is highly effective in supporting people to become readers. <br />
        Turning Pages is phonics based and has been developed specifically for
        adults. The programme consists of five different manuals, which
        increment in difficulty and contain milestones along the way. We can use
        Turning Pages with those who can’t read a word, or with anyone below
        entry level 3 literacy that is looking to improve their reading. <br />
        Turning Pages is a pick up and go resource, which means there are lots
        of instructions for coaches to support learners. In creating this
        digital tool we have broadened the amount of support that can be
        provided, for both learners and coaches.
      </>
    ),
    width: '908px',
  },
  {
    image: OnboardModalStep2,
    title:
      'This tool is where coaches and learners have everything they need to help people learn to read.',
    description:
      'You’ll see that each manual of Turning Pages is split into milestones. Coaches guide learners through each exercise, working alongside each other or as self-study. Learners will be able to see their achievements as they progress. ',
  },
  {
    image: OnboardModalStep2,
    title: 'Guidance and support through training will be provided',
    description:
      'Coaches will be onboarded to the programme with a series of training resources which they’ll always have access to they you need to refer back to anything. We want coaches to feel confident and self-sufficient. ',
  },
  {
    image: OnboardModalStep5,
    title: 'The tool makes it easy for you to manage the learners',
    description:
      'You can add new learners to the system, assign them to coaches and keep track of their status and progress. This gives you a good overview of everything happening in your organisation. ',
  },
  {
    image: OnboardModalStep6,
    title:
      'Training for coaches, facilitators, and learners is handled within the app',
    description: 'You can review the resources provided at any time',
    isLastTab: true,
  },
];

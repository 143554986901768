import { useReducer } from 'react';

import { BasicInput } from '../../../../components/Inputs';
import { string } from 'yup';
import { MediaKeyInput } from '../../../Components';

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }

  return { ...state, ...value };
}

const getInitialState = (data) => ({
  form: data || {
    correctAnswer: '',
    questionText: '',
  },
  httpError: '',
  validationErrs: {},
  loading: false,
  submitLoading: false,
});

const VowelBreak = ({ data, onChange, errors }) => {
  const [state, setState] = useReducer(reducer, getInitialState(data));

  const setFormData = (data) =>
    setState((prevState) => {
      const form = {
        ...prevState.form,
        ...data,
      };
      onChange({
        ...form,
      });
      return { form };
    });

  return (
    <div>
      <BasicInput
        label="Explainer Text"
        value={state.form.textAudio}
        handleChange={(textAudio) => setFormData({ textAudio })}
        m={{ mt: 8 }}
        error={errors?.textAudio}
      />
      <MediaKeyInput
        label={`Explainer audio file Key`}
        value={state.form.textAudioKey}
        handleChange={(textAudioKey) => {
          setFormData({ textAudioKey });
        }}
        m={{ mt: 5 }}
        error={errors?.textAudioKey}
      />

      <BasicInput
        label="Initial value"
        value={state.form.questionText}
        handleChange={(questionText) => setFormData({ questionText })}
        m={{ mt: 8 }}
        helper="put slashes in the wrong places(i.e. int/o)"
        error={errors?.questionText}
      />
      <BasicInput
        label="Correct Answer"
        value={state.form.correctAnswer}
        handleChange={(correctAnswer) => setFormData({ correctAnswer })}
        m={{ mt: 5 }}
        helper="put slashes in the correct places(i.e. in/to)"
        error={errors?.correctAnswer}
      />
    </div>
  );
};

const validationSchema = {
  correctAnswer: string()
    .required('required field')
    .test(
      'slashes',
      'correct answer should has the same slash numbers',
      (val, a) => {
        return (
          val.split('').filter((e) => e === '/').length ===
          a.parent.questionText.split('').filter((e) => e === '/').length
        );
      }
    ),
  questionText: string()
    .required('required field')
    .test('oneSlashAtLeast', 'should split syllables with "/"', (val, a) => {
      return val.includes('/');
    }),
  textAudio: string().notRequired(),
  textAudioKey: string().notRequired(),
};
export { validationSchema };

export default VowelBreak;

import { useRef, useState } from 'react';
import { Col, Row } from 'components/Grid';
import { BasicButton } from '../../../components/Button';
import * as S from './style';
import { Typography as T } from 'components';

import { navRoutes, organisationStatuses } from '../../../constants';

import { updateOrganisationStatus } from 'api-calls/organisations';

import OrganisationsTable from './OrganisationsTable';
import Modal from '../../../components/Modal';
import FeedbackSection from './FeedbackSection';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

const Organisations = ({ hideFeedback }) => {
  const queryClient = useQueryClient();
  const status = useRef(null);
  const id = useRef(null);
  const [isUpdateModalOpen, setIsUpdateModalVisible] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalVisible] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const [updateLoading, setUpdateLoading] = useState(false);

  const activateAndDeactivate = async () => {
    setUpdateLoading(true);
    const { error } = await updateOrganisationStatus({
      id: id.current,
      body: { status: status.current },
    });
    setUpdateLoading(false);
    await queryClient.invalidateQueries();

    if (!error) {
      setIsUpdateModalVisible(false);
      setIsSuccessModalVisible(true);
    } else {
      setError(error.message);
    }
  };

  const onActivateAndDeactivate = (_id, _status) => {
    status.current = _status;
    id.current = _id;
    setIsUpdateModalVisible(true);
  };

  return (
    <>
      <Row mb={9} mbT={7} inner jc="space-between" ai="center">
        <S.TableButtonWrapper>
          <S.TableTitleDescriptionWrapper>
            <T.H1 mb="8px">Organisations</T.H1>
            <T.P>
              Unlocking Learning Synergy: Explore and Connect with Diverse
              Organizations
            </T.P>
          </S.TableTitleDescriptionWrapper>

          <BasicButton
            ml="16px"
            mlM="0"
            mtM="16px"
            width="230px"
            handleClick={() => navigate(navRoutes.ADMIN.ADD_ORGANISATION)}
          >
            Add organisation
          </BasicButton>
        </S.TableButtonWrapper>
      </Row>

      {error && (
        <Col w={[4, 12, 12]}>
          <T.P color="error">{error}</T.P>
        </Col>
      )}
      {hideFeedback ? null : <FeedbackSection />}
      <Row mt={!hideFeedback && '7'} mtT={!hideFeedback && '5'}>
        <OrganisationsTable onActivateAndDeactivate={onActivateAndDeactivate} />
      </Row>

      <Modal
        visible={isUpdateModalOpen}
        setIsModalVisible={setIsUpdateModalVisible}
      >
        <Row>
          <Col w={[4, 12, 12]}>
            <T.P size="med" weight="bold" color="white">
              Are you sure?
            </T.P>
            <T.P size="regular" color="white" mt={4} mb={4}>
              {status.current === organisationStatuses.DEACTIVATED
                ? `By deactivating them you will remove their access to the platform as well as all their facilitators, coaches and learners.`
                : `By activating them they will be able to access the platform via their original log in details.`}
            </T.P>

            <BasicButton
              variant="secondary"
              onClick={(e) => {
                activateAndDeactivate(e, true);
              }}
              mt={4}
              loading={updateLoading}
              disabled={updateLoading}
            >
              Confirm
            </BasicButton>
            <BasicButton
              variant="tertiary"
              onClick={() => {
                setIsUpdateModalVisible(false);
              }}
              mt={2}
            >
              Cancel
            </BasicButton>
          </Col>
        </Row>
      </Modal>
      <Modal
        visible={isSuccessModalOpen}
        setIsModalVisible={setIsSuccessModalVisible}
      >
        <Row>
          <Col w={[4, 12, 12]}>
            <T.P size="med" weight="bold" color="white">
              Success
            </T.P>
            <T.P size="regular" color="white" mt={4} mb={4}>
              {status.current === organisationStatuses.DEACTIVATED
                ? `They have been deactivated, if you wish to give them permission again, please go to the deactivated tab.`
                : `They have been activated and can now log in again. If they have forgotten their log in details they can click “Forgot password” on the log in page.`}
            </T.P>

            <BasicButton
              variant="secondary"
              onClick={() => setIsSuccessModalVisible(false)}
              mt={4}
            >
              Return to organisations
            </BasicButton>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default Organisations;

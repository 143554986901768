import * as T from '../../Typography';
import * as CS from '../style';
import * as S from './style';
import Icon from './../../Icon';
import { PlayButton } from 'components/Button';

const BasicInput = ({
  type = 'text',
  id,
  name,
  placeholder = 'Type here...',
  label,
  error,
  value,
  handleChange,
  helper,
  color = 'neutralMain',
  placeholderColor = 'neutral80',
  w, // width
  disabled,
  autoComplete,
  m, // margins
  showPasswordInfo,
  optional,
  labelColor,
  bgColor,
  key,
  suffix,
  wrapperStyle,
  audioUrl,
  fallbackText,
  ...props
}) => {
  const onChange = (e) => {
    handleChange(e.target.value, e);
  };

  return (
    <CS.Field w={w} disabled={disabled} {...m} style={wrapperStyle}>
      {label && (
        <CS.Label htmlFor={label} mb={helper ? '1' : '2'}>
          {audioUrl && (
            <PlayButton
              width={'32px'}
              height={'32px'}
              radius={12}
              iconProps={{
                height: '25px',
                width: '25px',
              }}
              color="neutralMain"
              withoutButtonStyle={true}
              backgroundColor="none"
              audioUrl={audioUrl}
              fallbackText={fallbackText}
            />
          )}

          <T.P weight="bold" color={labelColor || color} m="0" ml="1">
            {label}{' '}
            {optional && <CS.OptionalTag ml="1">(optional)</CS.OptionalTag>}
          </T.P>
        </CS.Label>
      )}
      {helper && (
        <T.P isSmall color={'neutral80'} mb="2" ml="2">
          {helper}
        </T.P>
      )}
      <S.BasicInputRelativeDiv>
        <S.Input
          key={key}
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          color={color}
          disabled={disabled}
          autoComplete={autoComplete || 'on'}
          error={error}
          bgColor={bgColor}
          placeholderColor={placeholderColor}
          id={id}
          {...props}
        />
        {suffix && <S.AlertWrapper>{suffix}</S.AlertWrapper>}
        {error && (
          <S.AlertWrapper>
            <Icon width="28px" height="28px" icon="alert" color="error" />
          </S.AlertWrapper>
        )}
      </S.BasicInputRelativeDiv>
      {error && (
        <T.P color="error" m="0" mt="2" mx={2}>
          {error}
        </T.P>
      )}
    </CS.Field>
  );
};

export default BasicInput;

import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from '../../../components/Grid';
import Modal from '../../../components/Modal';
import BasicButton from '../../../components/Button/Basic';
import AssignColumn from '../../../components/CommonTables/AssignColumn';
import * as T from '../../../components/Typography';
import * as S from './style';
import * as Learners from '../../../api-calls/learners';
import { navRoutes } from '../../../constants';
import { BackButton } from 'components/BackButton';
import BasicCoachTable from '../Coaches/BasicTable';

const AssignCoach = () => {
  const [coach, setCoach] = useState();
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState('');
  const [learner, setLearner] = useState();
  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);

  const { id: learnerUserId } = useParams();

  const updateLearnerCoach = async () => {
    setLoading(true);
    const { error } = await Learners.updateLearnerCoach({
      learnerUserId,
      coachId: coach.id,
    });

    setLoading(false);
    if (error) {
      setError(error.message);
    } else {
      setIsModalVisible1(false);
      setIsModalVisible2(true);
      setLearner((prev) => ({ ...prev, coachId: coach.id }));
    }
  };

  const getLearner = async () => {
    setLoading(true);
    const { data, error } = await Learners.getLearnerUniqueId({
      learnerUserId,
    });
    setLoading(false);
    if (data) {
      setLearner(data);
    } else {
      setError(error.message);
    }
  };

  useEffect(() => {
    getLearner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learnerUserId]);

  return (
    <S.Wrapper>
      <BackButton />
      <Row>
        <BasicCoachTable
          tableTitle=" Assign a coach"
          assignCoachActionColumn={{
            title: (
              <T.P weight="bold" color="neutralMain">
                Assign a coach
              </T.P>
            ),
            render: (id, coachData) => {
              return (
                <AssignColumn
                  handleClick={() => {
                    setIsModalVisible1(true);
                    setCoach(coachData);
                  }}
                  alreadyAssigned={Number(id) === Number(learner?.coachId)}
                />
              );
            },
            dataIndex: 'id',
            key: 'id',
          }}
        />
      </Row>
      <Modal
        visible={isModalVisible1}
        setIsModalVisible={(e) => {
          setError('');
          setIsModalVisible1(e);
        }}
      >
        <Row>
          <Col w={[4, 12, 12]} mb={4}>
            {error ? (
              <>
                <T.H3 color="neutralSurface">Error:</T.H3>
                <T.H3 color="neutralSurface"> {error}</T.H3>
              </>
            ) : (
              <T.H3 color="neutralSurface">
                Are you sure you want to assign {coach?.fullName} as{' '}
                {learner?.fullName}'s coach
              </T.H3>
            )}
          </Col>
        </Row>

        <BasicButton
          to={'#'}
          variant="secondary"
          loading={loading}
          handleClick={() => {
            updateLearnerCoach();
          }}
        >
          <T.P color="white" weight="bold">
            Yes, I'm sure
          </T.P>
        </BasicButton>
        <BasicButton
          mt={3}
          to={'#'}
          variant="tertiary"
          loading={loading}
          handleClick={() => {
            setIsModalVisible1(false);
            setCoach(null);
          }}
        >
          No, go back
        </BasicButton>
      </Modal>

      <Modal visible={isModalVisible2} setIsModalVisible={setIsModalVisible2}>
        <Row>
          <Col w={[4, 12, 12]} mb={4}>
            <T.H3 color="neutralSurface">
              Thanks, the coach will be notified
            </T.H3>
          </Col>
        </Row>

        <BasicButton
          to={navRoutes.GENERAL.HOME}
          variant="secondary"
          loading={loading}
          handleClick={() => {
            setIsModalVisible2(false);
          }}
        >
          <T.P color="white" weight="bold">
            Return to dashboard
          </T.P>
        </BasicButton>
      </Modal>
    </S.Wrapper>
  );
};

export default AssignCoach;

import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga4';
import { BrowserTracing } from '@sentry/tracing';
import ErrorBoundary from './ErrorBoundary';
import { queryClient } from './api-calls/queryClient';

if (process.env.NODE_ENV === 'production') {
  // SENTRY
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: window.location.host.includes('turningpages.co.uk')
      ? 'production'
      : 'staging',

    tracesSampleRate: 0.5,
  });

  // GOOGLE ANALYTICS
  ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID);
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname + window.location.search,
  });
}

const container = document.getElementById('root');

const root = createRoot(container);
root.render(
  <QueryClientProvider client={queryClient}>
    <ErrorBoundary>
      <App ReactGA={ReactGA} />
    </ErrorBoundary>
    {process.env.NODE_ENV === 'development' ? (
      <ReactQueryDevtools initialIsOpen={false} />
    ) : null}
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

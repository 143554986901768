import React, { useEffect, useState } from 'react';
import Icon from '../Icon';
import * as S from './style';
import * as T from '../Typography';
import InlineCopy from './InlineCopy';

const Copy = ({ inviteToken, tokenStyle }) => {
  const [copied, setCopied] = useState(false);

  const copyCodeToClipboard = () => {
    setCopied(false);
    const el = document.createElement('textArea');
    el.value = inviteToken;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(true);
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 750);
    }
  }, [copied]);

  if (!inviteToken) {
    <T.P color="error">Missing Token.</T.P>;
  }

  return (
    <>
      <S.Wrapper>
        <S.CopyBtn onClick={copyCodeToClipboard}>
          <Icon icon="copy" color="white" width="16" height="16" />
          <T.P size="small" color="white">
            COPY
          </T.P>
        </S.CopyBtn>
        <T.P
          color={tokenStyle?.color || 'gray8'}
          bold
          style={{ ...tokenStyle }}
        >
          {inviteToken}
        </T.P>
      </S.Wrapper>
      {copied && (
        <Icon
          icon="tick"
          text="Copied"
          color="primary"
          width="16"
          height="16"
          mr="4"
          mt="2"
          style={{ justifyContent: 'flex-end' }}
        />
      )}
    </>
  );
};

export { InlineCopy };
export default Copy;

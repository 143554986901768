import progressCheck from '../../../components/assets/progress-check.png';

import * as S from './style';
import { Typography as T, Button } from '../../../components';
import { audio } from '../../../constants';
import { getMediaUrl } from '../../../helpers';
import { useGeneralState } from '../../../context/general-state';
import { navRoutes } from '../../../constants';

const ProgressCheck = ({ milestoneId }) => {
  const { state: generalState } = useGeneralState();

  return (
    <S.ProgressCheckWrapper
      image={progressCheck}
      to={
        navRoutes.LEARNER.PROGRESS_CHECK_QUESTIONS.replace(
          ':stepId',
          1
        ).replace(':milestoneId', milestoneId) +
        '?sandboxMode=true&selectMilestone=true'
      }
    >
      <S.ButtonsWrapper>
        <Button.PlayButton
          audioUrl={getMediaUrl(
            audio.progressCheck,
            true,
            generalState?.preferredVoice
          )}
          iconProps={{ width: 32 }}
          icon={'speaker'}
          fallbackText={`Milestone ${milestoneId} Progress check`}
        />
        <S.TextWrapper>
          <T.H1 color="neutralSurface">Milestone {milestoneId}</T.H1>
          <T.H1 color="neutralSurface">Progress check</T.H1>
        </S.TextWrapper>
      </S.ButtonsWrapper>
    </S.ProgressCheckWrapper>
  );
};
export default ProgressCheck;

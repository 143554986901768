import { QueryClient } from 'react-query';

const queryConfig = {
  queries: {
    // only retry queries and refetch when when window is focussed when in prod
    retry: process.env.NODE_ENV === 'production',
    refetchOnWindowFocus: process.env.NODE_ENV === 'production',
    // stale time 20 sec
    staleTime: 1000 * 20,
  },
};

export const queryClient = new QueryClient({
  defaultOptions: queryConfig,
});

import { useState } from 'react';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Icon from '../../Icon';
import {
  coachStatusesQuery,
  navRoutes as R,
  userRoles,
} from '../../../constants';
import ProgressHeader from './ProgressHeader';
import { useExercise } from './../../../context/exercise';
import { useAuth } from '../../../context/auth';
import * as S from './style';
import theme from '../../../theme';
import { Button } from '../../../components';
import { navRoutes as NR } from '../../../constants';
import { useProgressCheck } from '../../../context/progress-check';
import { useOrganisationDetails } from 'context/organisation-details';
import { useGeneralState } from 'context/general-state';

const SubMenuItem = (props) => {
  return <S.SubMenuItem weight="normal" color="secondaryMain" {...props} />;
};
const PlayWithTheToolLink = () => {
  return (
    <SubMenuItem
      to={NR.COMMON.EXERCISES_PLAYGROUND + `?milestoneId=1`}
      external
      color="secondaryMain"
      weight="normal"
    >
      Play with the tool
    </SubMenuItem>
  );
};
const Header = ({
  isLoggedIn,
  bgColor = 'neutralMain',
  type,
  learnerDashboard,
  exercise,
  progressPercent: _progressPercent,
  ...props
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const selectMilestone = searchParams.get('selectMilestone');

  const isSelectedMilestoneMode = selectMilestone === 'true';

  const { state: generalState, setState } = useGeneralState();

  const { progressPercent: exerciseProgressPercent } = useExercise();
  let { user, logout } = useAuth();

  const { progressPercent: checkProgressPercent } = useProgressCheck();

  const isUserHaveMultipleOrganisationRoles =
    user?.activeOrganisationsWithActivatedStatus?.length > 1;

  const location = useLocation();
  const navigate = useNavigate();
  const isMenu = location.pathname?.includes('menu');
  const isPreview = location?.pathname?.includes('preview');
  const isTablet = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.tablet})`,
  });
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.mobile})`,
  });
  const isDesktop = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.desktop})`,
  });
  const { organisationDetails: { logoUrl } = {} } = useOrganisationDetails();

  const showCoachNotes =
    !isPreview &&
    (user.role === userRoles.COACH ||
      (user.role === userRoles.LEARNER && generalState.withCoach));

  const showParking =
    !isPreview && generalState.exerciseFlow !== 'REVIEW_SELF_LEARNING';

  const switchAccount = () => {
    setState({
      ...generalState,
      switchAccount: true,
    });
    toggleMobileMenu();
    // wait for state to be updated
    setTimeout(() => {
      navigate(NR.COMMON.SELECT_ORGANISATION);
    }, 0);
  };

  const getUserItemsDropdown = () => {
    if (user.id && user.role === '') {
      return [<SubMenuItem onClick={logout}>Log out</SubMenuItem>];
    }

    if (user.role === userRoles.CONTENT_EDITOR) {
      return [
        <PlayWithTheToolLink />,
        <SubMenuItem onClick={logout}>Log out</SubMenuItem>,
      ];
    }
    if (user.role === userRoles.COACH) {
      if (isUserHaveMultipleOrganisationRoles) {
        return [
          <SubMenuItem to={NR.COACH.COACH_PROFILE}>Profile</SubMenuItem>,
          <SubMenuItem onClick={switchAccount}>Switch account</SubMenuItem>,
          <SubMenuItem onClick={logout}>Log out</SubMenuItem>,
        ];
      }
      return [
        <SubMenuItem to={NR.COACH.COACH_PROFILE}>Profile</SubMenuItem>,
        <SubMenuItem onClick={logout}>Log out</SubMenuItem>,
      ];
    }
    if (
      user.role === userRoles.FACILITATOR ||
      user.role === userRoles.ORGANISATION_ADMIN
    ) {
      if (isUserHaveMultipleOrganisationRoles) {
        return [
          <SubMenuItem to={NR.COMMON.ACCOUNT_DETAILS}>My account</SubMenuItem>,
          <SubMenuItem onClick={switchAccount}>Switch account</SubMenuItem>,
          <PlayWithTheToolLink />,
          <SubMenuItem onClick={logout}>Log out</SubMenuItem>,
        ];
      }

      return [
        <SubMenuItem to={NR.COMMON.ACCOUNT_DETAILS}>
          Account settings
        </SubMenuItem>,
        <PlayWithTheToolLink />,
        <SubMenuItem onClick={logout}>Log out</SubMenuItem>,
      ];
    }
    return [
      <SubMenuItem to={NR.COMMON.ACCOUNT_DETAILS}>My account</SubMenuItem>,
      <PlayWithTheToolLink />,
      <SubMenuItem onClick={logout}>Log out</SubMenuItem>,
    ];
  };
  if (type === 'progress') {
    return (
      <ProgressHeader
        showNotes={!isSelectedMilestoneMode && showCoachNotes}
        showParking={!isSelectedMilestoneMode && showParking}
        progressPercent={
          _progressPercent || exerciseProgressPercent || checkProgressPercent
        }
      />
    );
  }

  const Logo = ({ hideCustomLogo, hideMainLogo }) => {
    const { user } = useAuth();
    let link = !!user.id
      ? NR[user?.role]?.DASHBOARD || NR.COMMON.SELECT_ORGANISATION
      : NR.GENERAL.LOGIN;
    if (user.role === userRoles.CONTENT_EDITOR) {
      link = NR.CMS.BASE;
    }
    return (
      <S.LogoWrapper>
        {logoUrl && !hideCustomLogo && <S.CustomLogo src={logoUrl} />}
        {!hideMainLogo && (
          <Link to={link}>
            <Icon
              icon="logo"
              color="white"
              height={hideCustomLogo && isMobile ? 24 : 32}
              width={hideCustomLogo && isMobile ? 151 : 214}
            />
          </Link>
        )}
      </S.LogoWrapper>
    );
  };

  const DesktopMenu = (menuItems) => (
    <S.Header bgColor={bgColor} {...props}>
      <S.HeaderContent>
        <Logo />
        <S.MenuWrapper>
          {menuItems.map((item) => (
            <Button.WithText
              color="white"
              text={item.text}
              weight="700"
              to={item.to}
              header
            />
          ))}
          <Dropdown />
        </S.MenuWrapper>
      </S.HeaderContent>
    </S.Header>
  );

  const MobileMenu = () => {
    const menuItems = getMenuItemsByRole(user.role).map((item) => ({
      ...item,
      onClick: toggleMobileMenu,
    }));

    if (user.role === userRoles.COACH) {
      menuItems.push({
        text: 'Profile',
        to: NR.COACH.COACH_PROFILE,
        onClick: toggleMobileMenu,
      });
    }

    if (user.role !== '') {
      menuItems.push({
        text: 'My account',
        to: NR.COMMON.ACCOUNT_DETAILS,
        onClick: toggleMobileMenu,
      });
      if (isUserHaveMultipleOrganisationRoles) {
        menuItems.push({
          text: 'Switch account',
          onClick: switchAccount,
        });
      }
    }

    menuItems.push({ text: 'Log out', onClick: logout });

    return (
      <>
        <S.MobileHeader bgColor={bgColor} customLogo={logoUrl} {...props}>
          {logoUrl && (
            <S.CustomLogoWrapperMobile>
              <Logo hideMainLogo />
            </S.CustomLogoWrapperMobile>
          )}
          <S.HeaderContent customLogo={logoUrl}>
            <Logo hideCustomLogo />
            <S.MenuWrapper>
              <Button.IconButton
                handleClick={() => toggleMobileMenu()}
                aria-label={'Open menu'}
                noPadding
              >
                {menuOpen ? (
                  <Icon icon="cross" color="white" width="24" height="24" />
                ) : (
                  <Icon icon="hamburger" color="white" width="32" height="32" />
                )}
              </Button.IconButton>
            </S.MenuWrapper>
          </S.HeaderContent>
        </S.MobileHeader>

        {menuOpen && (
          <S.Overlay customLogo={logoUrl}>
            <S.OverlayContent>
              {menuItems.map((item) => (
                <Button.WithText
                  color="white"
                  text={item.text}
                  onClick={item.onClick}
                  weight="bold"
                  to={item.to}
                  header
                />
              ))}
            </S.OverlayContent>
          </S.Overlay>
        )}
      </>
    );
  };

  const Dropdown = () => {
    const items = getUserItemsDropdown(user.role);
    const [isOpen, setIsOpen] = useState(false);

    const handleHover = () => {
      setIsOpen(true);
    };

    const handleLeave = () => {
      setIsOpen(false);
    };
    return (
      <S.SubMenuWrapper onMouseEnter={handleHover} onMouseLeave={handleLeave}>
        <div className="user-icon">
          <Button.IconButton
            handleClick={(e) => e.preventDefault()}
            aria-label={'Dropdown menu'}
            noPadding
          >
            <Icon icon="userMenu" color="white" width="60" height="24" />
          </Button.IconButton>
          {isOpen && <S.SubMenu>{items}</S.SubMenu>}
        </div>
      </S.SubMenuWrapper>
    );
  };

  const toggleMobileMenu = () => {
    setMenuOpen(!menuOpen);
    if (!menuOpen) {
      window.scrollTo(0, 0);
    }
  };

  const getMenuItemsByRole = (role) => {
    const commonMenuItems = [
      { text: 'Learners', to: NR.COMMON.LEARNERS },
      {
        text: 'Coaches',
        to: NR.COMMON.COACHES.replace(':statusType', coachStatusesQuery.ALL),
      },
    ];

    switch (role) {
      case userRoles.ADMIN:
        return [
          { text: 'Dashboard', to: NR.ADMIN.DASHBOARD },
          { text: 'Learners', to: NR.COMMON.LEARNERS },
          { text: 'Coaches', to: NR.COMMON.COACHES },
          { text: 'Organisations', to: NR.ADMIN.ORGANISATIONS },
          { text: 'Facilitators', to: NR.COMMON.FACILITATORS },
        ];

      case userRoles.ORGANISATION_ADMIN:
        return [
          { text: 'Dashboard', to: NR.ORGANISATION_ADMIN.DASHBOARD },
          ...commonMenuItems,
          { text: 'Facilitators', to: NR.COMMON.FACILITATORS },
          { text: 'Customise', to: NR.ORGANISATION_ADMIN.CUSTOMISE },
          // { text: 'Training', to: NR.FACILITATOR.TRAINING_REVIEW }, // TODO - Ask if the org admin has training or not
        ];

      case userRoles.FACILITATOR:
        return [
          { text: 'Dashboard', to: NR.FACILITATOR.DASHBOARD },
          ...commonMenuItems,
          { text: 'Training', to: NR.FACILITATOR.TRAINING_REVIEW },
        ];

      case userRoles.COACH:
        return [
          { text: 'Dashboard', to: NR.COACH.DASHBOARD },
          { text: 'Learners', to: NR.COACH.LEARNERS },
          { text: 'Training', to: NR.COACH.TRAINING_REVIEW },
        ];

      default:
        return [];
    }
  };

  if (isTablet && !exercise && user.id) {
    return <MobileMenu />;
  } else if (isDesktop && user?.role === userRoles.ORGANISATION_ADMIN) {
    return <MobileMenu />;
  }

  if (!isTablet && !exercise && user.id) {
    const menuItems = getMenuItemsByRole(user.role);
    return DesktopMenu(menuItems);
  }

  if (user?.role === userRoles.LEARNER) {
    return (
      <S.Header bgColor={bgColor} {...props}>
        <S.HeaderContent>
          <Logo />
          {isMenu ? (
            <Button.IconButton
              handleClick={() => navigate(-1)}
              aria-label={'Menu'}
            >
              <Icon icon="cross" color="white" width="24" height="24" />
            </Button.IconButton>
          ) : (
            <Link
              to={R.LEARNER.MENU}
              aria-label={`Visit your ${user.role.toLowerCase()} menu page`}
            >
              <Icon
                icon="hamburger"
                color="white"
                style={{ cursor: 'pointer' }}
                width="36"
                height="32"
              />
            </Link>
          )}
        </S.HeaderContent>
      </S.Header>
    );
  }

  return (
    <S.Header
      learner={user?.role === userRoles.LEARNER}
      bgColor={bgColor}
      {...props}
    >
      <S.HeaderContent notLoggedIn={!user.role}>
        <Logo />
        {userRoles.LEARNER === user?.role ? (
          isMenu ? (
            <Button.IconButton
              handleClick={() => navigate(-1)}
              aria-label={`Go back to your previous page`}
            >
              <Icon icon="cross" color="white" width="24" height="24" />
            </Button.IconButton>
          ) : (
            <Link
              to={R.LEARNER.MENU}
              aria-label={`Visit your ${user.role.toLowerCase()} menu page`}
            >
              <Icon
                icon="hamburger"
                color="white"
                style={{ cursor: 'pointer' }}
                width="36"
                height="32"
              />
            </Link>
          )
        ) : null}
      </S.HeaderContent>
    </S.Header>
  );
};

export default Header;

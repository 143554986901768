import theme from '../../../theme';
const UserMenu = ({ width, height, color, ...props }) => (
  <svg
    width={width || '60'}
    height={height || '32'}
    viewBox="0 0 60 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#a)">
      <path
        d="M27.314 4.686A15.895 15.895 0 0 0 16 0 15.895 15.895 0 0 0 4.686 4.686 15.895 15.895 0 0 0 0 16c0 4.274 1.664 8.292 4.686 11.314A15.895 15.895 0 0 0 16 32c4.274 0 8.292-1.664 11.314-4.686A15.895 15.895 0 0 0 32 16c0-4.274-1.664-8.292-4.686-11.314ZM6.944 26.831c.528-4.546 4.437-8.056 9.056-8.056a9.06 9.06 0 0 1 6.447 2.671 9.111 9.111 0 0 1 2.61 5.385A14.062 14.062 0 0 1 16 30.125a14.06 14.06 0 0 1-9.056-3.294ZM16 16.844c-2.68 0-4.86-2.18-4.86-4.86S13.32 7.126 16 7.126s4.86 2.18 4.86 4.86-2.18 4.86-4.86 4.86Zm10.67 8.402a10.994 10.994 0 0 0-2.897-5.125 10.96 10.96 0 0 0-4.003-2.559 6.733 6.733 0 0 0 2.965-5.577A6.742 6.742 0 0 0 16 5.25a6.742 6.742 0 0 0-6.735 6.735 6.733 6.733 0 0 0 2.967 5.579 10.966 10.966 0 0 0-3.723 2.284 10.958 10.958 0 0 0-3.18 5.396A14.065 14.065 0 0 1 1.875 16C1.875 8.211 8.211 1.875 16 1.875S30.125 8.211 30.125 16c0 3.533-1.304 6.767-3.455 9.246Z"
        fill={color || theme.colors.white}
      />
    </g>
    <path
      d="m41.25 12.625 6.75 6.75 6.75-6.75"
      stroke={color || theme.colors.white}
      stroke-width="2.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <defs>
      <clipPath id="a">
        <path fill={color || theme.colors.white} d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default UserMenu;

import { useReducer, useEffect, useRef, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Redirect from '../../components/Route/Redirect';
import {
  Grid,
  Typography as T,
  Inputs as I,
  Button,
  ReCaptcha,
} from '../../components';
import * as S from './style';
import validate from '../../validation/schemas/login';
import { Users } from '../../api-calls';
import { useAuth } from '../../context/auth';
import { useGeneralState } from '../../context/general-state';

import { navRoutes as R, userRoles, audio } from '../../constants';
import { cleanEmail, getMediaUrl } from '../../helpers';
import Divider from '../../components/Divider/Divider';
import { useMediaQuery } from 'react-responsive';
import theme from 'theme';

const { Row, Col } = Grid;
const { PlayButton } = Button;

const initialState = {
  email: '',
  password: '',
  httpError: '',
  validationErrs: {},
  loading: false,
};

function reducer(state, newState) {
  return { ...state, ...newState };
}

const Login = () => {
  const submitAttempt = useRef(false);
  const [state, setState] = useReducer(reducer, initialState);
  const { email, password, loading, validationErrs, httpError } = state;
  const navigate = useNavigate();
  const { setUser, user } = useAuth();
  const { state: generalState } = useGeneralState();
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.mobile})`,
  });

  const refreshedCSRF = useRef(false);

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, password]);

  useLayoutEffect(() => {
    const script = document.createElement('script');

    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    const body = document.querySelector('body');
    body.appendChild(script);

    return () => {
      body.removeChild(script);
    };
  }, []);

  const validateForm = () => {
    try {
      validate({
        email: cleanEmail(email),
        password,
      });
      setState({ validationErrs: {} });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrs: error.inner });
      }
      return false;
    }
  };

  const handleLogin = async (values) => {
    setState({ loading: true });

    const { error, data } = await Users.login(values);

    setState({ loading: false });

    if (error?.data?.invalidCSRF && !refreshedCSRF.current) {
      refreshedCSRF.current = true;
      await Users.getCSRFToken();
      return handleLogin(values);
    }

    if (error) {
      setState({ httpError: error.message });
    } else {
      setUser(data);

      if (data.role !== userRoles.ADMIN) {
        ReactGA.event({
          category: 'login',
          action: 'login',
        });
        ReactGA.set({
          ...data,
        });
      }

      if (data?.organisationsRoles?.length > 1) {
        navigate(R.COMMON.SELECT_ORGANISATION);
      } else if (data.role === userRoles.CONTENT_EDITOR) {
        navigate(R.CMS.BASE);
      } else if (data.role === userRoles.ADMIN) {
        navigate(R.ADMIN.DASHBOARD);
      } else if (data.role === userRoles.ORGANISATION_ADMIN) {
        navigate(R.ORGANISATION_ADMIN.DASHBOARD);
      } else {
        // COACH or FACILITATOR
        navigate(R.COMMON.SPLASH_SCREEN, {
          state: {
            role: data.role,
          },
        });
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateForm();
    if (isValid) {
      if (process.env.NODE_ENV === 'production') {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
              action: 'login',
            })
            .then((reToken) => {
              handleLogin({
                email: cleanEmail(email),
                password,
                reToken,
              });
            });
        });
      } else {
        handleLogin({
          email: cleanEmail(email),
          password,
        });
      }
    }
  };

  if (user.id && user.role === '') {
    return <Redirect to={R.COMMON.SELECT_ORGANISATION} />;
  }

  return (
    <S.Form onSubmit={handleSubmit}>
      <Row mb={6}>
        <Col w={[4, 12, 12]} ai="center">
          <PlayButton
            width={'56px'}
            height={'56px'}
            radius={12}
            iconProps={{
              height: '32px',
              width: '32px',
            }}
            color="neutralMain"
            backgroundColor="white"
            audioUrl={getMediaUrl(
              audio.welcome,
              true,
              generalState?.preferredVoice
            )}
            fallbackText={'Welcome'}
          />
          <T.H1 weight="bold" color="neutralMain" ml="3" mt="1">
            Welcome
          </T.H1>
        </Col>
      </Row>
      <Row mb="3">
        <Col w={[4, 12, 12]}>
          <T.P
            size={isMobile ? 'med' : 'medLarge'}
            weight="bold"
            color="neutralMain"
          >
            If you are a facilitator, coach, or admin, please log in here to
            access your account.
          </T.P>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 12]} mt="5">
          <I.BasicInput
            id="email"
            label="Email address"
            placeholder="Type your email..."
            margins={{ mt: '2', mb: '1' }}
            type="email"
            value={email}
            autoFocus
            handleChange={(input) => setState({ email: input })}
            error={validationErrs.email}
          />
        </Col>
      </Row>
      <Row mt="5">
        <Col w={[4, 12, 12]}>
          <I.BasicInput
            id="password"
            label="Password"
            placeholder="Type your password..."
            margins={{ mt: '2', mb: '1' }}
            type="password"
            value={password}
            handleChange={(input) => setState({ password: input })}
            error={validationErrs.password}
          />
          <S.Link onClick={() => navigate(R.GENERAL.FORGET_PASSWORD)}>
            Forgot your password?
          </S.Link>
        </Col>
      </Row>
      <Row mt="6">
        <Col w={[4, 12, 12]}>
          {loading ? <T.P mb="2">...loading</T.P> : null}
          {httpError && (
            <T.P mb="2" color="error">
              {httpError}
            </T.P>
          )}

          <Button.BasicButton
            variant="primary"
            disabled={false}
            loading={loading}
            type="submit"
            id="login-button"
          >
            Log in
          </Button.BasicButton>
        </Col>
      </Row>
      <Row mt="1">
        <Col w={[4, 12, 12]}>
          <ReCaptcha />
        </Col>
      </Row>
      <Divider showText />
      <Row mt="4" mb="7">
        <Col w={[4, 12, 12]}>
          <Button.BasicButton
            id="login-as-learner-button"
            variant="transparent"
            customColor="neutralMain"
            bgColor="neutralSurface"
            borderColor="neutral50"
            disabled={false}
            loading={loading}
            type="submit"
            to={R.LEARNER.LOGIN}
            width="100%"
            showSound
            soundBgColor="neutral50"
            soundColor="neutralMain"
            audioUrl={getMediaUrl(
              audio.logInAsLearner, // TODO: update audio file
              true,
              generalState?.preferredVoice
            )}
            padding="0"
            fallbackText={'Log in as learner'}
          >
            Log in as learner
          </Button.BasicButton>
        </Col>
      </Row>
    </S.Form>
  );
};

export default Login;

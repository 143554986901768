import { useState } from 'react';
import { audio, navRoutes, userRoles } from './../../constants';
import Modal from '../Modal';
import { Row, Col } from '../Grid';
import { BasicButton, PlayButton } from '../Button';
import { getMediaUrl } from '../../helpers';
import Icon from '../Icon';
import { useGeneralState } from '../../context/general-state';
import { useNavigate } from 'react-router-dom';
import * as S from './style';
import { useExercise } from 'context/exercise';
import { useCreateStuckAt } from 'api-calls/learners.queries';
import { useAuth } from 'context/auth';

const ParkingIconAndModal = () => {
  const [parkingModalVisible, setParkingModalVisible] = useState(false);
  const { state: generalState } = useGeneralState();
  const navigate = useNavigate();
  const { user } = useAuth();

  const pushToDashboard = () => {
    if (generalState?.isPlayground) {
      return navigate(navRoutes.COMMON.EXERCISES_PLAYGROUND);
    }
    if (user.role === userRoles.COACH) {
      return navigate(
        navRoutes.COACH.LEARNER_DASHBOARD.replace(':id', learnerId)
      );
    }
    navigate(navRoutes.LEARNER.DASHBOARD);
  };

  const { currentStep } = useExercise();
  const learnerId =
    user.role === userRoles.LEARNER ? user.id : user.selectedLearnerId;
  const { mutate: createStuckAt } = useCreateStuckAt({
    stepId: currentStep?.id,
    learnerId,
  });

  const markActiveStepAsSaved = async () => {
    await createStuckAt({
      stepId: currentStep?.id,
      learnerId,
    });
  };

  const handleSaveStep = async () => {
    if (generalState?.isLearning) {
      await markActiveStepAsSaved();
    }
    setParkingModalVisible(true);
  };

  return (
    <>
      <S.IconButton
        onClick={handleSaveStep}
        arai-label="show-parking-button"
        title="show-parking-button"
      >
        <Icon
          icon="questionMark"
          color="neutralMain"
          ml={1}
          style={{ cursor: 'pointer' }}
          width={44}
          height={44}
        />
      </S.IconButton>
      <Modal visible={parkingModalVisible}>
        <Row>
          <Col w={[4, 12, 12]} jc="center">
            <Icon icon="bookmarkSaved" color="white" width="70" height="70" />
          </Col>

          <Col w={[4, 12, 12]} jc="center" mt={3}>
            <PlayButton
              color="neutralSurface"
              audioUrl={getMediaUrl(
                audio.parkingPageSaved,
                true,
                generalState?.preferredVoice
              )}
              fallbackText="Parking page saved"
            >
              Parking page saved
            </PlayButton>
          </Col>

          <Col w={[4, 12, 12]} mt={4}>
            <BasicButton
              handleClick={() => {
                pushToDashboard();
                setParkingModalVisible(false);
              }}
              icon="tick"
              variant="secondary"
              iconProps={{ width: '13', height: '11.5' }}
              focusOutlineColor="neutralSurface"
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default ParkingIconAndModal;

import { useReducer } from 'react';
import { string } from 'yup';

import { BasicInput } from '../../../../components/Inputs';
import { MediaKeyInput } from '../../../Components';
import * as T from '../../../../components/Typography';
import MDEditor from '../../../../components/MDEditor';

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }

  return { ...state, ...value };
}

const getInitialState = (data) => ({
  form: data
    ? data
    : {
        audioKey: '',
        title: '',
        subtitle: '',
        sentence: '',
        titleImage: '',
      },
  httpError: '',
  validationErrs: {},
  loading: false,
  submitLoading: false,
});

const ListenAndRepeat = ({ data, onChange, errors }) => {
  const [state, setState] = useReducer(reducer, getInitialState(data));

  const setFormData = (data) =>
    setState((prevState) => {
      const form = { ...prevState.form, ...data };
      onChange(form);
      return { form };
    });

  return (
    <div>
      <BasicInput
        value={state.form.textAudio}
        handleChange={(textAudio) => setFormData({ textAudio })}
        label="Explainer Text"
        error={errors.textAudio}
        m={{ mt: 8 }}
      />

      <MediaKeyInput
        label="Explainer Audio"
        value={state.form.textAudioKey}
        handleChange={(textAudioKey) => setFormData({ textAudioKey })}
        m={{ mt: 5 }}
        error={errors.textAudioKey}
      />

      <MediaKeyInput
        label="Audio Key"
        value={state.form.audioKey}
        handleChange={(audioKey) => {
          setFormData({ audioKey });
        }}
        m={{ mb: 5, mt: 5 }}
        error={errors?.audioKey}
      />

      <MDEditor
        label={'Title'}
        value={state.form.title}
        onChange={(title) => setFormData({ title })}
        mode={'edit'}
        height={80}
        m={{ mt: 8 }}
        error={errors?.title}
      />

      <MDEditor
        label={'Subtitle'}
        value={state.form.subtitle}
        onChange={(subtitle) => setFormData({ subtitle })}
        mode={'edit'}
        height={80}
        m={{ mt: 8 }}
        error={errors.subtitle}
      />

      <MDEditor
        label="Sentence"
        value={state.form.sentence}
        onChange={(sentence) => setFormData({ sentence })}
        mode={'edit'}
        m={{ mt: 8 }}
        error={errors?.sentence}
      />

      <MediaKeyInput
        label="Image Key"
        value={state.form.imageKey}
        handleChange={(imageKey) => {
          setFormData({ imageKey });
        }}
        m={{ mb: 5, mt: 8 }}
        error={errors?.imageKey}
      />

      <BasicInput
        label="Letter"
        helper="use to show letter beside the image"
        value={state.form.titleImage}
        handleChange={(titleImage) => {
          titleImage.length <= 1 && setFormData({ titleImage });
        }}
        m={{ mt: 8 }}
        error={errors?.titleImage}
      />
      <T.P color="error" mt={6}>
        {errors.general}
      </T.P>
    </div>
  );
};

const validationSchema = {
  audioKey: string().notRequired(),
  title: string().max(50).notRequired(),
  subtitle: string().max(15).notRequired(),
  sentence: string().notRequired(),
  titleImage: string().notRequired(),
  imageKey: string()
    .nullable()
    .when('titleImage', {
      is: (titleImage) => !!titleImage,
      then: string().required('required field'),
      otherwise: string().nullable().notRequired(),
    }),

  textAudio: string().notRequired(),
  textAudioKey: string().notRequired(),
  general: string().test(
    'shouldAddContent',
    'You should provide at least one content',
    (val, { parent }) => {
      const { title, subtitle, sentence, titleImage, textAudio } = parent;
      return title || subtitle || sentence || titleImage || textAudio || false;
    }
  ),
};

export { validationSchema };
export default ListenAndRepeat;

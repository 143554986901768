import { useState, useEffect } from 'react';
import * as S from './style';
import { getMediaUrl } from '../../../helpers';
import { audio } from '../../../constants';
import { useGeneralState } from '../../../context/general-state';

import { Typography as T, Button, Grid, Icon } from '../../../components';

import GoalProgress from './GoalProgress';

import { defaultGoals } from './helpers';
import {
  useUpdateLearnerGoal,
  useDeleteLearnerGoal,
  useCreateLearnerGoalTask,
} from '../../../api-calls/learnersGoals.queries';
import Task from './Task';
import theme from 'theme';
const { Col, Row } = Grid;
const handleProgress = ({ tasks = [], completed }) => {
  if (completed) {
    return 100;
  } else if (!tasks || tasks.length === 0) {
    return 0;
  } else {
    const completedTasked = tasks.filter((task) => task?.completed);
    return parseFloat((completedTasked.length / tasks.length).toFixed(2)) * 100;
  }
};

const GoalCard = ({
  goalId,
  title,
  tasks: _tasks,
  handleTaskCheck,
  handleDeleteGoal,
  completed,
  setGoals,
  refetch,
  setOpenModal,
}) => {
  const [tasks, setTasks] = useState([]);
  const { state: generalState } = useGeneralState();
  const [addMode, setAddMode] = useState(false);
  const [newItem, setNewItem] = useState('');
  const [expanded, setExpanded] = useState(false);

  const { mutate: updateGoal } = useUpdateLearnerGoal({
    personalGoalId: goalId,
    title,
    completed,
  });
  const { mutateAsync: deleteGoal } = useDeleteLearnerGoal({
    personalGoalId: goalId,
  });
  const { mutateAsync: createTask } = useCreateLearnerGoalTask({
    personalGoalId: goalId,
    title: newItem,
  });

  useEffect(() => {
    setTasks(completed ? [] : _tasks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_tasks]);

  const goalHasSound = defaultGoals[title];
  const handleAddChange = (e) => {
    const { value } = e.target;
    setNewItem(value);
  };

  const handleAdd = async () => {
    if (!newItem) {
      return;
    }
    setNewItem('');
    setAddMode(false);
    await createTask({ personalGoalId: goalId, title: newItem });
    refetch();
  };
  const markGoalAsCompleted = () => {
    updateGoal({
      title,
      completed: true,
    });
    setOpenModal(true);
    setGoals((goals) => {
      const currentGoal = goals.unCompleted.find((g) => g.id === goalId);
      const _unCompleted = goals.unCompleted.filter((g) => g.id !== goalId);
      const _completed = [
        ...goals.completed,
        { ...currentGoal, status: 'COMPLETED' },
      ];
      return { completed: _completed, unCompleted: _unCompleted };
    });
  };
  return (
    <>
      <S.CardWrapper completed={completed}>
        <S.DeleteButton
          type="button"
          onClick={() =>
            handleDeleteGoal({
              goalId,
              completed,
              cb: () => deleteGoal({ personalGoalId: goalId }),
            })
          }
        >
          <T.P color="neutral70" weight="bold">
            Delete
          </T.P>
        </S.DeleteButton>
        <Button.PlayButton
          backgroundColor={theme.colors.white}
          audioUrl={getMediaUrl(
            goalHasSound?.audio || null, // TODO: update the sound when it's ready
            true,
            generalState?.preferredVoice
          )}
          fallbackText={title}
        />

        <T.H2 mt="4" mb="2">
          {title}
        </T.H2>
        <GoalProgress progress={handleProgress({ tasks, completed })} />
        <S.AccordionWrapper>
          {!expanded && (
            <S.AccordionTitle>
              <S.AccordionBtn onClick={() => setExpanded(!expanded)}>
                <T.P color="primaryMain" weight="bold">
                  Expand
                </T.P>
                <Icon
                  icon="chevronDown"
                  color="primaryMain"
                  width="18px"
                  height="18px"
                />
              </S.AccordionBtn>
            </S.AccordionTitle>
          )}
          <S.AccordionItem collapsed={!expanded}>
            <S.AccordionContent>
              <S.Divider />
              {addMode ? (
                <S.EditInputWrapper>
                  <S.EditInput
                    value={newItem}
                    onChange={handleAddChange}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        handleAdd();
                      }
                    }}
                  />
                  <S.SaveButton onClick={handleAdd}>Save</S.SaveButton>
                </S.EditInputWrapper>
              ) : (
                <Button.BasicButton
                  handleClick={() => {
                    setAddMode(true);
                  }}
                  icon="plus"
                  variant="tertiary"
                  loading={false}
                >
                  <T.P weight="semi">Add goal list</T.P>
                </Button.BasicButton>
              )}
              {tasks?.length > 0 && (
                <Row mt="4">
                  {tasks.map((item) => {
                    return (
                      <Task
                        key={item.id}
                        item={item}
                        goalId={goalId}
                        refetch={refetch}
                        handleTaskCheck={handleTaskCheck}
                      />
                    );
                  })}
                </Row>
              )}

              <Row inner={true} mt={5}>
                <Col w={[4, 12, 12]}>
                  <Button.BasicButton
                    variant="primary"
                    handleClick={markGoalAsCompleted}
                    disabled={
                      tasks?.length > 0 && tasks.some((item) => !item.completed)
                    }
                    showSound
                    width={'100%'}
                    audioUrl={getMediaUrl(
                      audio.markAsComplete,
                      true,
                      generalState?.preferredVoice
                    )}
                    fallbackText="Mark as completed"
                  >
                    <T.P color="white" weight="bold">
                      Mark as completed
                    </T.P>
                  </Button.BasicButton>
                </Col>
              </Row>
            </S.AccordionContent>
            <S.AccordionTitle>
              <S.AccordionBtn onClick={() => setExpanded(!expanded)}>
                <T.P color="primaryMain" weight="bold">
                  Collapse
                </T.P>
                <Icon
                  icon="chevronDown"
                  color="primaryMain"
                  width="18px"
                  height="18px"
                  style={{ transform: 'rotate(90deg)' }}
                />
              </S.AccordionBtn>
            </S.AccordionTitle>
          </S.AccordionItem>
        </S.AccordionWrapper>
      </S.CardWrapper>
    </>
  );
};

export default GoalCard;

import {
  useFetch,
  formatQueryError,
  usePost,
  usePatch,
} from '../utils/queries';
import { EXERCISES_BASE } from '../constants';

function useGetExercises(options, { cms, forPublic }) {
  const context = useFetch(
    `${EXERCISES_BASE}`,
    {
      cms,
      forPublic,
    },
    options
  );
  return {
    ...context,
    milestones: context.data,
    error: formatQueryError(context),
  };
}

function useGetLetterSoundsSteps() {
  const context = useFetch(`${EXERCISES_BASE}/letter-sounds`, undefined);
  return { ...context, sounds: context.data, error: formatQueryError(context) };
}

function useGetMilestoneAllExercisesCompleted({ milestoneId }, options) {
  const context = useFetch(
    `${EXERCISES_BASE}/milestone-last-ex/${milestoneId}`,
    undefined,
    options
  );
  return {
    ...context,
    lastExercise: context.data,
    error: formatQueryError(context),
  };
}

function useUpdateExerciseFeedback({ exerciseId }) {
  return usePatch(`${EXERCISES_BASE}/${exerciseId}/feedback`);
}

const useCreateCompletedStep = ({ id, learnerId, withCoach }) => {
  return usePost(
    `${EXERCISES_BASE}/steps/${id}/complete`,
    {
      learnerId,
      withCoach,
    },
    {
      options: {
        invalidateKey: [`${EXERCISES_BASE}`],
      },
    }
  );
};

export {
  useGetExercises,
  useGetLetterSoundsSteps,
  useCreateCompletedStep,
  useGetMilestoneAllExercisesCompleted,
  useUpdateExerciseFeedback,
};

import * as S from './style';
import * as T from '../Typography';
import Icon from '../Icon';
import InfoCardWithButton from './InfoCardWithButton';
import InfoCardWithIcon from './InfoCardWithIcon';
import { useCallback } from 'react';
import { BasicButton } from 'components/Button';

const InfoCard = ({
  firstColumnLabel,
  firstColumnValue,
  secondColumnLabel,
  secondColumnValue,
  to,
  noIcon,
  buttonText = 'View',
  bgColor,
  actionType,
  learnerId,
  onClickAccept,
  onClickReject,
  isLoading,
  ...props
}) => {
  const renderActions = useCallback(() => {
    if (noIcon) {
      return null;
    }

    switch (actionType) {
      case 'confirm':
        return (
          <S.ConfirmActionsWrapper>
            <BasicButton
              handleClick={onClickReject}
              variant="transparent"
              width="auto"
              height="auto"
              size="small"
              customColor={'tertiaryMain'}
              borderColor={'tertiaryMain'}
              innerTextPadding={'0'}
            >
              Reject
            </BasicButton>
            <BasicButton
              handleClick={onClickAccept}
              variant="primary"
              width="auto"
              height="auto"
              size="small"
              loading={isLoading}
              innerTextPadding={'0'}
            >
              Accept
            </BasicButton>
          </S.ConfirmActionsWrapper>
        );
      case 'onboard':
        return (
          <BasicButton
            to={to}
            variant="primary"
            width="auto"
            height="auto"
            size="small"
            innerTextPadding={'0'}
          >
            Onboard
          </BasicButton>
        );
      default:
        return (
          <S.StyledLink bgColor={bgColor} to={to}>
            <Icon icon="eye" color="neutralMain" mr="1" />
            <T.H3 color="neutralMain" weight="bold">
              {buttonText}
            </T.H3>
          </S.StyledLink>
        );
    }
  }, [
    noIcon,
    actionType,
    onClickReject,
    onClickAccept,
    isLoading,
    to,
    bgColor,
    buttonText,
  ]);

  return (
    <S.Wrapper bgColor={bgColor} {...props}>
      <S.NameInfoWrapper>
        <div>
          <T.P color="neutral90">{firstColumnLabel}</T.P>
          <T.H3 color="neutralMain" weight="bold">
            {firstColumnValue}
          </T.H3>
        </div>
        {secondColumnValue && (
          <div>
            <T.P color="neutral90">{secondColumnLabel}</T.P>
            <T.H3 color="neutralMain" weight="bold">
              {secondColumnValue}
            </T.H3>
          </div>
        )}
      </S.NameInfoWrapper>
      {renderActions()}
    </S.Wrapper>
  );
};

export { InfoCardWithButton, InfoCardWithIcon };
export default InfoCard;

import { createContext, useState, useContext, useMemo } from 'react';
import { ThemeProvider } from '@emotion/react';
import * as Organisations from '../api-calls/organisations.queries';
import { Outlet, useParams } from 'react-router-dom';
import { useAuth } from './auth';
import {
  setColor,
  formatColor,
  convertHEXtoHSL,
} from '../helpers/set-color-variations';

import colors from '../theme/colors';
import getOrgLogoUrl from '../helpers/get-org-logo-url';

export const hiddenFieldsWhenOrganisationWorkingInPrison = [
  'preReleased',
  'nomisId',
  'delius',
  'probationOfficerName',
  'probationOfficerEmail',
  'confirmedPermissionType',
  'referralType',
  'controlTreatmentType',
];

const organisationDetailsState = {
  id: null,
  logoUrl: '',
  uniqueSlug: '',
  colors: colors,
  organisationName: '',
  isWorkingInPrison: false,
};

const OrganisationDetailsContext = createContext({
  organisationDetails: organisationDetailsState,
  setOrganisationDetails: () => {},
  setOrganisationIdFromInvite: () => {},
  logout: () => {},
});

const adjustedTheme = (ancestorTheme, updatedColors) => ({
  ...ancestorTheme,
  colors: updatedColors,
});

const getUpdatedColors = ({ mainBrandColour, secondaryBrandColour }) => {
  if (!mainBrandColour) {
    return colors;
  }

  const primaryHSLObj = convertHEXtoHSL(mainBrandColour);

  const quaternaryHSLObj = convertHEXtoHSL(
    secondaryBrandColour || colors.quaternaryMainHex
  );

  const updated = {
    ...colors,
    primaryMain: formatColor(setColor('primary', primaryHSLObj).main),
    primary30: formatColor(setColor('primary', primaryHSLObj).mid30),
    primaryLight: formatColor(setColor('primary', primaryHSLObj).light),

    quaternaryMain: formatColor(setColor('quaternary', quaternaryHSLObj).main),
    quaternary30: formatColor(setColor('quaternary', quaternaryHSLObj).mid30),
    quaternaryLight: formatColor(
      setColor('quaternary', quaternaryHSLObj).light
    ),

    neutralMain: formatColor(setColor('neutral', quaternaryHSLObj).main),
  };

  return updated;
};

const OrganisationDetailsWithOrganisationId = ({
  organisationId,
  uniqueSlug,
  colors,
  value,
  setOrganisationDetails,
  ...props
}) => {
  const isGetOrganisationEnabled = !!organisationId || !!uniqueSlug;

  Organisations.useGetOrganisationByIdOrUniqueSlug(
    { id: organisationId, uniqueSlug },
    {
      enabled: isGetOrganisationEnabled,
      onSuccess: (data) =>
        setOrganisationDetails({
          ...data,
          logoKey: data?.logoKey ? `${data.logoKey}?${Date.now()}` : ``,
          logoKeyOriginal: data?.logoKey,
        }),
    }
  );

  return (
    <ThemeProvider
      theme={(theme) =>
        !!organisationId || !!uniqueSlug ? adjustedTheme(theme, colors) : theme
      }
    >
      <OrganisationDetailsContext.Provider value={value} {...props} />
    </ThemeProvider>
  );
};

// get help details/logo/colors
const OrganisationDetails = (props) => {
  const { user } = useAuth();

  const [organisationIdFromInvite, setOrganisationIdFromInvite] =
    useState(null);

  const { uniqueSlug } = useParams();
  const organisationId = organisationIdFromInvite || user?.organisationId;

  const [organisationDetails, setOrganisationDetails] = useState(
    organisationDetailsState
  );

  const logoUrl = organisationDetails?.logoKey
    ? `${getOrgLogoUrl(organisationDetails?.logoKey)}`
    : undefined;

  const _setOrganisationDetails = (data) => {
    const logoKeyWithoutTimestamp = data?.logoKey?.split('?')?.[0];
    setOrganisationDetails({
      ...data,
      logoKey: data?.logoKey ? `${logoKeyWithoutTimestamp}?${Date.now()}` : ``,
    });
  };

  const value = useMemo(
    () => ({
      organisationDetails:
        !!uniqueSlug || !!organisationId
          ? {
              ...organisationDetails,
              logoUrl,
            }
          : organisationDetailsState,
      setOrganisationDetails: _setOrganisationDetails,
      setOrganisationIdFromInvite,
    }),
    [
      logoUrl,
      organisationDetails,
      setOrganisationIdFromInvite,
      organisationId,
      uniqueSlug,
    ]
  );

  const colors = useMemo(
    () =>
      getUpdatedColors({
        mainBrandColour: organisationDetails.mainBrandColour,
        secondaryBrandColour: organisationDetails.secondaryBrandColour,
      }),
    [
      organisationDetails.mainBrandColour,
      organisationDetails.secondaryBrandColour,
    ]
  );

  if (organisationId || uniqueSlug) {
    return (
      <OrganisationDetailsWithOrganisationId
        organisationId={organisationId}
        setOrganisationDetails={_setOrganisationDetails}
        uniqueSlug={uniqueSlug}
        colors={colors}
        value={value}
        {...props}
      />
    );
  }

  return (
    <ThemeProvider
      theme={(theme) =>
        !!organisationId || !!uniqueSlug ? adjustedTheme(theme, colors) : theme
      }
    >
      <OrganisationDetailsContext.Provider value={value} {...props} />
    </ThemeProvider>
  );
};

const useOrganisationDetails = () => {
  const value = useContext(OrganisationDetailsContext);
  return value;
};

const OrganisationDetailsProvider = () => {
  return (
    <OrganisationDetails>
      <Outlet />
    </OrganisationDetails>
  );
};

export { OrganisationDetailsProvider, useOrganisationDetails };
export default OrganisationDetailsContext;

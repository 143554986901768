import {
  useDelete,
  formatQueryError,
  usePost,
  useFetch,
  useGenericMutation,
} from '../utils/queries';
import { useMutation, useQueryClient } from 'react-query';

import { USERS_BASE, userRoles } from '../constants';
import axios from 'axios';

function useDeleteUser({ id, role }) {
  const context = useDelete(`${USERS_BASE}/delete/${role}/${id}`, undefined, {
    options: {
      invalidateKey: [
        role === userRoles.LEARNER ? `GET_LEARNERS` : 'GET_COACHES',
      ],
    },
  });
  return { ...context, error: formatQueryError(context) };
}

function useLogout() {
  const context = usePost(`${USERS_BASE}/logout`, undefined, {
    options: {
      invalidateAll: true,
    },
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
}

function useResetStateAfterSwitchAccount() {
  const context = useGenericMutation(() => {}, `/`, undefined, {
    options: {
      invalidateAll: true,
    },
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
}

function useDeleteUserPersonalInfo({ id }, options) {
  const context = useDelete(`${USERS_BASE}/personal-info`, undefined, {
    options: {
      invalidateAll: true,
      ...options,
    },
  });
  return { ...context, error: formatQueryError(context) };
}

function useGetUserPersonalInfo({ id }, options) {
  const context = useFetch(`${USERS_BASE}/personal-info`, undefined, {
    options: {
      invalidateAll: true,
      ...options,
    },
  });
  return { ...context, error: formatQueryError(context) };
}

function useUpdateUserPersonalInfo({
  firstName,
  lastName,
  email,
  organisationId,
  organisationName,
}) {
  const queryClient = useQueryClient();

  const context = useMutation(
    () =>
      axios.patch(`${USERS_BASE}/personal-info`, {
        firstName,
        lastName,
        email,
        organisationId,
        organisationName,
      }),
    {
      onError: (err, _, context) => {},
      onSettled: () => {
        queryClient.invalidateQueries();
      },
    }
  );

  return { ...context, error: formatQueryError(context) };
}

export {
  useDeleteUser,
  useLogout,
  useDeleteUserPersonalInfo,
  useGetUserPersonalInfo,
  useUpdateUserPersonalInfo,
  useResetStateAfterSwitchAccount,
};

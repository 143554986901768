import { useFetch, formatQueryError } from '../utils/queries';
import { MILESTONES_BASE } from '../constants';

function useGetMilestoneNewWords({ milestoneId }) {
  const context = useFetch(
    `${MILESTONES_BASE}/milestone-new-word/${milestoneId}`
  );
  return {
    ...context,
    newWords: context.data,
    error: formatQueryError(context),
  };
}

function useGetMilestonePreparationExercises({ milestoneId }) {
  const context = useFetch(
    `${MILESTONES_BASE}/${milestoneId}/preparation-exercises`
  );
  return {
    ...context,
    preparationExercises: context.data,
    error: formatQueryError(context),
  };
}

export { useGetMilestoneNewWords, useGetMilestonePreparationExercises };

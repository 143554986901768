import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BasicButton } from '../../components/Button';
import { BasicInput } from '../../components/Inputs';
import Modal from '../../components/Modal';
import { Col, Row } from '../../components/Grid';
import styled from '@emotion/styled';

import * as T from '../../components/Typography';

import { navRoutes } from './../../constants';
import { useAuth } from './../../context/auth';
import { Learners } from './../../api-calls';

const Link = styled(T.Link)`
  :hover {
    text-decoration: underline !important;
    color: ${({ theme }) => theme.colors.primaryMain} !important;
  }
`;
const StartCoaching = ({ hideForgetLink = false, btnProps }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [learnerUniqueId, setLearnerUniqueId] = useState('');
  const [loading, setLoading] = useState(false);
  const [learner, setLearner] = useState({});
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const { user, setUser } = useAuth();

  const getLearner = async () => {
    setLoading(true);
    const { data, error } = await Learners.getLearnerByUniqueId({
      learnerUniqueId: learnerUniqueId,
    });
    setLoading(false);

    if (data) {
      setUser({ ...user, selectedLearnerId: data.id });
      if (!data.hasSelfLearning) {
        return navigate(
          navRoutes.COACH.LEARNER_DASHBOARD.replace(':id', data.id)
        );
      }
      setLearner(data);
    } else {
      setError(error.message);
    }
  };

  return (
    <>
      <BasicButton
        handleClick={() => setIsModalVisible(true)}
        icon={'userWithArrow'}
        maxWidth="284px"
        borderRadius="8px"
        iconProps={{
          width: '32px',
          height: '32px',
        }}
        {...btnProps}
      >
        Start coaching
      </BasicButton>
      {/* <S.StartCoachingWrapper onClick={() => setIsModalVisible(true)}>
         <S.IconWrapper>
           <Icon icon={'userWithArrow'} />
         </S.IconWrapper>
         <T.P weight="bold" ml={3} color="neutralMain">
           Start coaching
         </T.P>
       </S.StartCoachingWrapper> */}

      <Modal
        visible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        bgColor="white"
        // parentFunc={parentFunc}
      >
        <Row>
          <Col w={[4, 12, 12]} jc="center">
            <T.P size="med" weight="bold" color="neutralMain">
              {learner.hasSelfLearning
                ? `${learner.firstName} has done some self-learning since the last session. Want to review?`
                : 'Thank you for volunteering to coach with Turning Pages'}
            </T.P>
          </Col>
          {learner.hasSelfLearning ? (
            <>
              <Col w={[4, 12, 12]} mt={4}>
                <BasicButton
                  to={navRoutes.COACH.REVIEW_LEARNER_SELF_LEARNING.replace(
                    ':id',
                    learner.id
                  )}
                  icon="tick"
                  variant="secondary"
                  iconProps={{ width: '12', height: '12' }}
                  loading={loading}
                  disabled={loading}
                />
              </Col>
              <Col w={[4, 12, 12]} mt={2}>
                <BasicButton
                  to={navRoutes.COACH.LEARNER_DASHBOARD.replace(
                    ':id',
                    learner.id
                  )}
                  icon="cross"
                  variant="tertiary"
                  iconProps={{
                    width: '12',
                    height: '12',
                    color: 'neutralMain',
                  }}
                  loading={loading}
                  disabled={loading}
                />
              </Col>
            </>
          ) : (
            <>
              <Col w={[4, 12, 12]} mt={5}>
                <BasicInput
                  handleClick={() => setIsModalVisible(false)}
                  color="neutralMain"
                  placeholder="Enter learner ID..."
                  value={learnerUniqueId}
                  handleChange={setLearnerUniqueId}
                  autoFocus
                />
              </Col>
              <Col w={[4, 12, 12]} mt={5}>
                {error && <T.P color="neutralMain">{error}</T.P>}
                <BasicButton
                  handleClick={getLearner}
                  icon="next"
                  variant="primary"
                  iconProps={{ width: '20', height: '20' }}
                  loading={loading}
                  disabled={loading || !learnerUniqueId}
                >
                  <T.P weight="bold" color="white">
                    Next
                  </T.P>
                </BasicButton>
              </Col>
              {!hideForgetLink && (
                <Col w={[4, 12, 12]} mt={5}>
                  <Link
                    color="neutralMain"
                    weight="regular"
                    to={navRoutes.COACH.LEARNERS}
                  >
                    Can’t remember their ID, click here
                  </Link>
                </Col>
              )}
            </>
          )}
        </Row>
      </Modal>
    </>
  );
};

export default StartCoaching;

import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { COACHES_BASE } from '../constants';
import {
  useFetch,
  usePost,
  usePatch,
  formatQueryError,
} from '../utils/queries';
import { updateCoach } from './coaches';

function useAddCoaches() {
  // usePost takes url and params
  // formData gets picked up by mutate prop
  return usePost(`${COACHES_BASE}`, undefined);
}

const useAddCoachesInvites = () => {
  const context = usePost(`${COACHES_BASE}/invites`, undefined, {
    queryKey: `ADD_COACHES_INVITES`,
    options: {
      invalidateKey: [`GET_COACHES`],
    },
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
};

function useUpdateCoachInfo({
  firstName,
  lastName,
  email,
  availabilityHoursPerWeek,
  bestDayTime,
  city,
  phoneNumber,
}) {
  return usePatch(
    `${COACHES_BASE}/update-coach-info`,
    {
      firstName,
      lastName,
      email,
      availabilityHoursPerWeek,
      bestDayTime,
      city,
      phoneNumber,
    },
    {
      options: {
        invalidateAll: true,
      },
    }
  );
}

function useUpdateCoach(
  {
    coachId,
    onboarded,
    bestDayTime,
    availabilityHoursPerWeek,
    completedTrainingAt,
  },
  options
) {
  return usePost(
    `${COACHES_BASE}/update/`,
    {
      coachId,
      onboarded,
      bestDayTime,
      availabilityHoursPerWeek,
      completedTrainingAt,
    },
    {
      options: {
        invalidateAll: true,
        ...options,
      },
    }
  );
}

function useGetCoachDetails({ coachId }) {
  const context = useFetch(`${COACHES_BASE}/${coachId}/details`);
  return {
    ...context,
    coach: context.data,
    error: formatQueryError(context),
  };
}

function useGetCoachesCount({ searchValue = '' }) {
  const context = useFetch(`${COACHES_BASE}/count`, {
    search: searchValue,
  });
  return {
    ...context,
    coachesCount: context.data,
    error: formatQueryError(context),
  };
}

function useGetRecruitmentFormAnswers({ coachId }, options) {
  const context = useFetch(
    `${COACHES_BASE}/${coachId}/recruitment-form-answers`,
    undefined,
    options
  );
  return {
    ...context,
    error: formatQueryError(context),
  };
}

function useGetCoaches({
  statusType,
  page,
  limit,
  sortField,
  sortOrder,
  searchValue,
}) {
  const context = useFetch(
    `${COACHES_BASE}/`,
    {
      statusType,
      page,
      limit,
      sortField,
      sortOrder,
      search: searchValue,
    },
    {
      queryKey: `GET_COACHES`,
    }
  );

  return {
    ...context,
    coaches: context.data,
    error: formatQueryError(context),
  };
}

function useUpdateCoachDBS({ value, coachId }) {
  return usePatch(
    `${COACHES_BASE}/dbs`,
    {
      value,
      coachId,
    },
    {
      options: {
        invalidateAll: true,
      },
    }
  );
}

function useGetCoachNotes({ coachId }) {
  const context = useFetch(`${COACHES_BASE}/notes/`, { coachId });
  return {
    ...context,
    notes: context.data,
    error: formatQueryError(context),
  };
}

function useAddCoachNote({ coachId, note }) {
  return usePost(
    `${COACHES_BASE}/notes`,
    {
      coachId,
      note,
    },
    {
      options: {
        invalidateKey: [`${COACHES_BASE}/notes/`, { coachId }],
      },
    }
  );
}

function useUpdateCoachApprovalStatusHook() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ note, coachId, status }) => {
      const { data } = await axios.patch(
        `${COACHES_BASE}/${coachId}/approval-status`,
        {
          note,
          coachId,
          status,
        }
      );
      return data;
    },
    {
      onSettled: () => queryClient.invalidateQueries(),
    }
  );
}

function useUpdateCoachApprovalStatus({ note, coachId, status }) {
  return usePatch(
    `${COACHES_BASE}/${coachId}/approval-status`,
    {
      note,
      coachId,
      status,
    },
    {
      options: {
        invalidateAll: true,
      },
    }
  );
}

function useUpdateCoachCompletedTrainingDay() {
  return useMutation(({ coachId, onboarded, completedTrainingAt }) =>
    updateCoach({
      coachId,
      onboarded,
      completedTrainingAt,
    })
  );
}
const useGetCoachStuckAtLearners = (options) => {
  const context = useFetch(`${COACHES_BASE}/stuck-at-learners`, undefined, {
    ...options,
  });

  return {
    ...context,
    learnersStuckAt: context.data,
    error: formatQueryError(context),
  };
};

export {
  useAddCoaches,
  useUpdateCoachInfo,
  useGetCoachDetails,
  useGetCoaches,
  useUpdateCoachDBS,
  useGetCoachNotes,
  useAddCoachNote,
  useAddCoachesInvites,
  useUpdateCoachApprovalStatus,
  useGetCoachesCount,
  useUpdateCoachApprovalStatusHook,
  useGetRecruitmentFormAnswers,
  useUpdateCoachCompletedTrainingDay,
  useGetCoachStuckAtLearners,
  useUpdateCoach,
};

import React from 'react';
import * as S from './style';
import Header from '../Header';
import Footer from '../Footer';

const Centered = ({
  children,
  image,
  contentProps = {},
  contentBodyProps = {},
  headerProps = {},
  exercise = false,
  ...props
}) => {
  return (
    <S.Wrapper {...props} bgColor={'primaryLight'}>
      <Header exercise={exercise} {...headerProps} />
      <S.Content {...contentProps}>
        <S.ContentBody {...contentBodyProps} headerType={headerProps.type}>
          {children}
        </S.ContentBody>
      </S.Content>
      <Footer {...props} />
    </S.Wrapper>
  );
};

export default Centered;

const Checklist = ({ width, height, color, ...props }) => (
  <svg
    width={width || '24'}
    height={height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    style={{
      padding: '2px',
    }}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 15.8L7.14286 17L10 14"
      stroke={color}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6 8.8L7.14286 10L10 7"
      stroke={color}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13 9L18 9"
      stroke={color}
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M13 16L18 16"
      stroke={color}
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C21.5093 4.43821 21.8356 5.80655 21.9449 8"
      stroke={color}
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
);
export default Checklist;

import { useReducer } from 'react';

import * as T from '../../../../components/Typography';
import { BasicInput } from '../../../../components/Inputs';
import { Checkbox } from 'antd';
import { MediaKeyInput } from '../../../Components';
import { Icon } from '../../../../components';
import * as S from './style';
import { string, number, boolean, array, object } from 'yup';

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }

  return { ...state, ...value };
}

const getInitialState = (data) => ({
  form: data
    ? {
        ...data,
        options: data?.options?.map((e, i) => ({ ...e, id: i })) || [
          { id: 0, option: '' },
        ],
      }
    : {
        options: [{ id: 0, option: '', isCorrect: false }],
        correctAnswer: '',
        textAudioKey: '',
        textAudio: '',
      },
  httpError: '',
  validationErrs: {},
  loading: false,
  submitLoading: false,
});

const SpotTheWord = ({ data, onChange, errors }) => {
  const [state, setState] = useReducer(reducer, getInitialState(data));

  const setFormData = (data) =>
    setState((prevState) => {
      const form = { ...prevState.form, ...data };
      onChange({
        ...form,
        options: form?.options?.filter((e) => e.option),
      });
      return { form };
    });

  const add = () => {
    const maxId = Math.max(...state.form.options.map((e) => e.id), 0);
    setFormData({
      options: [...state.form.options, { id: maxId + 1, option: '' }],
    });
  };
  const remove = (id) => {
    setFormData({
      options: state.form.options.filter((e) => e.id !== id),
    });
  };
  const onOptionChange = (value, id) => {
    setFormData({
      options: state.form.options.map((e) => {
        if (e.id !== id) return e;
        return { ...e, option: value };
      }),
    });
  };

  const onIsCorrectChange = (value, id) => {
    setFormData({
      options: state.form.options.map((e) => {
        if (e.id !== id) return e;
        return { ...e, isCorrect: value };
      }),
    });
  };

  return (
    <div>
      <BasicInput
        label="Explainer Text"
        value={state.form.textAudio}
        handleChange={(textAudio) => setFormData({ textAudio })}
        m={{ mt: 8 }}
      />
      <MediaKeyInput
        label={`Explainer Text file Key`}
        value={state.form.textAudioKey}
        handleChange={(textAudioKey) => {
          setFormData({ textAudioKey });
        }}
        m={{ mt: 5 }}
      />

      <BasicInput
        label="Correct answer"
        value={state.form.correctAnswer}
        handleChange={(correctAnswer) => setFormData({ correctAnswer })}
        m={{ mt: 8 }}
        helper="To be shown in the notebook"
        error={errors?.correctAnswer}
      />

      <T.P mt={8} size="large" weight="bold">
        Options
      </T.P>
      {errors?.options && typeof errors.options === 'string' && (
        <T.P mb={2} mt={2} color="error">
          {errors.options}
        </T.P>
      )}

      {state.form.options.map((e, i) => {
        return (
          <>
            <BasicInput
              label="Text"
              value={e.option}
              handleChange={(value) => {
                onOptionChange(value, e.id);
              }}
              m={{ mb: 5, mt: 5 }}
              error={errors?.options && errors?.options[i]?.option}
            />
            <Checkbox
              checked={e.isCorrect}
              onChange={(value) =>
                onIsCorrectChange(value.target.checked, e.id)
              }
              disabled={!e.option}
            >
              correct option?
            </Checkbox>
            {state.form.options.length === 1 && i === 0 ? null : (
              <S.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => remove(e.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </S.Button>
            )}
          </>
        );
      })}

      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={add}
        disabled={state.form.options.find((e) => !e.option)}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add Option
      </S.Button>
    </div>
  );
};

const validationSchema = {
  options: array()
    .of(
      object()
        .shape({
          id: number().required(),
          option: string().required('required field'),
          isCorrect: boolean(),
        })
        .required()
    )
    .required('you must provide options')
    .min(1, 'you must provide options')
    .test('oneCorrect', 'should select one correct answer at least', (val) => {
      return val?.filter((e) => e.isCorrect)?.length >= 1;
    }),

  correctAnswer: string().required('required field'),
  textAudioKey: string().notRequired(),
  textAudio: string().notRequired(),
};
export { validationSchema };

export default SpotTheWord;

import WithText from '../Button/WithText';
import * as S from './style';

const AssignColumn = ({ handleClick, alreadyAssigned }) => (
  <S.NameColumnWrapper>
    <WithText
      text={alreadyAssigned ? 'Assigned' : 'Assign'}
      icon="userWithArrow"
      width={'30px'}
      height={'30px'}
      handleClick={handleClick}
      disabled={alreadyAssigned}
    />
  </S.NameColumnWrapper>
);

export default AssignColumn;

import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Typography as T, Button } from '../../../components';
import { PlayButton } from '../../../components/Button';
import Loading from '../../../components/Loading';
import { getMediaUrl } from '../../../helpers';
import { useGeneralState } from '../../../context/general-state';
// import { useGetMilestoneNewWords } from '../../../api-calls/milestones.queries.js';
import { audio } from '../../../constants';
import {
  AudioBesideText,
  Text,
  GlassesImg,
  StyledImage,
  ImageWrapper,
  // WithBg
} from './style';
import { navRoutes } from '../../../constants';

const group1 = ['2', '4', '5', '7', '8', '10', '11', '13', '14'];
const group2 = ['1', '3', '6', '9', '12', '15'];

const endOfManuals = ['3', '6', '9', '12', '15'];

const affirmations = [
  {
    text: 'I love gaining knowledge which helps me in growing to my full potential.',
    audio: audio.iLoveGainingKnowledgeWhichHelpsMeInGrowingToMyFullPotential,
  },
  {
    text: 'I am advancing to new levels by learning more each day.',
    audio: audio.iAmAdvancingToNewLevelsByLearningMoreEachDay,
  },
  {
    text: `My mind’s ability to learn and remember is increasing every day.`,
    audio: audio.myMindsAbilityToLearnAndRememberIsIncreasingEveryDay,
  },
  {
    text: `I have a winner’s mindset and I love accomplishing my goals.`,
    audio: audio.iHaveAWinnersMindsetAndILoveAccomplishingMyGoals,
  },
  {
    text: `I will continue to expand my mind.`,
    audio: audio.iWillContinueToExpandMyMind,
  },
];

const randomAffirmation =
  affirmations[Math.floor(Math.random() * affirmations.length)];

const MilestoneSuccess = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { state: generalState } = useGeneralState();
  // const {
  //   data,
  //   error: getNewWordsError,
  //   isLoading: getNewWordsLoading,
  // } = useGetMilestoneNewWords({ milestoneId: id });
  const [affirmation, setAffirmation] = useState(affirmations[0]);

  useEffect(() => {
    if (!group1.includes(id) && !group2.includes(id)) {
      navigate(navRoutes.GENERAL.NOT_FOUND);
    }
    setAffirmation(randomAffirmation);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const text = endOfManuals.includes(id)
    ? 'You’ve completed a manual. You should be chuffed!'
    : 'Woohoo, you’ve reached a milestone!';
  if (isLoading) return <Loading />;
  return (
    <>
      <Grid.Row jc="center">
        <Grid.Col w={[4, 8, 8]}>
          <AudioBesideText>
            <PlayButton
              handleClick={() => {}}
              backgroundColor="white"
              iconProps={{
                width: '32px',
                height: '32px',
                color: 'neutralMain',
              }}
              padding="12px"
              disabled={false}
              loading={false}
              audioUrl={getMediaUrl(
                endOfManuals.includes(id)
                  ? audio.youveCompletedAManualYouShouldBeChuffed
                  : audio.woohooYouVeReachedAMilestone,
                true,
                generalState?.preferredVoice
              )}
              fallbackText={text}
            />
            <T.H2>{text}</T.H2>
          </AudioBesideText>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row jc="center">
        <Grid.Col w={[4, 8, 8]} jc="center">
          {!endOfManuals.includes(id) ? (
            <ImageWrapper>
              <StyledImage image="goldCup" alt="Golden Cup" />
            </ImageWrapper>
          ) : (
            <ImageWrapper>
              <StyledImage image="completedManual" alt="Books" />
            </ImageWrapper>
          )}
          {/* <WithBg>
            <AudioBesideText>
              <PlayButton
                handleClick={() => {}}
                backgroundColor="white"
                iconProps={{
                  width: '32px',
                  height: '32px',
                  color: 'neutralMain',
                }}
                padding="12px"
                disabled={false}
                loading={false}
                audioUrl={getMediaUrl(
                  audio.tryAgain,
                  true,
                  generalState?.preferredVoice
                )}
              />
              <T.H2>You’ve learnt {data?.newWords} words 🎉</T.H2>
            </AudioBesideText>
          </WithBg> */}
        </Grid.Col>
      </Grid.Row>
      <Grid.Row jc="center">
        <Grid.Col w={[4, 8, 8]} mt="2">
          <AudioBesideText>
            <PlayButton
              handleClick={() => {}}
              backgroundColor="white"
              iconProps={{
                width: '32px',
                height: '32px',
                color: 'neutralMain',
              }}
              padding="12px"
              disabled={false}
              loading={false}
              audioUrl={getMediaUrl(
                audio.letSRepeatAnAffirmationBeforeWeContinue,
                true,
                generalState?.preferredVoice
              )}
              fallbackText="Let’s repeat an affirmation before we continue"
            />
            <T.H2>Let’s repeat an affirmation before we continue</T.H2>
          </AudioBesideText>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row jc="center">
        <Grid.Col w={[4, 8, 8]}>
          <GlassesImg image="glasses" alt="glasses" />

          <AudioBesideText>
            <PlayButton
              handleClick={() => {}}
              backgroundColor="white"
              iconProps={{
                width: '24px',
                height: '24px',
                color: 'neutralMain',
              }}
              padding="8px"
              disabled={false}
              loading={false}
              audioUrl={getMediaUrl(
                affirmation.audio,
                true,
                generalState?.preferredVoice
              )}
              fallbackText={affirmation.text}
            />
            <Text ml="3">{affirmation.text}</Text>
          </AudioBesideText>
          <Button.BasicButton
            variant="primary"
            disabled={false}
            type="click"
            icon="next"
            iconProps={{ width: '20', height: '20' }}
            mt="6"
            onClick={() => navigate(navRoutes.LEARNER.DASHBOARD)}
            fallbackText="Next"
          ></Button.BasicButton>
        </Grid.Col>
      </Grid.Row>
      {/* {getNewWordsError ? (
        <Grid.Row jc="center">
          <Grid.Col w={[4, 8, 8]}>
            <T.P>{getNewWordsError?.message}</T.P>
          </Grid.Col>
        </Grid.Row>
      ) : null} */}
    </>
  );
};

export default MilestoneSuccess;

import moment from 'moment';
import { EyeOutlined } from '@ant-design/icons';
import * as T from 'components/Typography';
import * as S from './style';
import { navRoutes } from '../../../constants';
import LearnerStatusColumn from './LearnerStatusColumn';
import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter';

const badgesStyle = {
  REFERRAL: {
    text: 'Referral',
    bgColor: 'secondaryMain',
  },
  REJECTED: {
    text: 'Rejected',
    bgColor: 'neutralMain',
  },
  AWAITING_COACH: {
    text: 'Awaiting Coach',
    bgColor: 'neutral50',
    fontColor: 'neutral90',
  },
  TO_BE_ONBOARDED: {
    text: 'Still to onboard',
    bgColor: 'primaryMain',
  },
  ASSIGNED: {
    text: 'Assigned',
    bgColor: 'quaternaryMain',
  },
  AWAITING_APPROVAL: {
    text: 'Awaiting Coach Approval',
    bgColor: 'warningLight',
    fontColor: 'neutral90',
  },
};

const decideSingleStatus = (row) => {
  if (row.referralStatus === 'PENDING') return 'REFERRAL';
  if (row.referralStatus === 'REJECTED') return 'REJECTED';
  if (row.referralStatus === 'APPROVED' && !row.assignedToCoach)
    return 'AWAITING_COACH';
  if (!row.isReferral && !row.assignedToCoach && !row.confirmed)
    return 'AWAITING_COACH';

  if (row.assignedToCoach && !row.confirmed) return 'AWAITING_APPROVAL';

  if (row.assignedToCoach && !row.onboarded) return 'TO_BE_ONBOARDED';
  if (row.assignedToCoach && row.onboarded) return 'ASSIGNED';
};

const decideMultipleStatuses = (row) => {
  let statuses = [];
  if (row.isReferral) {
    statuses.push('REFERRAL');
  }
  if (row.referralStatus === 'REJECTED') {
    return ['REJECTED'];
  }
  if (!row.assignedToCoach) {
    statuses.push('AWAITING_COACH');
  }

  if (row.assignedToCoach && !row.confirmed) {
    statuses.push('AWAITING_APPROVAL');
  }

  if (row.assignedToCoach && !row.onboarded) {
    statuses.push('TO_BE_ONBOARDED');
  }
  if (row.assignedToCoach && row.onboarded) {
    statuses.push('ASSIGNED');
  }

  return statuses;
};

export const getLearnerTableColumns = ({
  showSingleStatus,
  showAdminFields,
  showStatusFiled,
  showAssignedCoachField,
  showInactiveFields = false,
  page,
}) => {
  const commonColumns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (text, record, index) => index + 1 + (page - 1) * 20,
    },
    {
      title: 'Name',
      width: '15%',
      dataIndex: 'fullName',
      key: 'fullName',
      render: (fullName, record) => {
        const { referralStatus, id, deleted } = record;
        const needsReview = referralStatus === 'PENDING';

        return (
          <S.LearnerNameColumnWrapper>
            <T.P>{deleted ? 'Deleted' : fullName}</T.P>
            {!deleted ? (
              <T.Link
                to={
                  needsReview
                    ? navRoutes.COMMON.LEARNER_REFERRAL.replace(':id', id)
                    : navRoutes.COMMON.LEARNER.replace(':userId', id)
                }
              >
                <S.Button type="text" icon={<EyeOutlined />}>
                  {needsReview ? 'Review' : 'View more'}
                </S.Button>
              </T.Link>
            ) : null}
          </S.LearnerNameColumnWrapper>
        );
      },
    },
    {
      title: LearnerStatusColumn,
      dataIndex: 'status',
      key: 'status',
      render: (_, row) => {
        if (showSingleStatus) {
          const status = decideSingleStatus(row);
          const { text, bgColor, fontColor } = badgesStyle[status] || {};
          return (
            <S.Tag bgColor={bgColor} fontColor={fontColor}>
              {text}
            </S.Tag>
          );
        } else {
          const statuses = decideMultipleStatuses(row);
          return statuses.map((status) => {
            const { text, bgColor, fontColor } = badgesStyle[status] || {};
            return (
              <S.Tag bgColor={bgColor} fontColor={fontColor} key={status}>
                {text}
              </S.Tag>
            );
          });
        }
      },
    },
    {
      title: 'Assigned Coach',
      dataIndex: 'coach',
      key: 'coach',
      render: (coach) => (coach.deleted ? 'Deleted' : coach.fullName || 'N/A'),
    },
    {
      title: 'Date added to platform',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => (date ? moment(date).format('DD MMMM YYYY') : 'N/A'),
    },
    {
      title: 'Learner ID',
      dataIndex: 'learnerUniqueId',
      key: 'learnerUniqueId',
      render: (text) => <T.P>{text}</T.P>,
    },
    {
      title: 'Deactivation date',
      dataIndex: 'deactivatedAt',
      key: 'deactivatedAt',
      render: (date) => (date ? moment(date).format('DD MMMM YYYY') : 'N/A'),
    },
    {
      title: 'Deactivated Reason',
      dataIndex: 'deactivatedReason',
      key: 'deactivatedReason',
      render: () => <T.P>This is not finished yet</T.P>,
    },
  ].filter((column) => {
    // if showStatusFiled is false, then we don't want to show the status column
    if (column.key === 'status' && !showStatusFiled) {
      return false;
    }

    // if showAssignedCoachField is false, then we don't want to show the assigned coach column
    if (column.key === 'coach' && !showAssignedCoachField) {
      return false;
    }

    if (column.key === 'deactivatedAt' && !showInactiveFields) {
      return false;
    }

    if (column.key === 'deactivatedReason' && !showInactiveFields) {
      return false;
    }

    return true;
  });

  if (showAdminFields) {
    return [
      ...commonColumns.splice(0, 2),
      {
        title: <T.P weight="bold">Organisation</T.P>,
        dataIndex: 'organisationName',
        key: 'organisationName',
        render: (text) => (text ? capitalizeFirstLetter(text) : 'N/A'),
      },
      ...commonColumns,
    ];
  }

  return commonColumns;
};

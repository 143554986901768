import { useParams } from 'react-router-dom';

import { BasicButton } from '../../../components/Button';
import { Col, Row } from '../../../components/Grid';
import { PlayButton } from '../../../components/Button';
import Tip from '../../../components/Tip';
import { navRoutes, userRoles } from '../../../constants';

import { getMediaUrl } from '../../../helpers';
import * as T from '../../../components/Typography';
import * as S from './style';
import { useGeneralState } from '../../../context/general-state';
import { useNavigate } from 'react-router-dom';
import { LearningSession } from './../../../api-calls';
import { useAuth } from '../../../context/auth';

const Content = ({ beforeExercise, showCoachNotes, toCoachNotes }) => {
  const { state: generalState } = useGeneralState();
  const { exerciseId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();

  const {
    mutateAsync,
    error: httpError,
    isLoading,
  } = LearningSession.useCreateLearningSession();

  const beforeExerciseText = beforeExercise?.text?.trim();
  const beforeExerciseTextTip = beforeExercise?.tip?.trim();
  return (
    <S.Wrapper>
      <Row jc="center">
        <Col w={[4, 8, 8]}>
          <PlayButton
            audioUrl={getMediaUrl(
              beforeExercise?.textAudioKey,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: '32px', height: '32px' }}
            padding="12px"
            fallbackText={
              typeof beforeExerciseText === 'string' && beforeExerciseText
            }
          >
            {beforeExerciseText}
          </PlayButton>
        </Col>
      </Row>
      {beforeExercise?.tip && (
        <Row jc="center">
          <Col w={[4, 8, 8]} mt={4} mb={4} noWrap>
            {beforeExercise?.tipAudioKey && (
              <PlayButton
                audioUrl={getMediaUrl(
                  beforeExercise?.tipAudioKey,
                  true,
                  generalState?.preferredVoice
                )}
                mr={'12px'}
                fallbackText={
                  typeof beforeExerciseTextTip === 'string' &&
                  beforeExerciseTextTip
                }
              />
            )}
            <Tip text={beforeExerciseTextTip} />
          </Col>
        </Row>
      )}

      <Row jc="center" ai="flex-end" style={{ flex: 1 }}>
        {httpError?.message && (
          <Col w={[4, 8, 8]}>
            <T.P color="error" mt={4}>
              {httpError?.message}
            </T.P>
          </Col>
        )}
        <Col w={[4, 8, 8]}>
          <S.ButtonWrapper>
            {showCoachNotes && (
              <BasicButton
                variant="secondary"
                maxWidth="300px"
                to={toCoachNotes?.to}
                state={toCoachNotes?.state}
              >
                <T.P color="white" weight="semi">
                  View coach notes
                </T.P>
              </BasicButton>
            )}
            <BasicButton
              mt="3"
              variant="primary"
              icon="next"
              maxWidth="300px"
              onClick={() => {
                if (user.role === userRoles.COACH && generalState?.isLearning) {
                  mutateAsync({ selfLearning: false });
                }

                navigate(
                  navRoutes.EXERCISES.SINGLE_STEP.replace(
                    ':exerciseId',
                    exerciseId
                  ).replace(':stepId', 'start')
                );
              }}
              loading={isLoading}
            >
              <T.P color="white" weight="semi">
                Next
              </T.P>
            </BasicButton>
          </S.ButtonWrapper>
        </Col>
      </Row>
    </S.Wrapper>
  );
};

export default Content;

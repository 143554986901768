import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Col, Row } from '../../../components/Grid';
import * as S from './style';
import * as T from '../../../components/Typography';
import { Loading } from '../../../components';
import { BasicButton } from 'components/Button';
import SuccessModal from './SuccessModal';

import { navRoutes, organisationStatuses } from '../../../constants';

import {
  useGetFacilitatorById,
  useUpdateFacilitatorOrganisations,
} from 'api-calls/facilitator.queries';
import { useGetOrganisations } from 'api-calls/organisations.queries';
import { MultiDropDown } from 'components/Inputs';
import { BackButton } from 'components/BackButton';

const UpdateFacilitatorsOrganisations = () => {
  const { id: facilitatorId } = useParams();

  const navigate = useNavigate();

  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);

  const [selectedOrganisations, setSelectedOrganisations] = useState([]);

  const {
    organisations,
    error: getOrganisationsError,
    isLoading: getOrganisationsLoading,
  } = useGetOrganisations({
    enabled: true,
    limit: 999999,
  });

  const activeOrganisations =
    organisations?.filter((o) => o.status === organisationStatuses.ACTIVATED) ||
    [];

  const {
    mutateAsync: updateFacilitatorOrganisations,
    isLoading: updateFacilitatorOrganisationsLoading,
    error: updateFacilitatorOrganisationsError,
  } = useUpdateFacilitatorOrganisations(facilitatorId, {});

  const {
    facilitator,
    isLoading: isLoadingFacilitator,
    error: errorFacilitator,
  } = useGetFacilitatorById(facilitatorId, {
    enabled: !!facilitatorId,
    onSuccess: (data) => {
      setSelectedOrganisations(
        data?.organisations?.map((o) => ({
          label: o.organisationName,
          value: o.organisationId,
        }))
      );
    },
  });

  const isApiRequestThrownError =
    errorFacilitator?.message ||
    getOrganisationsError?.message ||
    updateFacilitatorOrganisationsError?.message;

  if (isLoadingFacilitator || getOrganisationsLoading) return <Loading />;

  const { fullName } = facilitator;

  const handleSubmit = () => {
    updateFacilitatorOrganisations(
      {
        organisationIds: selectedOrganisations.map(
          (selected) => selected.value
        ),
      },
      {
        onSuccess: () => {
          setIsSuccessModalVisible(true);
        },
        onError: () => {
          setIsSuccessModalVisible(false);
        },
      }
    );
  };

  return (
    <>
      <BackButton ml={'7'} mlT={'0'} mlM={'0'} mb={'56px'} />
      <S.Wrapper>
        <Row ai="center" mb={5}>
          <Col w={[4, 12, 12]}>
            <T.H1 color="neutralMain">{fullName}</T.H1>
          </Col>
        </Row>

        <Row>
          <Col w={[4, 12, 12]}>
            <T.P color="neutralMain" weight="bold" mb="20px">
              Select which organisations they are a facilitator of
            </T.P>
            <MultiDropDown
              multiple
              options={activeOrganisations
                ?.filter(
                  (o) =>
                    !selectedOrganisations
                      ?.map((selected) => selected.value)
                      ?.includes(o.id)
                )
                ?.map((organisation) => ({
                  label: organisation.name,
                  value: organisation.id,
                }))}
              value={selectedOrganisations}
              selected={selectedOrganisations}
              loading={getOrganisationsLoading}
              onChange={(_, value) => {
                setSelectedOrganisations(value);
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col w={[4, 12, 12]}>
            <BasicButton
              mt={6}
              variant="primary"
              disabled={updateFacilitatorOrganisationsLoading}
              loading={updateFacilitatorOrganisationsLoading}
              type="submit"
              handleClick={handleSubmit}
            >
              <T.P color="white" weight="semi">
                Update
              </T.P>
            </BasicButton>
          </Col>

          {isApiRequestThrownError && (
            <T.P mb={2} mt={2} color="error">
              {errorFacilitator?.message ||
                getOrganisationsError?.message ||
                updateFacilitatorOrganisationsError?.message ||
                'Something went wrong'}
            </T.P>
          )}
        </Row>

        <SuccessModal
          message="Their assigned organisations have been updated and the facilitator will receive an email to let them know."
          onSuccess={() => navigate(navRoutes.COMMON.FACILITATORS)}
          isModalVisible={isSuccessModalVisible}
          setIsModalVisible={setIsSuccessModalVisible}
        />
      </S.Wrapper>
    </>
  );
};

export default UpdateFacilitatorsOrganisations;

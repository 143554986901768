import { useFetch, usePatch } from '../utils/queries';
import { BADGES_BASE } from '../constants';

function useGetBadges({ type }, options) {
  const context = useFetch(`${BADGES_BASE}`, { type }, options);
  return { ...context };
}

function UpdateBadges(data) {
  return usePatch(`${BADGES_BASE}`, data, {
    options: {
      invalidateKey: [`${BADGES_BASE}`],
    },
  });
}

export { useGetBadges, UpdateBadges };

import { useState, useMemo } from 'react';
import {
  useGetCoaches,
  useGetCoachesCount,
  useUpdateCoachApprovalStatusHook,
} from '../../../api-calls/coaches.queries';
import getCountText from 'utils/getCountText';
import { debounce } from 'lodash';
import useCsvDownload from '../../../Hooks/useCsvDownload';
import { useAuth } from 'context/auth';
import { userRoles } from '../../../constants';
import { Button, Inputs } from '../../../components';

import * as T from '../../../components/Typography';
import getCoachTableColumns from './columns';
import { Row, Col } from '../../../components/Grid';

import SelectSortOptionComponent from '../../../components/SelectSortOptionComponent';
import TabsCommonTable from '../../../components/CommonTables/TabsCommonTable';
import { navRoutes } from '../../../constants';

const coachesTableEmptyState = {
  title: 'You currently do NOT have any coaches',
  subTitle:
    'Currently, there are no learners registered. Begin inviting learners to embark on their learning journey with us',
  buttonText: 'Setup  a new coach',
  buttonLink: navRoutes.COMMON.ADD_COACHES,
  disabled: false,
};

const pageSize = 20;

const BasicTable = ({
  tableTitle = 'Coaches',
  assignCoachActionColumn = undefined,
}) => {
  const [page, setPage] = useState(1);
  const [statusType, setStatusType] = useState('APPROVED');
  const [debounceSearchValue, setDebounceSearchValue] = useState('');
  const [data, handleClick] = useCsvDownload('/csv/coaches');
  const {
    user: { role },
  } = useAuth();

  const isAdmin = role === userRoles.ADMIN;

  const [actionFired, setActionFired] = useState(undefined);

  const [sortInfo, setSortInfo] = useState({
    key: 'created_at',
    order: 'ASC',
  });

  const [searchValue, setSearchValue] = useState('');

  const {
    coaches,
    error: getCoachesError,
    isLoading: getCoachesLoading,
  } = useGetCoaches({
    statusType,
    page,
    limit: pageSize,
    searchValue: debounceSearchValue,
    sortField: sortInfo.key,
    sortOrder: sortInfo.order,
  });

  const {
    mutateAsync: updateCoachApprovalStatus,
    isLoading: updateCoachApprovalStatusLoading,
  } = useUpdateCoachApprovalStatusHook();
  const {
    coachesCount,
    error: getCoachesCountError,
    isLoading: getCoachesCountLoading,
  } = useGetCoachesCount({
    searchValue: debounceSearchValue,
  });

  const searchValueFunctionDebounced = useMemo(
    () => debounce((value) => setDebounceSearchValue(value), 500),
    [setDebounceSearchValue]
  );
  if (getCoachesError?.message || getCoachesCountError?.message) {
    return (
      <T.P mb={2} mt={2} color="error">
        {getCoachesError?.message || getCoachesCountError?.message}
      </T.P>
    );
  }

  const {
    awaitingSignupStatusCount,
    invitedStatusCount,
    approvedStatusCount: approvedCount,
    rejectedStatusCount: rejectedCount,
    inTrainingStatusCount,
    searchCount,
    total,
  } = coachesCount || {};

  const {
    awaitingSignupStatusCount: awaitingSignupStatusCountTab,
    approvedStatusCount: approvedCountTab,
    invitedStatusCount: invitedStatusCountTab,
    rejectedStatusCount: rejectedCountTab,
    inTrainingStatusCount: inTrainingStatusCountTab,
  } = searchCount || {
    awaitingSignupStatusCount: 0,
    approvedStatusCount: 0,
    invitedStatusCount: 0,
    rejectedStatusCount: 0,
    inTrainingStatusCount: 0,
  };

  const apiLoading = getCoachesLoading || getCoachesCountLoading;

  const commonTableProps = {
    loading: apiLoading,
    emptyState: !total && coachesTableEmptyState,
    setPage,
    data: coaches,
    totalRecords: coaches?.[0]?.total || 0,
  };
  return (
    <>
      <Row ai="center" mb={5} mt={7} mtT={5}>
        <Col w={[4, 12, 4.5]} mbT={4}>
          <T.H2 color="neutralMain" size="medLarge">
            {tableTitle}
          </T.H2>
        </Col>

        <Col w={[4, 4, 2.5]} mbM={2}>
          <SelectSortOptionComponent
            sortOptions={[
              {
                value: 'oldest-to-newest',
                label: 'Oldest to Newest',
              },
              {
                value: 'newest-to-oldest',
                label: 'Newest to Oldest',
              },
            ]}
            onChange={(value) => {
              if (value === 'oldest-to-newest') {
                setSortInfo({
                  key: 'created_at',
                  order: 'ASC',
                });
              } else if (value === 'newest-to-oldest') {
                setSortInfo({
                  key: 'created_at',
                  order: 'DESC',
                });
              }
            }}
          />
        </Col>

        <Col w={[4, 4, 2.5]} mbM={2}>
          <Inputs.BasicInput
            w="100%"
            value={searchValue}
            searchIcon
            handleChange={(value) => {
              setSearchValue(value);
              setPage(1);
              searchValueFunctionDebounced(value);
            }}
            placeholder="Search..."
            m={{ ml: '0' }}
          />
        </Col>

        <Col w={[4, 4, 2.5]} mbM={2}>
          <Button.BasicButton
            width="100%"
            disabled={data.loading}
            handleClick={handleClick}
            loading={data.loading}
            variant="tertiary"
            icon={'export'}
          >
            Export data
          </Button.BasicButton>
        </Col>
      </Row>
      <TabsCommonTable
        page={page}
        tabs={[
          {
            key: '1',
            title: 'Approved',
            count: approvedCount
              ? getCountText(
                  Number(approvedCount),
                  Number(approvedCountTab),
                  !!debounceSearchValue,
                  getCoachesLoading
                )
              : 0,
            columns: getCoachTableColumns({
              tab: 'approved',
              showAdminFields: isAdmin === true,
              assignCoachActionColumn,
              handleDbsUpdate: () => {},
              page,
            }),
            ...commonTableProps,
            totalRecords: Number(approvedCountTab) || Number(approvedCount),
          },
          {
            key: '2',
            title: 'Awaiting',
            count: awaitingSignupStatusCount
              ? getCountText(
                  Number(awaitingSignupStatusCount),
                  Number(awaitingSignupStatusCountTab),
                  !!debounceSearchValue,
                  getCoachesLoading
                )
              : 0,
            columns: getCoachTableColumns({
              tab: 'awaiting',
              handleDbsUpdate: () => {},
              showAdminFields: isAdmin === true,
              actions: {
                rejectActionLoading:
                  actionFired === 'reject' && updateCoachApprovalStatusLoading,
                acceptActionLoading:
                  actionFired === 'accept' && updateCoachApprovalStatusLoading,
                reject: async ({ id }) => {
                  setActionFired('reject');
                  await updateCoachApprovalStatus({
                    note: '',
                    coachId: id,
                    status: 'REJECTED',
                  });
                  setActionFired(undefined);
                },
                accept: async ({ id }) => {
                  setActionFired('accept');
                  await updateCoachApprovalStatus({
                    note: '',
                    coachId: id,
                    status: 'APPROVED',
                  });
                  setActionFired(undefined);
                },
              },
              page,
            }),
            ...commonTableProps,
            totalRecords:
              Number(awaitingSignupStatusCountTab) ||
              Number(awaitingSignupStatusCount),
          },
          {
            key: '3',
            title: 'In training',
            count: inTrainingStatusCount
              ? getCountText(
                  Number(inTrainingStatusCount),
                  Number(inTrainingStatusCountTab),
                  !!debounceSearchValue,
                  getCoachesLoading
                )
              : 0,
            columns: getCoachTableColumns({
              tab: 'inTraining',
              handleDbsUpdate: () => {},
              showAdminFields: isAdmin === true,
              page,
              assignCoachActionColumn,
            }),
            ...commonTableProps,
            totalRecords:
              Number(inTrainingStatusCountTab) || Number(inTrainingStatusCount),
          },
          {
            key: '4',
            title: 'Invited',
            count: invitedStatusCount
              ? getCountText(
                  Number(invitedStatusCount),
                  Number(invitedStatusCountTab),
                  !!debounceSearchValue,
                  getCoachesLoading
                )
              : 0,
            columns: getCoachTableColumns({
              tab: 'invited',
              handleDbsUpdate: () => {},
              showAdminFields: isAdmin === true,
              page,
            }),
            ...commonTableProps,
            totalRecords:
              Number(invitedStatusCountTab) || Number(invitedStatusCount),
          },
          {
            key: '5',
            title: 'Rejected',
            count: rejectedCount
              ? getCountText(
                  Number(rejectedCount),
                  Number(rejectedCountTab),
                  !!debounceSearchValue,
                  getCoachesLoading
                )
              : 0,
            columns: getCoachTableColumns({
              tab: 'rejected',
              handleDbsUpdate: () => {},
              showAdminFields: isAdmin === true,
              page,
            }),
            ...commonTableProps,
            totalRecords: Number(rejectedCountTab) || Number(rejectedCount),
          },
        ]}
        tabsProps={{
          onChange: (key) => {
            setPage(1);
            if (key === '1') setStatusType('APPROVED');
            if (key === '2') setStatusType('AWAITING_SIGNUP');
            if (key === '3') setStatusType('IN_TRAINING');
            if (key === '4') setStatusType('AWAITING_INVITE');
            if (key === '5') setStatusType('REJECTED');
          },
        }}
      />
    </>
  );
};

export default BasicTable;

import React, { useEffect, useRef } from 'react';
import * as S from './style';
import Exercises, { MilestoneCard } from '../Exercises';
import { navRoutes } from './../../constants';
import { useAuth } from './../../context/auth';
import { userRoles } from './../../constants/data-types';
import ProgressCheck from './../../components/ProgressCheck';
import * as C from '../../components';
import { useGeneralState } from 'context/general-state';
import MilestoneBar from './MilestoneBar';
import { useMediaQuery } from 'react-responsive';
import theme from 'theme';

const DashedLine = () => {
  return <C.Icon icon="dashedLine" />;
};

const CurvedDashedLine = ({ firstExercise }) => {
  return firstExercise ? (
    <S.CurvedLineWrapper>
      <C.Icon icon="curvedDashedLine" height={200} width={100} />
    </S.CurvedLineWrapper>
  ) : (
    <C.Icon icon="curvedDashedLine" height={200} width={100} />
  );
};

let exerciseCardLink = (item, isCoach, isPlayground) => {
  if (isCoach && !isPlayground) {
    return navRoutes.EXERCISES.EXERCISE_EXPLAINER.replace(
      ':exerciseId',
      item.id
    );
  }

  return navRoutes.EXERCISES.SELECT_WITH_COACH_OR_NOT.replace(
    ':exerciseId',
    item.id
  );
};

const ExerciseTimeline = ({
  milestone,
  index,
  activeExercise,
  lastCompletedMilestone,
  isLastMilestone,
  activeExerciseRef,
}) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.mobile})`,
  });
  const targetRef = useRef();
  const { state: generalState } = useGeneralState();
  const [isElementVisible, setIsElementVisible] = React.useState(false);
  const { user } = useAuth();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsElementVisible(true);
          } else {
            setIsElementVisible(false);
          }
        });
      },
      {
        root: null, // viewport
        rootMargin: '0px',
        threshold: 0.01,
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  return (
    <S.MilestoneSection key={milestone.id} ref={targetRef}>
      <MilestoneBar
        title={milestone.title}
        isCompleted={milestone.completed}
        exercises={milestone.exercises}
        isFirstItem={index === 0}
      />
      {index !== 0 && isMobile ? <DashedLine /> : null}
      {index === 0 && isMobile ? <CurvedDashedLine /> : null}
      {index === 0 && !isMobile && (
        <S.MilestoneTitle>
          <MilestoneCard title={milestone.title} id={milestone.milestoneId} />
          <S.StartPathWrapper>
            <C.Icon icon="startPath" />
          </S.StartPathWrapper>
        </S.MilestoneTitle>
      )}
      {milestone.exercises.map((item) => {
        return (
          <>
            <S.CardWrapper
              key={item.id}
              ref={activeExercise?.id === item.id ? activeExerciseRef : null}
              alignItems={item.index % 2 ? 'flex-start' : 'flex-end'}
            >
              <Exercises
                title={item.title}
                exerciseComplete={item.exerciseComplete && !item.savedExercise}
                savedExercise={item.savedExercise}
                titleAudioKey={isElementVisible ? item.titleAudioKey : null} // only load audio when in viewport
                to={exerciseCardLink(
                  item,
                  user.role === userRoles.COACH,
                  generalState.isPlayground
                )}
                disabled={
                  (lastCompletedMilestone
                    ? item.milestoneId > lastCompletedMilestone.milestoneId
                    : true) &&
                  !item.exerciseComplete &&
                  !item.savedExercise &&
                  item.id !== activeExercise?.id &&
                  !generalState.isPlayground
                }
              />
            </S.CardWrapper>
            <S.CardWrapper>
              {isMobile ? (
                <DashedLine />
              ) : item?.index % 2 ? (
                <C.Icon icon="leftStepToRightStep" />
              ) : (
                <C.Icon icon="righSteptToLeftStep" />
              )}
            </S.CardWrapper>
          </>
        );
      })}
      <S.CardWrapper
        alignItems={
          milestone?.progressCheckCardIndex % 2 === 0
            ? 'flex-start'
            : 'flex-end'
        }
      >
        <ProgressCheck
          userRole={user.role}
          code={milestone.code}
          progressProfileFilled={milestone.progressProfileFilled}
          milestoneId={milestone.milestoneId}
          progressCheckFilled={milestone.progressCheckFilled}
        />
      </S.CardWrapper>
      <S.CardWrapper>
        {isLastMilestone || isMobile ? null : (
          <C.Icon
            icon={
              milestone?.progressCheckCardIndex % 2 === 0
                ? 'leftProgressToRightStep'
                : 'rightProgressToLeftStep'
            }
          />
        )}
        {isMobile && <C.Icon icon="dashedLine" />}
      </S.CardWrapper>
    </S.MilestoneSection>
  );
};

export default ExerciseTimeline;

import styled from '@emotion/styled';
import setMargin from '../../../helpers/set-margin';
import * as CS from '../style';
import search from '../../../components/assets/search.svg';

const InputCommonStyle = ({ theme, error, color, disabled }) => `
  width: 100%;
  padding-top: 4px;
  padding-left: ${theme.spacings[4]};
  padding-right: ${error ? '45px' : theme.spacings[3]};
  font-size: 1rem !important;
  color: ${color ? theme.colors[color] || color : theme.colors.neutralDark};
  cursor: ${disabled && 'not-allowed'};
  border-radius: 16px;
  background-color: transparent;
  vertical-align: middle;
  min-height: 64px;

  :hover {
    border: solid 1px ${
      error ? theme.colors.error : theme.colors.primaryMain
    } !important;
  }


  :focus-within {
    outline: none;
    border: solid 1px ${theme.colors.primaryMain};
    box-shadow :
        0 0 0 2px white,
        0 0 0 5px ${theme.colors.primaryMain};
  }


  :focus {
    outline: none;
    border: solid 1px ${theme.colors.primaryMain};
    box-shadow :
        0 0 0 2px white,
        0 0 0 5px ${theme.colors.primaryMain};
  }

  ::placeholder {
    overflow: visible;
    font-size: 1rem !important;
  }
`;

export const Input = styled.input`
  ${setMargin};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  ${CS.commonBorderStyle};
  ${InputCommonStyle};
  ${CS.placeholderStyle};
  ${({ searchIcon }) =>
    searchIcon &&
    `
    padding-left: 48px;
    background-size: 12px;
    background: url(${search}) no-repeat center left 12px;
    `}
  ::placeholder,
    ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.neutral60};
  }
`;

export const InputWrapper = styled.div`
  ${setMargin};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  ${CS.commonBorderStyle};
  ${InputCommonStyle};
  ${CS.placeholderStyle};
  height: 100%;
`;

export const BasicInputRelativeDiv = styled.div`
  position: relative;
  width: 100%;
`;

export const AlertWrapper = styled.div`
  position: absolute;
  top: 5%;
  transform: translate(0, 50%);
  right: 12px;
`;

export const InfoWrapper = styled.button`
  margin-bottom: ${({ theme }) => theme.spacings[2]};
  margin-left: ${({ theme }) => theme.spacings[2]};
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
`;

import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { BasicButton } from '../../../components/Button';
import { Textarea } from '../../../components/Inputs';
import { Col, Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';
import SuccessModal from './SuccessModal';

import validate from '../../../validation/schemas/reject-coach';
import { coachApprovalStatuses } from '../../../constants';

import { useUpdateCoachApprovalStatus } from '../../../api-calls/coaches.queries';

const submissionStatuses = {
  reject: coachApprovalStatuses.REJECTED,
  interview: coachApprovalStatuses.INVITED_TO_INTERVIEW,
};

const ManageInterviewOrReject = () => {
  const { coachId, action } = useParams();
  const [note, setNote] = useState('');
  const [noteValidationError, setNoteValidationError] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const {
    mutate: offerInterview,
    error: httpError,
    isLoading,
  } = useUpdateCoachApprovalStatus({
    note,
    coachId,
    status: submissionStatuses[action],
  });
  const submitAttempt = useRef(false);

  const rejectCoach =
    submissionStatuses[action] === coachApprovalStatuses.REJECTED;

  const validateNote = () => {
    try {
      if (note.length > 0) {
        validate({ note });
      }

      setNoteValidationError(false);
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setNoteValidationError(error?.inner?.note);
      }
      return false;
    }
  };

  const handleSubmission = async (e) => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateNote();

    if (isValid) {
      offerInterview(
        { note, coachId, status: submissionStatuses[action] },
        {
          onSuccess: () => {
            setIsModalVisible(true);
          },
        }
      );
    }
  };

  useEffect(() => {
    if (submitAttempt.current) {
      validateNote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note]);

  return (
    <>
      <Row mt="3">
        <Col w={[4, 9, 9]}>
          <T.H1 color="neutralMain">
            {rejectCoach ? 'Reject application' : 'Offer an interview'}{' '}
          </T.H1>
          <T.P mt="8">
            An email will be sent out to the applicant. Would you like to add an
            additional message?
          </T.P>
        </Col>
      </Row>
      <Row mt="3">
        <Col w={[4, 9, 9]}>
          <Textarea
            label={`Add note`}
            value={note}
            handleChange={(note) => setNote(note)}
            m={{ mt: 5 }}
            error={noteValidationError}
          />
        </Col>
      </Row>

      <Row mt="5">
        <Col w={[4, 7, 7]}>
          <BasicButton
            disabled={isLoading}
            loading={isLoading}
            style={{ width: '300px' }}
            variant="primary"
            type="submit"
            onClick={handleSubmission}
          >
            <T.P color="white" weight="semi">
              {rejectCoach ? 'Reject application' : 'Offer interview'}
            </T.P>
          </BasicButton>
        </Col>
        {httpError ? <T.P color="error">{httpError?.message}</T.P> : null}
        <SuccessModal
          isLoading={isLoading}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          rejectCoach={rejectCoach}
        />
      </Row>
    </>
  );
};

export default ManageInterviewOrReject;

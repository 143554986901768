import { Button, Typography as T, Video } from 'components';
import * as S from './style';
import { getMediaUrl } from 'helpers';
import { Col } from 'components/Grid';
import { useNavigate } from 'react-router-dom';
import { navRoutes as R, audio } from '../../../constants';
import { useGeneralState } from '../../../context/general-state';

const Welcome = () => {
  const navigate = useNavigate();
  const handleStartExercises = () => {
    navigate(R.LEARNER.DASHBOARD);
  };
  const { state: generalState } = useGeneralState();

  return (
    <S.Wrapper>
      <S.PlayButtonWrapper>
        <Button.PlayButton
          width="56px"
          height="56px"
          audioUrl={getMediaUrl(
            audio.welcome,
            true,
            generalState?.preferredVoice
          )}
          iconProps={{ width: 27.2 }}
          backgroundColor="neutralSurface"
          fallbackText={`Welcome`}
        />
        <T.H2 ml="4">Welcome 🎉</T.H2>
      </S.PlayButtonWrapper>

      <S.PlayButtonWrapper mt="4">
        <Button.PlayButton
          width="32px"
          height="32px"
          audioUrl={getMediaUrl(
            audio.welcomeToTurningPages,
            true,
            generalState?.preferredVoice
          )}
          iconProps={{ width: 27.2 }}
          backgroundColor="transparent"
          withoutButtonStyle
          fallbackText={`Welcome to Turning Pages Digital, you can watch the following video to find out more.`}
        />
        <T.P ml="2">
          Welcome to Turning Pages Digital, you can watch the following video to
          find out more.
        </T.P>
      </S.PlayButtonWrapper>

      <Col w={[4, 12, 12]} mt="5">
        <Video
          src={'https://www.youtube.com/embed/3ecB5090QCc?si=RvaTAb88BF8uWLhY'}
        />
      </Col>

      <S.PlayButtonWrapper mt="6">
        <Button.BasicButton
          variant="primary"
          iconProps={{ width: '23px', height: '23px' }}
          handleClick={handleStartExercises}
          showSound
          width="100%"
          audioUrl={getMediaUrl(
            audio.getStarted,
            true,
            generalState?.preferredVoice
          )}
          fallbackText={'Get started'}
        >
          <T.P weight="bold" color="white">
            Get started
          </T.P>
        </Button.BasicButton>
      </S.PlayButtonWrapper>
    </S.Wrapper>
  );
};

export default Welcome;

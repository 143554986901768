import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Layout from '../../../components/Layout';

import Loading from '../../../components/Loading';
import { useExercise } from '../../../context/exercise';
import { useGeneralState } from '../../../context/general-state';
import { navRoutes as NR } from '../../../constants';
import { Exercises } from '../../../api-calls';

import { replaceMediaKeysWithUrls } from '../../../helpers';
import { useCreateCompletedStep } from '../../../api-calls/exercises.queries';
import { useAuth } from 'context/auth';
import StuckAtReview from './StuckAtReview';

import Formats from './Formats';

const initStep = {
  audioKey: '',
  correctOption: null,
  imageKey: '',
  options: null,
  subtitle: '',
  title: '',
};

const ExerciseStepContent = ({ preview }) => {
  const navigate = useNavigate();
  const { state: generalState } = useGeneralState();
  const auth = useAuth();

  const { stepId, exerciseId } = useParams();
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(initStep);
  const { exercise, nextStep } = useExercise();
  const { mutateAsync: createCompletedStep, isLoading } =
    useCreateCompletedStep({
      id: stepId,
      learnerId: auth.user.selectedLearnerId,
      withCoach: generalState.withCoach,
    });

  const handleNext = async () => {
    if (generalState?.isLearning) {
      await createCompletedStep({
        id: stepId,
        learnerId: auth.user.selectedLearnerId,
        withCoach: generalState.withCoach,
      });
    }

    if (nextStep) {
      navigate(
        NR.EXERCISES.SINGLE_STEP.replace(':exerciseId', exerciseId).replace(
          ':stepId',
          nextStep.id
        )
      );
    } else {
      if (generalState.exerciseFlow === 'REVIEW_SELF_LEARNING') {
        navigate(
          NR.EXERCISES.REVIEW_COMPLETED_SL.replace(':exerciseId', exerciseId)
        );
      } else {
        navigate(
          NR.EXERCISES.EXERCISE_COMPLETED.replace(
            ':exerciseId',
            exerciseId
          ).replace(':milestoneId', exercise.milestoneId)
        );
      }
    }
  };

  useEffect(() => {
    let mount = true;
    async function fetchData() {
      setLoading(true);

      const { data, error } = await Exercises.getStepById({
        stepId: stepId,
        exerciseId,
      });

      if (mount) {
        if (error?.statusCode === 404) {
          navigate(NR.GENERAL.NOT_FOUND);
        }
        setStep({
          ...replaceMediaKeysWithUrls(data, generalState?.preferredVoice),
        });
        setLoading(false);
      }
    }

    if ((!Number(stepId) && stepId !== 'start') || !Number(exerciseId)) {
      navigate(NR.GENERAL.NOT_FOUND);
    } else {
      fetchData();
    }

    return () => {
      mount = false;
    };
  }, [stepId, exerciseId, generalState?.preferredVoice, navigate]);

  if (loading) return { Component: <Loading />, layout: 'general' };
  return Formats({
    exercise,
    handleNext,
    preview,
    step,
    isLoading,
  });
};

const ExerciseStep = (props) => {
  const { Component, layout } = ExerciseStepContent(props);

  return (
    <Layout
      layout={layout}
      headerProps={{
        type: 'progress',
      }}
      image="secondaryColor"
    >
      {Component}
    </Layout>
  );
};

export default ExerciseStep;
export { StuckAtReview };

import { useAuth } from '../../context/auth';
import { useState } from 'react';
import { ColorSelect } from '../../components/Inputs';
import { Users } from '../../api-calls';
import Loading from '../../components/Loading';
import { Col, Row } from '../../components/Grid';
import { BasicButton, PlayButton } from '../../components/Button';
import { audio } from '../../constants';
import { getMediaUrl } from '../../helpers';
import { useGeneralState } from '../../context/general-state';

import * as S from './style';
import { useNavigate } from 'react-router-dom';

const colors = [
  {
    label: 'Blue',
    value: 'irlenBlue',
  },
  {
    label: 'Green',
    value: 'irlenGreen',
  },
  {
    label: 'Yellow',
    value: 'irlenYellow',
  },
  {
    label: 'Red',
    value: 'irlenRed',
  },
];
const OverlayColor = () => {
  const [state, setState] = useState({});
  const navigate = useNavigate();
  const {
    user: { overlayColor, id },
    setOverlayColor,
  } = useAuth();
  const { state: generalState } = useGeneralState();

  const updateColor = async ({ color }) => {
    setState({ loading: true });

    const { error } = await Users.updateOverlayColor({
      id,
      color: color || 'default',
    });

    setState({ loading: false });

    if (error) {
      setState({ httpError: error.message });
      return false;
    }
    return true;
  };

  const handleSelect = (color) => {
    const successPostColor = updateColor({
      color: color !== overlayColor ? color : 'default',
    });
    if (successPostColor) {
      setOverlayColor(color !== overlayColor ? color : 'default');
      if (color === overlayColor) {
        setOverlayColor('default');
      } else {
        setOverlayColor(color);
      }
    }
  };

  if (state?.loading) {
    return <Loading />;
  }

  return (
    <S.Wrapper>
      <Row ai="center">
        <Col w={[4, 10, 10]}>
          <PlayButton
            audioUrl={getMediaUrl(
              audio.whichColouredOverlayWouldYouLike,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: '32px', height: '32px' }}
            padding="12px"
            mr="2"
            fallbackText="Which coloured overlay would you like?"
          >
            Which coloured overlay would you like?
          </PlayButton>
        </Col>
      </Row>
      <Row mt="5" mtT="4">
        <Col w={[4, 12, 12]}>
          <ColorSelect
            options={colors}
            selected={overlayColor}
            handleChange={handleSelect}
          />
        </Col>
      </Row>
      <Row jc="center" ai="flex-end" style={{ flex: 1 }}>
        <Col w={[4, 8, 8]}>
          <S.ButtonWrapper>
            <BasicButton
              mtT="4"
              mt="5"
              variant="primary"
              icon="back"
              disabled={false}
              onClick={() => navigate(-1)}
            ></BasicButton>
          </S.ButtonWrapper>
        </Col>
      </Row>
    </S.Wrapper>
  );
};

export default OverlayColor;

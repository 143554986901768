import { useState } from 'react';
import * as T from '../../../../components/Typography';
import { PlayButton, PlayBig } from '../../../../components/Button';
import EmptySpace from '../../../../components/EmptySpace';
import Option from '../../../../components/Option';
import StepFooterButtons from '../StepFooterButtons';
import * as S from './style';
import { showMovingBlock } from '../../../../helpers';

const DragAndDropV2 = ({
  data: {
    options: _options = [],
    correctAnswer,
    preFilledAnswer = [],
    textAudio,
    textAudioUrl,
  } = {},
  audioUrl,
  handleNext,
  stuckAtReview,
  disabled,
  preview,
  cmsPreview,
}) => {
  const [showAnswer, setShowAnswer] = useState(false);
  const [answers, setAnswers] = useState(
    preFilledAnswer.map((e, i) => ({ text: e, preFilled: !!e }))
  );

  const [options, setOptions] = useState(
    _options.map((e, i) => ({
      text: e.option,
      id: i,
    }))
  );
  const [activePosition, setActivePosition] = useState(
    preFilledAnswer.indexOf(preFilledAnswer.find((e) => !e)) || 0
  );

  const onClickAnswer = (answer, i) => {
    setActivePosition(i);

    if (answer?.text) {
      showMovingBlock({
        OptionElId: `option-${answer.id}`,
        answerElId: `answer-${i}`,
        movingElId: 'moving-div',
        isBeingSelected: true,
        done: () => {
          setOptions((_ops) =>
            _ops.map((e) => {
              if (e.id === answer.id) {
                return { ...e };
              }
              return e;
            })
          );
        },
      });
      setAnswers((_answers) =>
        _answers.map((e, _i) => {
          if (_i === i) {
            return { id: null, text: null };
          }
          return e;
        })
      );
    }
  };

  const onClickOption = (option) => {
    setOptions((_ops) =>
      _ops.map((e) => {
        if (e.id === option.id) {
          return { ...e };
        }
        return e;
      })
    );
    showMovingBlock({
      OptionElId: `option-${option.id}`,
      answerElId: `answer-${activePosition}`,
      movingElId: 'moving-div',
      isBeingSelected: false,
      done: () => {
        setAnswers((_answers) => {
          const newAnswers = _answers.map((e, i) => {
            if (i === activePosition) {
              return { ...option };
            }
            return e;
          });

          return newAnswers;
        });

        setActivePosition((_activePosition) => {
          const availablePositions = [...answers, ...answers].map(
            (answer, i) => !answer.text && i > _activePosition
          );

          const nextSpace = availablePositions.indexOf(true);
          return nextSpace % answers.length;
        });
      },
    });
  };

  const onFocusAnswer = (i) => {
    setActivePosition(i);
  };

  const onClickNext = () => {
    if (showAnswer || stuckAtReview) {
      handleNext();
    } else {
      setShowAnswer(true);
    }
  };
  const answer = answers.map((e) => e.text).join('');

  if (!options?.length) {
    return (
      <S.Wrapper>
        {<T.P color="error">Exercise missing required fields </T.P>}
      </S.Wrapper>
    );
  }
  return (
    <S.Wrapper>
      {/* keep this to animation */}
      <div style={{ display: 'none' }}>
        <Option id="moving-div"></Option>
      </div>
      {textAudio && (
        <PlayButton
          audioUrl={textAudioUrl}
          iconProps={{ width: '32px', height: '32px' }}
          padding="12px"
          fallbackText={textAudio}
        >
          {textAudio}
        </PlayButton>
      )}

      <S.ButtonWrapper>
        <PlayBig audioUrl={audioUrl} mt="3" />
      </S.ButtonWrapper>
      <S.PreFilledAnswersGridWrapper>
        {answers.map((answer, i) => {
          let bgColor = answer?.text
            ? 'neutralSurface'
            : 'rgba(255, 255, 255, 0.5)';
          bgColor = answer.preFilled ? 'white' : bgColor;
          return (
            <EmptySpace
              isActive={i === activePosition && !cmsPreview}
              onClick={() =>
                answer.preFilled || showAnswer
                  ? undefined
                  : onClickAnswer(answer, i)
              }
              onFocus={() =>
                answer.preFilled || showAnswer ? undefined : onFocusAnswer(i)
              }
              id={`answer-${i}`}
              bgColor={bgColor}
              key={i}
              w="74px"
              h="74px"
              size="large"
              disabled={answer.preFilled || showAnswer}
              preFilled={answer.preFilled}
            >
              {answer?.text}
            </EmptySpace>
          );
        })}
      </S.PreFilledAnswersGridWrapper>
      <S.OptionsGridWrapper showAnswer={showAnswer}>
        {showAnswer ? (
          <T.P m={5} size="extraLarge">
            = {answer}
          </T.P>
        ) : (
          <S.OptionsGrid>
            {options.map((op) => (
              <Option
                onClick={() => onClickOption(op)}
                id={`option-${op.id}`}
                key={op.id}
                size="large"
              >
                {op.text}
              </Option>
            ))}
          </S.OptionsGrid>
        )}
      </S.OptionsGridWrapper>
      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={onClickNext}
        preview={preview}
        disabled={disabled}
        enableNext={answer === correctAnswer}
      />
    </S.Wrapper>
  );
};

export default DragAndDropV2;

export const TRAINING_DATA = [
  {
    order: 1,
    index: 1,
    title: 'How Turning Pages works',
    desc: 'Learn about the history and structure of Turning Pages',
    user: 'COACH',
    clickable: true,
    routeKey: 'TRAINING_STEP',
    param: ':stepId',
    introduction: `Shannon Trust first developed Turning Pages back in 2015 and have used this resource to support thousands of learners. An evaluation of the programme, conducted by Birmingham City University in 2016 showed that it is highly effective in supporting people to become readers.

Turning Pages is phonics based and has been developed specifically for adults. The programme consists of 5 different manuals, which increase incrementally in difficulty (and contain milestones along the way. Using the resource, we can support a wide range of people. We can use Turning Pages with those who can’t read a word, or with anyone below entry level 3 literacy that is looking to improve their reading.

Turning Pages is a pick up and go resource, which means that there are lots of instructions for coaches to support learners. In creating this digital version of the programme we have made sure that there continue to be available to provide guidance.`,
    videoURLs: [
      {
        url: 'https://www.youtube.com/embed/HJsxEguZPbM?si=luy0lTTdK6eQ3TXD',
        title: '',
      },
    ],
  },
  {
    order: 2,
    index: 2,
    title: 'Understand phonics',
    desc: 'Learn about the relationship between letters and sounds',
    user: 'COACH',
    clickable: true,
    routeKey: 'TRAINING_STEP',
    param: ':stepId',
    items: [
      {
        type: 'TickList',
        list: [
          'Having questions regarding literacy included in assessment paperwork.',
          'Having leaflets, flyers and posters available or promote via social media.',
          'Ensuring all staff/volunteers are clear on the type of support available, who it is for and how this can be accessed.',
          'Encourage staff/volunteers to broach with their clients and discuss their responses.',
          'Host events which could promote reading.',
        ],
      },
    ],
    videoURLs: [
      {
        url: 'https://www.youtube.com/embed/HgTO9tekoFs?si=KwJlmo6SI-xmJ_ho',
        title: '',
      },
    ],
  },
  {
    order: 3,

    index: 3,
    title: 'How to use this tool',
    desc: 'Learn how to navigate and utilise the Turning Pages tool effectively',
    user: 'COACH',
    clickable: true,
    routeKey: 'TRAINING_STEP',
    param: ':stepId',
    introduction:
      'In this short video tutorial we will begin by showing you how to use this tool.',
    videoURLs: [
      {
        url: 'https://www.youtube.com/embed/bcgSKV1lWbw?si=6ziV_c8gUvc8A2_3',
        title:
          'Setting up an account, navigating the dashboard and accessing training',
      },
      {
        url: 'https://www.youtube.com/embed/gL1Ct7WYI44?si=BzZxO0mR5bhdzHuJ',
        title: 'Accepting Learners',
      },
      {
        url: 'https://www.youtube.com/embed/o4fUS20KbfQ?si=109a3cOE2_b_KalM',
        title: 'Getting Started Coaches Part Three',
      },
    ],
  },
  {
    order: 4,
    index: 4,
    title: 'How to get started with your first learner',
    desc: 'Learn how to onboard learners and introduce the Turning Pages tool',
    user: 'COACH',
    clickable: true,
    routeKey: 'TRAINING_STEP',
    param: ':stepId',
    introduction:
      'You can watch a short tutorial regarding onboarding a learner here:',
    videoURLs: [
      {
        url: 'https://www.youtube.com/embed/Hia77Lm2GxI?si=nhROOp2XsUZguxXF',
        title: 'Getting Started with a Learner',
      },
      {
        url: 'https://www.youtube.com/embed/YEpioTCqlJE?si=gzITkiB5IRSwlojb',
        title: 'Onboarding',
      },
    ],

    items: [
      {
        type: 'Paragraph',
        list: [
          'For most Reading Coaches, the idea of supporting someone with reading and memorising the phonics sounds can feel a little daunting. We would firstly suggest initially taking a little bit of time to use the tutorials on this tool to practice the phonics sounds and increase your own confidence. A short tutorial can be found here:',
          'In addition, we would also suggest taking the time to provide an appropriate starting point for your learner. Some information on how to do this is also included within the tutorials in this section. Another short tutorial can be found here:',
        ],
      },
    ],
  },
  {
    order: 5,
    index: 5,
    title: 'How to coach your first learner',
    desc: 'Learn how to coach your first learner',
    user: 'COACH',
    clickable: true,
    routeKey: 'TRAINING_STEP',
    param: ':stepId',
    introduction:
      'Whilst many people will ask for help and support to improve their reading, many will try to keep this issue hidden, often through shame and embarrassment. In this short tutorial we will discuss how your organisation or service will be able to promote this support and encourage people to take part. As a basic set of tips we would recommend:',

    items: [
      {
        type: 'TickList',
        introduction:
          'The key things to consider when coaching your first learner are:',
        list: [
          'Developing a relationship with them – getting to know them and their interests',
          'Making them feel comfortable and putting them (and yourself) at ease',
          'Ensuring they understand how the tool works and what they will be doing working with you',
          'Helping them to set some personal goals',
          'Finding an appropriate starting point within the programme',
          'Setting some clear boundaries',
          'Setting up your future sessions',
        ],
      },
    ],
    videoURLs: [
      {
        url: 'https://www.youtube.com/embed/BDIcEOVpNTo?si=r6NALS6dH1oWSeVj',
        title: 'Motivating Learners',
      },
      {
        url: 'https://www.youtube.com/embed/wEpQHPhdgZo?si=-FXyd4V-UVHQBkS2',
        title: 'Being an Effective Coach',
      },
    ],
  },
];

export const LINK_LIST = [
  {
    label: 'Link 1',
    url: 'http://www.example.com',
  },
  {
    label: 'Link 2',
    url: 'http://www.example.com',
  },
  {
    label: 'Link 3',
    url: 'http://www.example.com',
  },
];

import { ORGANISATIONS_BASE } from '../constants';
import {
  useFetch,
  usePost,
  formatQueryError,
  usePatch,
} from '../utils/queries';

const useGetOrganisationByIdOrUniqueSlug = ({ id, uniqueSlug }, options) => {
  const url = uniqueSlug
    ? `${ORGANISATIONS_BASE}/unique-slug/${uniqueSlug}`
    : `${ORGANISATIONS_BASE}/${id}`;

  const queryKeyParam = uniqueSlug ? uniqueSlug : id;

  const context = useFetch(url, undefined, {
    queryKey: [`GET_ORGANISATION_BY_ID`, queryKeyParam],
    ...options,
  });

  return {
    ...context,
    organisation: context.data,
    error: formatQueryError(context),
  };
};

const useAddOrganisation = () => {
  // usePost takes url and params
  // formData gets picked up by mutate prop
  const context = usePost(`${ORGANISATIONS_BASE}`, undefined, {
    queryKey: `ADD_ORGANISATION`,
    invalidateKeys: [
      {
        key: [`GET_ORGANISATIONS`, null],
      },
    ],
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useUpdateOrganisation = (id) => {
  const context = usePatch(`${ORGANISATIONS_BASE}/${id}`, undefined, {
    options: {
      invalidateKeys: [
        {
          key: [[`GET_ORGANISATION_BY_ID`, id], null],
        },
        {
          key: [`GET_ORGANISATIONS`, null],
        },
      ],
    },
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useUpdateOrganisationCustomisation = (id) => {
  return usePatch(`${ORGANISATIONS_BASE}/${id}/customisation`, undefined, {
    options: {
      invalidateKeys: [
        {
          key: [[`GET_ORGANISATION_BY_ID`, id], null],
        },
        {
          key: [`GET_ORGANISATIONS`, null],
        },
      ],
    },
  });
};

const useGetOrganisationsCount = ({ searchValue }) => {
  const context = useFetch(
    `${ORGANISATIONS_BASE}/count`,
    {
      search: searchValue,
    },
    {
      queryKey: `GET_ORGANISATIONS_COUNT`,
    }
  );

  return {
    ...context,
    organisationsCount: context.data,
    error: formatQueryError(context),
  };
};

const useGetOrganisations = (
  { page, limit, statusType, searchValue, sortField, sortOrder },
  options = {}
) => {
  const context = useFetch(
    `${ORGANISATIONS_BASE}`,
    { page, limit, statusType, searchValue, sortField, sortOrder },
    {
      queryKey: `GET_ORGANISATIONS`,
      ...options,
    }
  );

  return {
    ...context,
    organisations: context.data,
    error: formatQueryError(context),
  };
};

const useUpdateOrgAdminToFacilitator = (id) => {
  const context = usePatch(
    `${ORGANISATIONS_BASE}/${id}/is-facilitator`,
    undefined,
    {}
  );
  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useGetOrganisationRecruitmentForm = ({ id, uniqueSlug }, options) => {
  const url = uniqueSlug
    ? `${ORGANISATIONS_BASE}/unique-slug/${uniqueSlug}/recruitment-form`
    : `${ORGANISATIONS_BASE}/${id}/recruitment-form`;

  const context = useFetch(url, undefined, {
    queryKey: `GET_ORGANISATION_RECRUITMENT_FORM`,
    ...options,
  });

  return {
    ...context,
    recruitmentForm: context.data,
    error: formatQueryError(context),
  };
};

const useUpdateRecruitmentForm = (id) => {
  return usePatch(`${ORGANISATIONS_BASE}/${id}/recruitment-form`, undefined, {
    options: {
      invalidateKeys: [
        {
          key: [`GET_ORGANISATION_RECRUITMENT_FORM`],
        },
      ],
    },
  });
};

export {
  useGetOrganisations,
  useAddOrganisation,
  useGetOrganisationByIdOrUniqueSlug,
  useUpdateOrganisation,
  useUpdateOrganisationCustomisation,
  useUpdateOrgAdminToFacilitator,
  useGetOrganisationsCount,
  useGetOrganisationRecruitmentForm,
  useUpdateRecruitmentForm,
};

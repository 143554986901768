import { COACHES_BASE, CUSTOM_TRAINING_BASE } from '../constants';
import {
  useFetch,
  usePost,
  usePatch,
  useDelete,
  formatQueryError,
} from '../utils/queries';

import { useMutation, useQueryClient } from 'react-query';

import axios from 'axios';

const useDeleteCustomTrainingResourceById = ({ id }) => {
  const context = useDelete(
    `${CUSTOM_TRAINING_BASE}/custom-training-resources/${id}`,
    undefined,
    {
      options: {
        invalidateAll: true,
      },
    }
  );
  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useDeleteCustomTrainingStepById = ({ stepId }) => {
  const context = useDelete(
    `${CUSTOM_TRAINING_BASE}/custom-training-steps/${stepId}`,
    undefined,
    {
      options: {
        invalidateAll: true,
      },
    }
  );
  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useGetCustomTrainingStepById = ({ stepId }) => {
  const context = useFetch(
    `${CUSTOM_TRAINING_BASE}/custom-training-steps/${stepId}`,
    undefined,
    {
      queryKey: [`GET_CUSTOM_TRAINING_STEP`, stepId],
    }
  );
  return {
    ...context,
    customTrainingSteps: context.data,
    error: formatQueryError(context),
  };
};

const useGetCustomTrainingResource = ({ id }) => {
  const context = useFetch(
    `${CUSTOM_TRAINING_BASE}/custom-training-resources/${id}`,
    undefined,
    {
      queryKey: [`GET_CUSTOM_TRAINING_RESOURCE`, id],
    }
  );
  return {
    ...context,
    customTrainingResources: context.data,
    error: formatQueryError(context),
  };
};

const useUpdateCustomTrainingResourceById = ({
  id,
  title,
  externalLink,
  order,
  organisationId,
}) => {
  const context = usePatch(
    `${CUSTOM_TRAINING_BASE}/custom-training-resources/${id}`,
    undefined,
    {
      options: {
        queryKey: [`UPDATE_CUSTOM_TRAINING_RESOURCE`, id],
        invalidateKeys: [
          {
            key: [`GET_ALL_CUSTOM_TRAINING_RESOURCES`, organisationId],
          },
          {
            key: [`GET_CUSTOM_TRAINING_RESOURCE`, id],
          },
        ],
      },
    }
  );
  return {
    ...context,
    customTrainingResources: context.data,
    error: formatQueryError(context),
  };
};

const useUpdateCustomTrainingStepById = ({
  stepId,
  title,
  description,
  introduction,
  order,
  videoLink,
  organisationId,
}) => {
  const context = usePatch(
    `${CUSTOM_TRAINING_BASE}/custom-training-steps/${stepId}`,
    undefined,
    {
      options: {
        queryKey: [`UPDATE_CUSTOM_TRAINING_STEP`, stepId],
        invalidateKeys: [
          {
            key: [`GET_ALL_CUSTOM_TRAINING_STEPS`, organisationId],
          },
          {
            key: [`GET_CUSTOM_TRAINING_STEP`, stepId],
          },
        ],
      },
    }
  );
  return {
    ...context,
    customTrainingSteps: context.data,
    error: formatQueryError(context),
  };
};

const useGetDefaultCompletedStep = ({ userId }) => {
  const context = useFetch(`${COACHES_BASE}/${userId}/training-step`, {
    queryKey: [`GET_DEFAULT_COMPLETED_STEP`, userId],
  });
  return {
    ...context,
    defaultCompletedStep: context.data,
    error: formatQueryError(context),
  };
};

const useUpdateTrainingStepUsesMutation = () => {
  const queryClient = useQueryClient();

  const mutations = useMutation(
    (data) => {
      const url = data?.customStepId
        ? `${CUSTOM_TRAINING_BASE}/completed-step/${data.customStepId}`
        : `${COACHES_BASE}/${data.userId}/training-step`;

      return axios.patch(url, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
    {
      // request finished
      onSettled: () => queryClient.invalidateQueries(),
    }
  );

  return mutations;
};

const useGetAllCustomTrainingResources = ({ organisationId }) => {
  const context = useFetch(
    `${CUSTOM_TRAINING_BASE}/custom-training-resources`,
    {
      organisationId,
    },
    {
      queryKey: [`GET_ALL_CUSTOM_TRAINING_RESOURCES`, organisationId],
    }
  );
  return {
    ...context,
    customTrainingResources: context.data,
    error: formatQueryError(context),
  };
};

const useGetAllCustomTrainingSteps = ({ organisationId }) => {
  const context = useFetch(
    `${CUSTOM_TRAINING_BASE}/custom-training-steps`,
    {
      organisationId,
    },
    {
      queryKey: [`GET_ALL_CUSTOM_TRAINING_STEPS`, organisationId],
    }
  );

  return {
    ...context,
    customTrainingSteps: context.data,
    error: formatQueryError(context),
  };
};

const useAddCustomTrainingResource = ({
  title,
  externalLink,
  order,
  organisationId,
}) => {
  const context = usePost(
    `${CUSTOM_TRAINING_BASE}/custom-training-resources`,
    undefined,
    {
      options: {
        queryKey: [`ADD_CUSTOM_TRAINING_RESOURCE`, undefined],
        invalidateKey: [`GET_ALL_CUSTOM_TRAINING_RESOURCES`, organisationId],
      },
    }
  );
  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useAddCustomTrainingStep = ({
  title,
  description,
  introduction,
  order,
  videoLink,
  organisationId,
}) => {
  const context = usePost(
    `${CUSTOM_TRAINING_BASE}/custom-training-steps`,
    undefined,
    {
      options: {
        queryKey: [`ADD_CUSTOM_TRAINING_STEP`, undefined],
        invalidateKey: [`GET_ALL_CUSTOM_TRAINING_STEPS`, organisationId],
      },
    }
  );
  return {
    ...context,
    error: formatQueryError(context),
  };
};

export {
  useAddCustomTrainingStep,
  useGetAllCustomTrainingSteps,
  useGetCustomTrainingStepById,
  useUpdateCustomTrainingStepById,
  useDeleteCustomTrainingStepById,
  useGetAllCustomTrainingResources,
  useGetCustomTrainingResource,
  useAddCustomTrainingResource,
  useUpdateCustomTrainingResourceById,
  useDeleteCustomTrainingResourceById,
  useGetDefaultCompletedStep,
  useUpdateTrainingStepUsesMutation,
};

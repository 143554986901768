import { useReducer } from 'react';

import * as T from '../../../../components/Typography';
import { BasicInput } from '../../../../components/Inputs';

import * as S from './style';
import { Icon } from '../../../../components';
import { string, array, object, number } from 'yup';
import { MediaKeyInput } from '../../../Components';

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }

  return { ...state, ...value };
}

const getInitialState = (data) => ({
  form: data
    ? {
        ...data,
        options: data?.options?.map((e, i) => ({ ...e, id: i })) || [
          { id: 0, option: '' },
        ],
      }
    : {
        options: [{ id: 0, option: '' }],
        correctAnswer: '',
        audioKey: '',
      },
  httpError: '',
  validationErrs: {},
  loading: false,
  submitLoading: false,
});

const DragAndDrop1 = ({ data, onChange, errors }) => {
  const [state, setState] = useReducer(reducer, getInitialState(data));

  const setFormData = (data) =>
    setState((prevState) => {
      const form = { ...prevState.form, ...data };
      onChange({
        ...form,
      });
      return { form };
    });

  const add = () => {
    const maxId = Math.max(...state.form.options.map((e) => e.id), 0);
    setFormData({
      options: [...state.form.options, { id: maxId + 1, option: '' }],
    });
  };
  const remove = (id) => {
    setFormData({
      options: state.form.options.filter((e) => e.id !== id),
    });
  };
  const onOptionChange = (value, id) => {
    setFormData({
      options: state.form.options.map((e) => {
        if (e.id !== id) return e;
        return { ...e, option: value };
      }),
    });
  };

  return (
    <div>
      <MediaKeyInput
        label="Audio key"
        value={state.form.audioKey}
        handleChange={(audioKey) => setFormData({ audioKey })}
        m={{ mt: 5 }}
        error={errors.audioKey}
      />

      <BasicInput
        label="Correct Answer"
        value={state.form.correctAnswer}
        handleChange={(correctAnswer) => setFormData({ correctAnswer })}
        m={{ mt: 5 }}
        error={errors.correctAnswer}
      />

      <T.P mb={2} mt={8} size="large" weight="bold">
        Options
      </T.P>

      {errors?.options && typeof errors.options === 'string' && (
        <T.P mb={2} mt={2} color="error">
          {errors.options}
        </T.P>
      )}
      {state.form.options.map((e, i) => {
        return (
          <>
            <BasicInput
              label={`Option ${i + 1}`}
              value={e.option}
              handleChange={(value) => {
                onOptionChange(value, e.id);
              }}
              m={{ mb: 2, mt: 5 }}
              error={errors?.options && errors?.options[i]?.option}
            />
            {state.form.options.length === 1 && i === 0 ? null : (
              <S.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => remove(e.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </S.Button>
            )}
          </>
        );
      })}

      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={add}
        disabled={state.form.options.find((e) => !e.option)}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add Option
      </S.Button>
    </div>
  );
};

const validationSchema = {
  options: array()
    .of(
      object()
        .shape({
          id: number().notRequired(),
          option: string().required('required field'),
        })
        .required()
    )
    .required()
    .min(1),
  correctAnswer: string().required(),
};
export { validationSchema };

export default DragAndDrop1;

import { useReducer } from 'react';

import * as T from '../../../../components/Typography';
import { BasicInput, Dropdown } from '../../../../components/Inputs';
import * as S from './style';
import { Icon } from '../../../../components';
import { string, number, array, object, boolean } from 'yup';
import { MediaKeyInput } from '../../../Components';

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }

  return { ...state, ...value };
}

const getInitialState = (data) => ({
  form: data
    ? {
        ...data,
        options: data?.options?.map((e, i) => ({ ...e, id: i })) || [
          { id: 0, option: '' },
        ],
        correctOptions: data?.correctOptions?.map((e, i) => ({
          ...e,
          id: i,
        })) || [{ id: 0, option: '' }],
      }
    : {
        options: [{ id: 0, option: '' }],
        audioUrl: '',
        correctOptions: [{ id: 0, option: '' }],
      },
  wordsLength: data?.correctOptions?.[0]?.option?.length || 3,
  httpError: '',
  validationErrs: {},
  loading: false,
  submitLoading: false,
});

const WordBuild = ({ data, onChange, errors }) => {
  const [state, setState] = useReducer(reducer, getInitialState(data));

  const setFormData = (data) =>
    setState((prevState) => {
      const _correctOptions =
        data?.correctOptions || prevState?.form?.correctOptions;
      const form = {
        ...prevState.form,
        ...data,
      };
      onChange({
        ...form,
        options: form?.options?.filter((e) => e.option),
        correctOptions: _correctOptions.map((e) => ({
          ...e,
        })),
        innerError: form.options.every(
          (e) => e.option.length === state.wordsLength
        ),
        disabled: _correctOptions.find(
          (e) => e.option.length !== state.wordsLength
        ),
      });
      return { form };
    });

  const addOption = () => {
    const maxId = Math.max(...state.form.options.map((e) => e.id), 0);
    setFormData({
      options: [...state.form.options, { id: maxId + 1, option: '' }],
    });
  };
  const removeOption = (id) => {
    setFormData({
      options: state.form.options.filter((e) => e.id !== id),
    });
  };

  const addCorrectOptions = () => {
    const maxId = Math.max(...state.form.correctOptions.map((e) => e.id), 0);
    setFormData({
      correctOptions: [
        ...state.form.correctOptions,
        { id: maxId + 1, option: '' },
      ],
    });
  };
  const removeCorrectOptions = (id) => {
    setFormData({
      correctOptions: state.form.correctOptions.filter((e) => e.id !== id),
    });
  };
  const onOptionChange = (value, id) => {
    setFormData({
      options: state.form.options.map((e) => {
        if (e.id !== id) return e;
        return { ...e, option: value };
      }),
    });
  };

  const onCorrectOptionsTextChange = (value, id) => {
    setFormData({
      correctOptions: state.form.correctOptions.map((e) => {
        if (e.id !== id) return e;
        return { ...e, option: value };
      }),
    });
  };

  const onCorrectOptionsAudioKeyChange = (value, id) => {
    setFormData({
      correctOptions: state.form.correctOptions.map((e) => {
        if (e.id !== id) return e;
        return { ...e, audioKey: value };
      }),
    });
  };

  return (
    <div>
      <T.P mt={8} size="large" weight="bold">
        Options
      </T.P>
      {errors?.options && typeof errors.options === 'string' && (
        <T.P mb={2} mt={2} color="error">
          {errors.options}
        </T.P>
      )}

      {state.form.options.map((e, i) => {
        return (
          <>
            <BasicInput
              label="Text"
              value={e.option}
              handleChange={(value) => {
                onOptionChange(value, e.id);
              }}
              m={{ mt: 5 }}
              error={errors?.options && errors?.options[i]?.option}
            />

            {state.form.options.length === 1 && i === 0 ? null : (
              <S.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => removeOption(e.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </S.Button>
            )}
          </>
        );
      })}

      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={addOption}
        disabled={state.form.options.find((e) => !e.option)}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add Option
      </S.Button>

      <T.P mt={8} size="large" weight="bold">
        Correct Answers
      </T.P>
      {errors?.correctOptions && typeof errors.correctOptions === 'string' && (
        <T.P mb={2} mt={2} color="error">
          {errors.correctOptions}
        </T.P>
      )}

      <Dropdown
        label="Select words length"
        options={[
          {
            value: 3,
            label: '3',
          },
          {
            value: 4,
            label: '4',
          },
          {
            value: 5,
            label: '5',
          },
          {
            value: 6,
            label: '6',
          },
        ]}
        selected={state.wordsLength}
        m={{ mb: 2, mt: 5 }}
        helper={'All correct answers / words should have the same length'}
        handleChange={(wordsLength) => setState({ wordsLength })}
      />

      {state.form.correctOptions.map((e, i) => {
        return (
          <>
            <BasicInput
              label={`Correct answer ${i + 1}`}
              value={e.option}
              handleChange={(value) => {
                onCorrectOptionsTextChange(value, e.id);
              }}
              m={{ mb: 2, mt: 8 }}
              error={
                errors?.correctOptions?.[i]?.option ||
                (e.option.length !== state.wordsLength &&
                  `should be ${state.wordsLength} characters`)
              }
            />

            <MediaKeyInput
              label={`Answer ${i + 1} audio file Key`}
              value={e.audioKey}
              handleChange={(value) => {
                onCorrectOptionsAudioKeyChange(value, e.id);
              }}
              m={{ mt: 5 }}
              error={errors?.correctOptions?.[i]?.audioKey}
            />
            {state.form.correctOptions.length === 1 && i === 0 ? null : (
              <S.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => removeCorrectOptions(e.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </S.Button>
            )}
          </>
        );
      })}

      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={addCorrectOptions}
        disabled={state.form.correctOptions.find(
          (e) => !e.option || !e.audioKey
        )}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add answer
      </S.Button>
    </div>
  );
};

const validationSchema = {
  options: array()
    .of(
      object()
        .shape({
          id: number().notRequired(),
          option: string().required('required field'),
        })
        .required()
    )
    .required()
    .min(1),
  correctOptions: array()
    .of(
      object()
        .shape({
          id: number().required(),
          option: string()
            .min(3, 'should be 3 letters length')
            .required('required field'),

          audioKey: string().required('required field'),
        })
        .required()
    )
    .required()
    .min(1),
  innerError: boolean().oneOf([false]),
};
export { validationSchema };

export default WordBuild;

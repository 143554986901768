import { Table } from 'antd';
import { navRoutes } from '../../../constants';
import * as T from '../../../components/Typography';
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    render: (title, row) => {
      return (
        <T.Link to={navRoutes.CMS.VIEW_EXERCISE.replace(':id', row.id)}>
          {title}
        </T.Link>
      );
    },
  },
  {
    title: 'Key',
    dataIndex: 'key',
    key: 'key',
  },
  {
    title: 'Milestone',
    dataIndex: 'milestone',
    key: 'milestone.title',
    render: (milestone) => {
      return <T.Link>{milestone.title}</T.Link>;
    },
  },
];

const TableComponent = ({ loading, data }) => (
  <Table columns={columns} dataSource={data} loading={loading} />
);

export default TableComponent;

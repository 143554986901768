import { useState } from 'react';
import {
  Grid,
  Typography as T,
  Button,
  Loading,
  StatsChart,
} from '../../../components';
import * as S from './style';
import { useGetDefaultFacilitator } from '../../../api-calls/facilitator.queries';
import { getMediaUrl } from '../../../helpers';
import { useGeneralState } from '../../../context/general-state';
import BadgesList from '../../../components/BadgesList';

import { useGetLearnerStats } from '../../../api-calls/learners.queries';

import { navRoutes as R, audio } from '../../../constants';
import { useAuth } from '../../../context/auth';
import { Badges } from '../../../api-calls';
import { BackButton } from 'components/BackButton';
import { LEARNER } from 'constants/nav-routes';
import { Row } from 'components/Grid';
import { Divider } from 'components/Divider';

const { Col } = Grid;

const Profile = () => {
  const { state: generalState } = useGeneralState();
  const [isLarge, setIsLarge] = useState(() => {
    return localStorage.getItem('isLarge') || false;
  });
  const { user } = useAuth();
  const {
    facilitator,
    error: getFacilitatorError,
    isLoading: getFacilitatorLoading,
  } = useGetDefaultFacilitator();
  const { stats, isLoading, isError, isSuccess, error } = useGetLearnerStats({
    learnerId: user.id,
  });

  const { data: badges, isFetched: badgesLoaded } = Badges.useGetBadges({
    type: 'all',
  });

  if (isLoading) return <Loading />;

  return (
    <S.Wrapper>
      <BackButton link={LEARNER.DASHBOARD} />
      <S.PlayButtonWrapper mt="7">
        <Button.PlayButton
          width="56px"
          height="56px"
          audioUrl={getMediaUrl(
            audio.profile,
            true,
            generalState?.preferredVoice
          )}
          iconProps={{ width: 27.2 }}
          mr={3}
          fallbackText="Profile"
        />
        <T.H1 ml="1">Profile</T.H1>
      </S.PlayButtonWrapper>
      <S.Content>
        <S.PlayButtonWrapper mt="7">
          <Button.PlayButton
            width="56px"
            height="56px"
            audioUrl={getMediaUrl(null, true, generalState?.preferredVoice)} // keep this invalid audio url as the content should be dynamic based on id
            iconProps={{ width: 27.2 }}
            mr={3}
            fallbackText={`Learner ID: ${user?.learnerUniqueId
              ?.split('')
              .join(' ')}`}
          />
          <T.H2 mr="1">
            Learner ID: <S.LearnerId>{user?.learnerUniqueId}</S.LearnerId>
          </T.H2>
        </S.PlayButtonWrapper>
        <Row mt="5" gap="24px">
          <Button.BasicButton
            handleClick={() => {}}
            to={R.LEARNER.SOUND_BOARD}
            variant="primary"
            ta="center"
            showSound
            audioUrl={getMediaUrl(
              audio.listenToSoundBoard,
              true,
              generalState?.preferredVoice
            )}
            fallbackText="Listen to sound board"
          >
            <T.P color="white" weight="bold">
              Listen to sound board
            </T.P>
          </Button.BasicButton>
          <Button.BasicButton
            handleClick={() => {}}
            to={R.LEARNER.ICONS}
            variant="secondary"
            ta="center"
            showSound
            audioUrl={getMediaUrl(
              audio.seeListenToAppIcons,
              true,
              generalState?.preferredVoice
            )}
            fallbackText="See/listen to app icons"
          >
            <T.P color="white" weight="bold">
              See/listen to app icons
            </T.P>
          </Button.BasicButton>
        </Row>
        <Divider />
        {/* Statistics */}
        <S.PlayButtonWrapper mb="5">
          <Button.PlayButton
            width="56px"
            height="56px"
            audioUrl={getMediaUrl(
              audio.statistics,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: 27.2 }}
            mr={3}
            fallbackText="Statistics"
          />

          <Col w={[3, 7, 7]}>
            <T.H2>Statistics</T.H2>
          </Col>
        </S.PlayButtonWrapper>
        {isSuccess ? (
          <S.StatsWrapper>
            <StatsChart
              backgroundMode={'progress'}
              statChartType="progress"
              value={stats.completedExercisesPerc}
            />
            <StatsChart
              backgroundMode={'dark'}
              statChartType="milestone"
              value={stats.milestoneReached}
            />
            <StatsChart
              backgroundMode={'light'}
              statChartType="learningTime"
              value={stats.totalSessionTimesTotal}
              selfLearningPercentage={stats.selfLearningPercentage}
            />
          </S.StatsWrapper>
        ) : isError ? (
          <T.P mb="2" color="error">
            {error.message}
          </T.P>
        ) : null}
        <Divider />
        <S.PlayButtonWrapper mb="5">
          <Button.PlayButton
            width="56px"
            height="56px"
            audioUrl={getMediaUrl(
              audio.accessibility,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: 27.2 }}
            mr={3}
            fallbackText="Accessibility"
          />

          <Col w={[3, 7, 7]} mlM="3">
            <T.H2>Accessibility</T.H2>
          </Col>
        </S.PlayButtonWrapper>
        <Row mt="5" gap="24px">
          <Button.BasicButton
            handleClick={() => {}}
            to={R.LEARNER.COLOURED_OVERLAY}
            variant="tertiary"
            iconMR="4px"
            ta="center"
            showSound
            audioUrl={getMediaUrl(
              audio.addAColouredOverlay,
              true,
              generalState?.preferredVoice
            )}
            fallbackText="Add a coloured overlay"
          >
            <T.P color="neutralMain" weight="bold">
              Add a coloured overlay
            </T.P>
          </Button.BasicButton>
          <Button.BasicButton
            handleClick={() => {
              if (!isLarge) {
                document.getElementsByTagName('html')[0].style.fontSize =
                  '19px';
                localStorage.setItem('isLarge', 'true');
                setIsLarge(true);
              } else {
                document.getElementsByTagName('html')[0].style.fontSize =
                  '16px';
                localStorage.removeItem('isLarge');
                setIsLarge(false);
              }
            }}
            variant="primary"
            ta="center"
            icon="lens"
            iconMR="4px"
            showSound
            audioUrl={getMediaUrl(
              isLarge ? audio.decreaseFontSize : audio.increaseFontSize,
              true,
              generalState?.preferredVoice
            )}
            fallbackText={isLarge ? 'Decrease text size' : 'Increase text size'}
          >
            <T.P color="white" weight="bold">
              {!isLarge ? '+ Increase text size' : '- Decrease text size'}
            </T.P>
          </Button.BasicButton>
        </Row>

        {badgesLoaded && badges?.length > 0 && (
          <>
            <Divider />
            {/* badges */}
            <S.PlayButtonWrapper mb="5">
              <Button.PlayButton
                width="56px"
                height="56px"
                audioUrl={getMediaUrl(
                  audio.badges,
                  true,
                  generalState?.preferredVoice
                )}
                iconProps={{ width: 27.2 }}
                mr={3}
                fallbackText="Badges"
              />
              <Col w={[3, 7, 7]} mlM="3">
                <T.H2>Badges</T.H2>
              </Col>
            </S.PlayButtonWrapper>
            <BadgesList badges={badges} />
          </>
        )}
        <Divider />
        <S.PlayButtonWrapper mb="5">
          <Button.PlayButton
            width="56px"
            height="56px"
            audioUrl={getMediaUrl(
              audio.requestToDeleteTheAccount,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: 27.2 }}
            mr={3}
            fallbackText="Request to delete the account"
          />

          <Col w={[3, 7, 7]} mlM="3">
            <T.H2>Request to delete the account</T.H2>
          </Col>
        </S.PlayButtonWrapper>
        <Button.BasicButton
          id="phone-button"
          to={`tel:${facilitator?.phoneNumber}`}
          variant="tertiary"
          external
          disabled={getFacilitatorLoading || getFacilitatorError}
          showSound
          audioUrl={getMediaUrl(
            audio.byPhoneCall,
            true,
            generalState?.preferredVoice
          )}
          maxWidth="325px"
          fallbackText="By phone call"
        >
          By phone call
        </Button.BasicButton>
        {getFacilitatorError ? (
          <T.P mb="2" color="error" mt="4">
            {getFacilitatorError?.message}
          </T.P>
        ) : null}
      </S.Content>
    </S.Wrapper>
  );
};

export default Profile;

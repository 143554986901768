import { useReducer } from 'react';
import { string, array, object, number } from 'yup';
import { Icon } from '../../../../components';

import { BasicInput } from '../../../../components/Inputs';
import { MediaKeyInput } from '../../../Components';
import * as T from '../../../../components/Typography';
import MDEditor from '../../../../components/MDEditor';
import * as S from './style';

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }

  return { ...state, ...value };
}

const getInitialState = (data) => {
  return {
    form: data
      ? data
      : {
          audioKey: '',
          title: '',
          textAudio: '',
          textAudioKey: '',
          multipleWords: [{ id: 0, option: '', audioKey: '' }],
        },
    httpError: '',
    validationErrs: {},
    loading: false,
    submitLoading: false,
  };
};

const ListenAndRepeatMultiple = ({ data, onChange, errors }) => {
  const initialState = getInitialState(data);
  const [state, setState] = useReducer(reducer, initialState);

  const setFormData = (data) =>
    setState((prevState) => {
      const form = { ...prevState.form, ...data };
      onChange(form);
      return { form };
    });

  const addWord = () => {
    const maxId = Math.max(...state.form.multipleWords.map((e) => e.id), 0);
    setFormData({
      multipleWords: [
        ...state.form.multipleWords,
        { id: maxId + 1, option: '', hide: false },
      ],
    });
  };
  const removeWord = (id) => {
    setFormData({
      multipleWords: state.form.multipleWords.filter((e) => e.id !== id),
    });
  };

  const onOptionChange = (value, id) => {
    setFormData({
      multipleWords: state.form.multipleWords.map((e) => {
        if (e.id !== id) return e;
        return { ...e, option: value };
      }),
    });
  };
  const onAudioKeyChange = (value, id) => {
    setFormData({
      multipleWords: state.form.multipleWords.map((e) => {
        if (e.id !== id) return e;
        return { ...e, audioKey: value };
      }),
    });
  };

  return (
    <div>
      <BasicInput
        value={state.form.textAudio}
        handleChange={(textAudio) => setFormData({ textAudio })}
        label="Explainer Text"
        error={errors.textAudio}
        m={{ mt: 8 }}
      />

      <MediaKeyInput
        label="Explainer Audio"
        value={state.form.textAudioKey}
        handleChange={(textAudioKey) => setFormData({ textAudioKey })}
        m={{ mt: 5 }}
        error={errors.textAudioKey}
      />

      <MDEditor
        label={'Title'}
        value={state.form.title}
        onChange={(title) => setFormData({ title })}
        mode={'edit'}
        height={80}
        m={{ mt: 8 }}
        error={errors?.title}
      />

      <T.P mt={8} size="large" weight="bold">
        Words
      </T.P>
      {errors?.multipleWords && typeof errors.multipleWords === 'string' && (
        <T.P mb={2} mt={2} color="error">
          {errors.multipleWords}
        </T.P>
      )}

      {state.form.multipleWords.map((e, i) => {
        return (
          <>
            <MDEditor
              label={`Word ${i + 1}`}
              value={e.option}
              onChange={(value) => {
                onOptionChange(value, e.id);
              }}
              mode={'edit'}
              height={80}
              m={{ mt: 5 }}
              error={errors?.multipleWords?.[i]?.option}
            />

            <MediaKeyInput
              label={`Word ${i + 1} Audio Key`}
              value={e.audioKey}
              handleChange={(value) => {
                onAudioKeyChange(value, e.id);
              }}
              m={{ mt: 5 }}
              error={errors?.multipleWords?.[i]?.audioKey}
            />

            {state.form.multipleWords.length === 1 && i === 0 ? null : (
              <S.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => removeWord(e.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </S.Button>
            )}
          </>
        );
      })}
      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={addWord}
        disabled={state.form.multipleWords.find((e) => !e.option)}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add Word
      </S.Button>

      <T.P color="error" mt={6}>
        {errors.general}
      </T.P>
    </div>
  );
};

const validationSchema = {
  title: string().max(15).notRequired(),
  textAudio: string().notRequired(),
  textAudioKey: string().notRequired(),
  multipleWords: array()
    .of(
      object()
        .shape({
          id: number().required(),
          option: string().required('required field'),
          audioKey: string().required('required field'),
        })
        .required()
    )
    .required('you must provide words')
    .min(1, 'you must provide words'),
  general: string().test(
    'titleOrText',
    'You should provide either title or explainer text',
    (val, { parent }) => {
      const { title, textAudio } = parent;
      return title || textAudio || false;
    }
  ),
};

export { validationSchema };
export default ListenAndRepeatMultiple;

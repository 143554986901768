import styled from '@emotion/styled';
import setMargin from './../../helpers/set-margin';

export const Wrapper = styled.div`
  ${setMargin};
  background: ${({ theme }) => theme.colors.neutralSurface};
  border-radius: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  border-radius: 8px;
  overflow: hidden;

  p,
  div {
    word-wrap: break-word !important;
    overflow-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
  }
`;
export const InlineWrapper = styled.div`
  display: flex;
  width: 100%;

  p,
  div {
    word-wrap: break-word !important;
    overflow-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
  }
`;

export const CopyBtn = styled.button`
  ${setMargin};
  all: unset;
  background-color: ${({ theme }) => theme.colors.neutralMain};
  cursor: pointer;
  max-width: 97px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 16px;
`;

export const InlineCopyBtn = styled.button`
  ${setMargin};
  border: none;
  background: none;
  cursor: pointer;
  min-width: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

import React, { useMemo } from 'react';
import * as S from './style';
import * as T from 'components/Typography';
import { BasicButton } from 'components/Button';
import Image from 'components/Image';
import { Divider } from 'components/Divider';
import { InfoCard, Loading, StatsChart } from 'components';
import {
  useGetLearnerProfile,
  useGetLearnerStuckAt,
} from 'api-calls/learners.queries';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback } from 'react';
import { navRoutes, userRoles } from '../../../../constants';
import LearnerStatus from './LearnerStatus';
import DeleteAccount from './DeleteAccount';
import ProgressReports from './ProgressReports';
import { useAuth } from 'context/auth';
import { Col, Row } from 'components/Grid';
import { InfoCardWithButton } from 'components/InfoCard';
import Badges from './Badges';
import StartCoachingButton from 'components/StartCoachingButton';
import { useOrganisationDetails } from 'context/organisation-details';
import LearnersNotes from '../LearnersNotes';

const NewLearnerProfile = () => {
  const { user } = useAuth();
  const { userId } = useParams();
  const navigate = useNavigate();
  const { learnerProfile, isLoading } = useGetLearnerProfile({
    learnerUserId: userId,
  });
  const dashboardLink = navRoutes[user?.role].DASHBOARD;
  const { stuckAt } = useGetLearnerStuckAt({ learnerUserId: userId });
  const { organisationDetails } = useOrganisationDetails();

  const isCoach = user?.role === userRoles.COACH;
  const isAdmin = user?.role === userRoles.ADMIN;

  const showBadges = [
    userRoles.COACH,
    userRoles.LEARNER,
    userRoles.FACILITATOR,
    userRoles.ORGANISATION_ADMIN,
    userRoles.ADMIN,
  ].includes(user?.role);

  const learner = useMemo(() => learnerProfile?.learner, [learnerProfile]);
  const coach = useMemo(() => learnerProfile?.coach, [learnerProfile]);
  const stats = useMemo(() => learnerProfile?.stats, [learnerProfile]);
  const latestProgressReport = useMemo(
    () => learnerProfile?.latestProgressReport,
    [learnerProfile]
  );

  const currentMilestone =
    stats?.milestonesReached?.find((m) => m.status === 'current') ||
    stats?.milestonesReached?.find((m) => m.id === 1);

  const nextMilestone =
    stats?.milestonesReached?.[
      stats?.milestonesReached?.findIndex((m) => m.id === currentMilestone.id) +
        1
    ];

  const reassignCoach = useCallback(() => {
    navigate(navRoutes.COMMON.ASSIGN_COACH.replace(':id', userId));
  }, [navigate, userId]);

  const viewDetails = useCallback(() => {
    navigate(navRoutes.COMMON.LEARNER_REFERRAL.replace(':id', userId));
  }, [navigate, userId]);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  if (isLoading) return <Loading />;

  return (
    <S.PageWrapper>
      <S.CoverWrapper>
        <S.CoverBackButtonWrapper>
          <BasicButton
            handleClick={goBack}
            variant="tertiary"
            icon={'leftChevron'}
          >
            Back
          </BasicButton>
        </S.CoverBackButtonWrapper>
        <S.CoverBackgroundWrapper>
          <Image image="profileCover" width="100%" height="100%" />
        </S.CoverBackgroundWrapper>
      </S.CoverWrapper>
      <S.Content>
        <S.DetailsWrapper>
          <S.AvatarNameWrapper>
            <S.AvatarWrapper>
              <T.P size="small" color="white">
                ID:
              </T.P>
              <T.P size="med" color="white">
                {learner?.learnerUniqueId}
              </T.P>
            </S.AvatarWrapper>
            <S.NameWrapper>
              <S.NameStuckLabelWrapper>
                <T.H1>{learner?.fullName}</T.H1>
                {stuckAt?.length ? (
                  <S.Stucklabel>Stuck learner</S.Stucklabel>
                ) : null}
              </S.NameStuckLabelWrapper>
              <T.P size="med">Coach: {coach?.fullName || 'N/A'}</T.P>
            </S.NameWrapper>
          </S.AvatarNameWrapper>
          {isCoach && (
            <StartCoachingButton
              id={userId}
              user={user}
              learner={learner}
              learnerUniqueId={learner.learnerUniqueId}
              margin={'32px'}
            />
          )}
          {!isAdmin && !isCoach && (
            <BasicButton
              handleClick={reassignCoach}
              maxWidth="220px"
              variant="tertiary"
              icon={'assign'}
            >
              {coach?.id ? 'Reassign coach' : 'Assign coach'}
            </BasicButton>
          )}
        </S.DetailsWrapper>
        <Divider my="6" />

        <>
          <T.H1 size="med" mb="5">
            {learner?.firstName}’s status
          </T.H1>
          <Row inner>
            <Col w={[4, 6, 6]}>
              <InfoCard
                firstColumnLabel="Current milestone"
                firstColumnValue={currentMilestone?.title}
                buttonText={isCoach && 'Prepare'}
                to={
                  isCoach &&
                  navRoutes.COACH.PREPARE_MILESTONE.replace(
                    ':milestoneId',
                    currentMilestone?.id
                  )
                }
                noIcon={!isCoach}
              />
            </Col>
            <Col w={[4, 6, 6]}>
              <InfoCard
                firstColumnLabel="Next milestone"
                firstColumnValue={nextMilestone?.title}
                buttonText={isCoach && 'Prepare'}
                to={
                  isCoach &&
                  navRoutes.COACH.PREPARE_MILESTONE.replace(
                    ':milestoneId',
                    nextMilestone?.id
                  )
                }
                noIcon={!isCoach}
              />
            </Col>
          </Row>
          <Divider my="6" />
          <T.H1 size="med" mb="5">
            Exercises {learner.firstName} is finding difficult
          </T.H1>
          {stuckAt?.length ? (
            <Row gap="24px 0" inner>
              {stuckAt.map((learner, i) => {
                return (
                  <Col w={[4, 6, 6]}>
                    <InfoCardWithButton
                      adminPreview={!isCoach}
                      firstColumnLabel="Stuck on"
                      firstColumnValue={
                        learner?.stepData?.title?.toUpperCase() +
                        ', ' +
                        learner.title
                      }
                      handleClick={async () => {
                        navigate(
                          navRoutes.COACH.REVIEW_STUCK_AT.replace(
                            ':exerciseId',
                            learner.exerciseId
                          ).replace(':stepId', learner.stepId),
                          {
                            state: {
                              type: learner.stepType,
                              to: navRoutes.COMMON.LEARNER.replace(
                                ':userId',
                                userId || user.selectedLearnerId
                              ),
                            },
                          }
                        );
                      }}
                    />
                  </Col>
                );
              })}
            </Row>
          ) : (
            <InfoCardWithButton />
          )}
          <Divider my="6" />
        </>

        <T.H1 mb="5">{learner?.firstName}’s stats</T.H1>
        <S.StatsWrapper>
          <S.StatsColWrapper>
            <StatsChart
              backgroundMode="progress"
              statChartType="progress"
              value={stats?.completedExercisesPerc}
            />
            <StatsChart
              backgroundMode="quaternary"
              statChartType="completionTaskLastDate"
              value={stats?.dateOfLastCoachingSession}
            />
          </S.StatsColWrapper>
          <StatsChart
            backgroundMode="light"
            statChartType="learningTime"
            selfLearningPercentage={stats?.selfLearningPercentage}
            value={stats?.totalSessionTimesTotal}
          />
          <S.StatsColWrapper>
            <StatsChart
              backgroundMode="light"
              statChartType="sessionDate"
              value={stats?.dateOfLastCoachingSession}
            />
            <StatsChart
              backgroundMode="dark"
              statChartType="milestone"
              value={stats?.milestoneReached}
              actionLink={navRoutes.COMMON.LEARNER_REACHED_MILESTONES.replace(
                ':learnerId',
                userId
              )}
            />
          </S.StatsColWrapper>
        </S.StatsWrapper>
        <Divider my="6" />
        <ProgressReports
          stats={stats}
          latestProgressReport={latestProgressReport}
        />
        <Divider my="6" />
        {!showBadges ? null : (
          <>
            <Badges />
            <Divider my="6" />
          </>
        )}
        {!isCoach && (
          <>
            <T.H2 size="med" mb="2">
              Details
            </T.H2>
            <T.P mb="6">
              Here you can view and update all relevant details for the learner.
            </T.P>
            <BasicButton
              handleClick={viewDetails}
              variant="tertiary"
              maxWidth="255px"
            >
              View details
            </BasicButton>
            <Divider my="6" />
          </>
        )}
        <LearnerStatus learner={learner} />
        <Divider my="6" />
        {!isCoach ? null : (
          <>
            <S.HandOverCard>
              <div>
                <T.H2 size="med" mb="2">
                  I need to handover my learner
                </T.H2>
                <T.P>
                  To arrange the handover your learner you need to email your
                  organisation
                </T.P>
              </div>
              <BasicButton
                maxWidth="230px"
                mtM="4"
                external
                to={`mailto:${organisationDetails?.email}?subject=Handover%20my%20learner%20${learner?.fullName}&body=Hi%20${organisationDetails?.name}%2C%0D%0A%0D%0AI%20would%20like%20to%20handover%20my%20learner%20${learner?.fullName}%20to%20another%20coach.%0D%0A%0D%0AKind%20regards%2C%0D%0A${user?.firstName}%20${user?.lastName}%0D%0A${user?.email}`}
              >
                Email organisation
              </BasicButton>
            </S.HandOverCard>
            <Divider my="6" />
          </>
        )}
        <LearnersNotes learnerId={userId} />
        <Divider my="6" />
        {!isCoach && <DeleteAccount />}
        <BasicButton
          to={dashboardLink}
          icon={'back'}
          variant="secondary"
          maxWidth="225px"
        >
          Back to dashboard
        </BasicButton>
      </S.Content>
    </S.PageWrapper>
  );
};

export default NewLearnerProfile;

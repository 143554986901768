import { useNavigate } from 'react-router-dom';
import { navRoutes, userRoles } from '../../constants';
import TextWithIcon from '../TextWithIcon';

import * as S from './style';
import { Typography as T, Button, Icon } from '../';
import { navRoutes as R, audio } from '../../constants';
import { getMediaUrl } from '../../helpers';
import { useGeneralState } from '../../context/general-state';
import theme from 'theme';

const ProgressCheck = ({
  userRole,
  code,
  milestoneId,
  progressCheckFilled,
  hideEndPath,
  isMobile,
  pathIcon,
  ...props
}) => {
  const navigate = useNavigate();
  const { state: generalState } = useGeneralState();

  const enabled = code || generalState?.isPlayground;
  return (
    <S.Wrapper {...props}>
      <S.CardWrapper>
        <S.BellIconWrapper>
          <Icon icon="bell" color="white" />
        </S.BellIconWrapper>
        {progressCheckFilled ? (
          <S.CompletedWrapper>
            <TextWithIcon
              text="Progress check"
              icon="tick"
              size="large"
              weight="bold"
              color="primaryMain"
              isText={true}
              iconProps={{
                color: 'primaryMain',
                width: '24.23px',
                height: '21px',
              }}
            />
          </S.CompletedWrapper>
        ) : (
          <S.ButtonsWrapper>
            <Button.PlayButton
              audioUrl={getMediaUrl(
                !enabled ? audio.exerciseLocked : audio.progressCheck,
                true,
                generalState?.preferredVoice
              )}
              iconProps={{ width: '25px' }}
              icon={!enabled ? 'lock' : 'speaker'}
              disabled={!enabled}
              backgroundColor={theme.colors.neutralSurface}
              fallbackText="Progress check"
            />
            <S.PlayButtonWrapper disabled={!enabled}>
              <T.H1 size="med" color="primaryMain">
                Progress check
              </T.H1>
            </S.PlayButtonWrapper>
          </S.ButtonsWrapper>
        )}
        <T.P my="3">
          Track your session achievements and identify areas for improvement.
        </T.P>
        <Button.BasicButton
          variant="primary"
          showSound
          width="100%"
          audioUrl={getMediaUrl(
            !enabled ? audio.exerciseLocked : audio.progressCheck,
            true,
            generalState?.preferredVoice
          )}
          disabled={!enabled || progressCheckFilled}
          handleClick={() =>
            userRole === userRoles.LEARNER && !generalState?.isPlayground
              ? navigate(R.LEARNER.COACH_CODE, { state: { milestoneId, code } })
              : navigate(
                  navRoutes.LEARNER.PROGRESS_PROFILE_QUESTIONS.replace(
                    ':id',
                    1
                  ).replace(':milestoneId', milestoneId)
                )
          }
          fallbackText="Progress check"
        >
          Progress check
        </Button.BasicButton>
      </S.CardWrapper>
    </S.Wrapper>
  );
};
export default ProgressCheck;

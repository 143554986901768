import { useState, useRef, useEffect } from 'react';
import { addCoaches as validate } from '../../../validation/schemas';
import { useAddCoachesInvites } from '../../../api-calls/coaches.queries';

const handleAddresses = (str) => {
  return str.split(',').map((item) => item.trim());
};

const useInviteCoaches = ({ inviteType }) => {
  const [coachesInvites, setCoachesInvites] = useState({
    successInvites: [],
    failedInvites: [],
  });
  const [addresses, setAddresses] = useState('');
  const [visible, setVisible] = useState(false);
  const submitAttempt = useRef(false);
  const [validationErr, setValidationErr] = useState('');
  const {
    mutateAsync: addCoaches,
    error: httpError,
    isLoading,
    isError,
  } = useAddCoachesInvites();

  const arrayOfAddresses = handleAddresses(addresses);

  const validateForm = () => {
    try {
      validate({
        arrayOfAddresses,
      });
      setValidationErr('');
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setValidationErr('The list has invalid email(s)');
      }
      return false;
    }
  };

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addresses]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateForm();
    if (isValid) {
      const { data } = await addCoaches({
        emailsList: arrayOfAddresses,
        inviteType,
      });
      setCoachesInvites(data);
      setVisible(true);
    }
  };

  const reset = () => {
    setAddresses('');
    setValidationErr('');
    submitAttempt.current = false;
  };

  return {
    addresses,
    setAddresses,
    visible,
    coachesInvites,
    setVisible,
    handleSubmit,
    validationErr,
    httpError,
    isError,
    isLoading,
    reset,
  };
};

export default useInviteCoaches;

import { FEEDBACK_BASE } from '../constants';
import { usePost, formatQueryError, useFetch } from '../utils/queries';

function useCreateFeedback() {
  const context = usePost(`${FEEDBACK_BASE}`);
  return {
    ...context,
    error: formatQueryError(context),
  };
}

function useGetFeedbacks({ targetRole }) {
  const context = useFetch(`${FEEDBACK_BASE}`, {
    targetRole,
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
}

export { useCreateFeedback, useGetFeedbacks };

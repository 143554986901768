import Markdown from '../../../../components/Markdown';
import * as S from './style';
import { UseAudio } from 'Hooks';

const OptionButton = ({
  index,
  option,
  isCorrect,
  handleClick,
  selected,
  imageUrl,
  audioUrl,
  grid,
  bubble,
  mb,
  variant,
  find,
}) => {
  const { isLoading, fallbackDisabled, handleAudioClick } = UseAudio({
    audioUrl,
    handleClick: () => handleClick({ option, id: index, isCorrect }),
  });

  const audioDisabled = isLoading || fallbackDisabled;

  return (
    <S.Option
      grid={grid}
      onClick={handleAudioClick}
      index={index}
      selected={selected.option + selected.id}
      option={option + index}
      imageUrl={imageUrl}
      mb={mb}
      variant={variant}
      find={find}
      leftPadding={/\s/.test(option)}
      disabled={audioUrl && audioDisabled}
    >
      {imageUrl ? (
        <img
          src={imageUrl}
          alt={option}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      ) : (
        <Markdown
          text={option}
          customStyles={{
            p: {
              size: bubble ? 'medLarge' : 'med',
              ta: /\s/.test(option) ? 'left' : 'center',
              color:
                selected?.option && selected.option === option
                  ? 'neutralSurface'
                  : '',
            },
          }}
        />
      )}
    </S.Option>
  );
};

export default OptionButton;

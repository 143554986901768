import { useReducer } from 'react';
import { string } from 'yup';

import MDEditor from '../../../../components/MDEditor';
import { MediaKeyInput } from '../../../Components';
import { BasicInput } from '../../../../components/Inputs';

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }

  return { ...state, ...value };
}

const getInitialState = (data) => ({
  form: data || {
    text: '',
    textAudioKey: '',
    textAudio: '',
  },
  httpError: '',
  validationErrs: {},
  loading: false,
  submitLoading: false,
});

const TextFinder = ({ data, onChange, errors }) => {
  const [state, setState] = useReducer(reducer, getInitialState(data));

  const setFormData = (data) =>
    setState((prevState) => {
      const form = { ...prevState.form, ...data };
      onChange(form);
      return { form };
    });

  return (
    <div>
      <BasicInput
        label="Explainer Text"
        value={state.form.textAudio}
        handleChange={(textAudio) => setFormData({ textAudio })}
        m={{ mt: 8 }}
      />
      <MediaKeyInput
        label={`Explainer Text file Key`}
        value={state.form.textAudioKey}
        handleChange={(textAudioKey) => {
          setFormData({ textAudioKey });
        }}
        m={{ mt: 5 }}
      />

      <MDEditor
        value={state.form.text}
        onChange={(text) => setFormData({ text })}
        mode={'edit'}
        label="Text"
        helper='put "%" around the letter/word to make it selectable'
        error={errors.text}
        m={{ mt: 8 }}
      />
    </div>
  );
};

const validationSchema = {
  text: string().required('required field'),
  textAudioKey: string().notRequired(),
  textAudio: string().notRequired(),
};

export { validationSchema };
export default TextFinder;

import { BasicButton } from 'components/Button';
import React, { useState } from 'react';
import { Textarea } from 'components/Inputs';
import { useParams } from 'react-router-dom';
import validate from '../../../../validation/schemas/add-coach-note';
import * as T from 'components/Typography';
import { useAuth } from 'context/auth';
import { useAddLearnerNote } from 'api-calls/learners.queries';

const AddNote = ({ learnerId }) => {
  const { coachId } = useParams();
  const [showNoteInput, setShowNoteInput] = useState(false);
  const [note, setNote] = useState('');
  const [noteValidationError, setNoteValidationError] = useState(null);
  const { user } = useAuth();
  const isAdmin = user?.role === 'ADMIN';

  const {
    mutate: addNote,
    error: addNoteHttpError,
    isLoading,
  } = useAddLearnerNote({
    learnerId,
    note,
  });

  const validateNote = () => {
    try {
      validate({ note });
      setNoteValidationError(false);

      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setNoteValidationError(error?.inner?.note);
      }
      return false;
    }
  };

  const handleNoteSubmission = async (e) => {
    e.preventDefault();
    const isValid = validateNote();
    if (isValid) {
      addNote(
        { coachId, note },
        {
          onSuccess: () => {
            setNote('');
            setShowNoteInput(false);
            setNoteValidationError(null);
          },
        }
      );
    }
  };

  return (
    <>
      {showNoteInput ? (
        <Textarea
          label={`Add note`}
          value={note}
          handleChange={(note) => setNote(note)}
          m={{ mt: 5 }}
          error={noteValidationError}
        />
      ) : null}

      {!isAdmin ? (
        <BasicButton
          variant="primary"
          type="submit"
          mt="5"
          style={{ width: 'auto' }}
          onClick={(e) => {
            if (!showNoteInput) {
              setShowNoteInput(true);
            } else {
              handleNoteSubmission(e);
            }
          }}
          loading={isLoading}
          maxWidth="190px"
          icon={'plus'}
        >
          Add note
        </BasicButton>
      ) : null}
      {addNoteHttpError ? (
        <T.P color="error">{addNoteHttpError?.message}</T.P>
      ) : null}
    </>
  );
};

export default AddNote;

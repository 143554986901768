import React from 'react';
import styled from '@emotion/styled';
import setMargin from '../../helpers/set-margin';

import SecondaryColor from '../assets/SecondaryColor.png';
import TertiaryColor from '../assets/TertiaryColor.png';
import ExerciseCompletedBackground from '../assets/ExerciseCompletedBackground.png';
import Question from '../assets/Question.svg';
import Quest from '../assets/Quest.jpg';
import Book1 from '../assets/book1.png';
import Book2 from '../assets/book2.png';
import Notepad from '../assets/notepad.png';
import BookOnWindow from '../assets/book-on-window.jpg';
import BookOnWindow2 from '../assets/book-on-window-2.png';
import GoldCup from '../assets/GoldCup.png';
import Glasses from '../assets/glasses.jpg';
import Books from '../assets/books.png';
import CompletedManual from '../assets/completed-manual.png';
import joinCommunityAndWebinars from '../assets/join-community-and-webinars.png';
import Congrats from '../assets/Congrats.png';
import CoachWelcomeImage from '../assets/CoachWelcomeImage.png';
import profileCover from '../assets/profileCover.png';
import megaphone from '../assets/Megaphone.png';

export const imgMap = {
  secondaryColor: SecondaryColor,
  tertiaryColor: TertiaryColor,
  exerciseCompletedBackground: ExerciseCompletedBackground,
  question: Question,
  quest: Quest,
  book1: Book1,
  book2: Book2,
  notepad: Notepad,
  bookOnWindow: BookOnWindow,
  bookOnWindow2: BookOnWindow2,
  goldCup: GoldCup,
  glasses: Glasses,
  books: Books,
  completedManual: CompletedManual,
  joinCommunityAndWebinars,
  Congrats,
  CoachWelcomeImage,
  profileCover,
  megaphone,
};

const StyledImage = styled.img`
  max-width: 100% !important;
  width: ${({ width }) => (width ? `${width}` : 'auto')};
  height: ${({ height }) => (height ? `${height}px` : '100% !important')};
  ${setMargin}
`;

const Image = ({
  image,
  width = '100%',
  height,
  margin,
  alt = 'Illustration',
  customStyle,
  ...props
}) => {
  if (!imgMap[image]) {
    // eslint-disable-next-line no-console
    console.warn(`<Image /> called with invalid image prop "${image}"`);
    return null;
  }

  return (
    <StyledImage
      src={imgMap[image]}
      alt={alt}
      width={width}
      height={height}
      margin={margin}
      style={{ ...customStyle }}
      {...props}
    />
  );
};

export default Image;

import { useCallback, useEffect, useState } from 'react';
import { Button, OnboardingModal, Typography as T } from 'components';
import {
  navRoutes as NR,
  coachStatusesQuery,
  navRoutes,
} from '../../../constants';
import { ORG_STEPS } from './data';
import { useGetOrgProgress } from 'Hooks';
import QuickAccessCard from 'components/QuickAccessCard';
import Stack from '@mui/material/Stack';
import * as S from './style';
import StatsChart from 'components/StatsChart';
import { useGetStats } from 'api-calls/stats.queries';
import theme from 'theme';
import { getGreetings } from 'helpers';
import { useAuth } from 'context/auth';
import TrainingBanner from 'components/TrainingBanner';
import { useLocation } from 'react-router-dom';
import { ONBOARDING_ORGANISATION_ADMIN_STATIC_DATA } from 'components/OnboardingModal/onboardingModalStaticData';
import useCsvDownload from './../../../Hooks/useCsvDownload';

const Dashboard = () => {
  const { coachesCount, completedCustomisation, hasFacilitator, loading } =
    useGetOrgProgress();
  const [step, setStep] = useState(0);
  const [data, handleClick] = useCsvDownload(`/csv/kpis`);

  const { stats } = useGetStats();
  const { user } = useAuth();

  const { state } = useLocation();
  const [showOnboarding, setShowOnboarding] = useState(false);
  useEffect(() => {
    if (!!state?.showOnboarding) {
      setShowOnboarding(true);
      window.history.replaceState({}, document.title);
    }
  }, [state]);

  const {
    totalCoachesCount: { total: totalCoachesCount },
    totalLearnersCount: { total: totalLearnersCount },
    learningTime: { totalSessionTimeHours },
    totalCompletedMilestones,
  } = stats || {
    totalCoachesCount: { total: 0 },
    totalLearnersCount: { total: 0 },
    learningTime: { totalSessionTimeHours: 0 },
    totalCompletedMilestones: 0,
  };

  useEffect(() => {
    if (completedCustomisation) setStep(1);
    if (completedCustomisation && hasFacilitator) setStep(2);
    if (completedCustomisation && hasFacilitator && coachesCount) setStep(3);
  }, [completedCustomisation, hasFacilitator, coachesCount]);

  const trainingComplete = !!(
    completedCustomisation &&
    hasFacilitator &&
    coachesCount
  );

  const closeOnboarding = useCallback(() => {
    setShowOnboarding(false);
  }, []);

  return (
    <S.Wrapper>
      <S.HeaderWrapper mb="6">
        <div>
          <T.H1 id="welcome-message" color="neutralMain" mb="2">
            Welcome back to Turning Pages
          </T.H1>
          <T.P color="neutral70" size="med">
            {getGreetings()}, {user.firstName}!
          </T.P>
        </div>
        <Button.BasicButton
          icon={'userWithPlusFill'}
          iconProps={{
            width: '32px',
            height: '32px',
          }}
          to={navRoutes.COMMON.ADD_LEARNER}
          width="auto"
          height="auto"
          size="medium"
        >
          New learner
        </Button.BasicButton>
      </S.HeaderWrapper>
      {step < 3 && !loading && (
        <TrainingBanner
          isAllStepCompleted={trainingComplete}
          stepIndex={step}
          stepData={ORG_STEPS[step]}
        />
      )}

      <S.QuickAccessWrapper>
        <T.H2 mb="16px" weight="bold" size="med">
          Quick Access
        </T.H2>

        <Stack
          spacing="16px"
          direction={{
            xs: 'column',
            md: 'row',
          }}
          sx={{
            flexWrap: {
              xs: 'wrap',
              md: 'nowrap',
            },
          }}
        >
          <QuickAccessCard
            text="My learners"
            to={NR.COMMON.LEARNERS}
            bgColor={theme.colors.neutralLight}
          />
          <QuickAccessCard
            text="My Coaches"
            to={NR.COMMON.COACHES.replace(
              ':statusType',
              coachStatusesQuery.ALL
            )}
            bgColor={theme.colors.neutralLight}
          />
          <QuickAccessCard
            text="My facilitators"
            to={NR.COMMON.FACILITATORS}
            bgColor={theme.colors.neutralLight}
          />
        </Stack>
      </S.QuickAccessWrapper>

      <Stack spacing="16px" width="100%">
        <T.H2 weight="bold" size="med" mb="1">
          Statistics
        </T.H2>

        <Stack
          gap="4px"
          display={'grid'}
          gridTemplateColumns={{
            xs: '1fr',
            md: '1fr 1fr',
          }}
        >
          <StatsChart
            label="Learners"
            actionText={`View all learners`}
            actionLink={NR.COMMON.LEARNERS}
            statChartType="totalCount"
            backgroundMode="light"
            value={totalLearnersCount}
          />
          <StatsChart
            label="Coaches"
            actionText={`View all coaches`}
            actionLink={NR.COMMON.COACHES.replace(
              ':statusType',
              coachStatusesQuery.ALL
            )}
            statChartType="coach"
            backgroundMode="dark"
            value={totalCoachesCount}
          />

          <StatsChart
            statChartType="hours"
            backgroundMode="dark"
            value={totalSessionTimeHours}
          />
          <StatsChart
            statChartType="milestone"
            backgroundMode="light"
            value={Number(totalCompletedMilestones)}
          />
        </Stack>
      </Stack>

      {user?.organisationId === 1 && (
        <>
          <T.H2 weight="bold" size="med" mt="8" mb="2">
            Export KPIs
          </T.H2>

          <Button.BasicButton
            variant="secondary"
            loading={data.loading}
            onClick={handleClick}
            width="350px"
          >
            <T.P color="white" weight="bold">
              Export KPIs
            </T.P>
          </Button.BasicButton>
        </>
      )}

      <OnboardingModal
        isModalOpen={showOnboarding}
        onClose={closeOnboarding}
        handleClick={closeOnboarding}
        data={ONBOARDING_ORGANISATION_ADMIN_STATIC_DATA}
      />
    </S.Wrapper>
  );
};

export default Dashboard;

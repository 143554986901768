import {
  useFetch,
  usePatch,
  usePost,
  formatQueryError,
} from '../utils/queries';
import { FACILITATORS_BASE } from '../constants';
import { useMutation } from 'react-query';
import axios from 'axios';

function useUpdateCoachInterviewDate({ date = null, coachId }) {
  return usePatch(
    `${FACILITATORS_BASE}/update-coach-interview-date`,
    {
      date,
      coachId,
    },
    {
      options: {
        invalidateAll: true,
      },
    }
  );
}

function useUpdateCoachType({ value = null, coachId }) {
  return usePatch(
    `${FACILITATORS_BASE}/update-coach-type`,
    {
      value,
      coachId,
    },
    {
      options: {
        invalidateAll: true,
      },
    }
  );
}

function useFacilitatorUpdateCoachNote({ note, noteId, coachId }) {
  return usePatch(
    `${FACILITATORS_BASE}/coach-notes/update`,
    {
      note,
      noteId,
    },
    {
      options: {
        invalidateKey: [`${FACILITATORS_BASE}/coach-notes/`, { coachId }],
      },
    }
  );
}

function useGetDefaultFacilitator() {
  const context = useFetch(
    `${FACILITATORS_BASE}/default-facilitator`,
    undefined
  );

  return {
    ...context,
    facilitator: context.data,
    error: formatQueryError(context),
  };
}

function useGetAllFacilitators({ page = 1, limit = 20 }) {
  const context = useFetch(
    `${FACILITATORS_BASE}/all-facilitators`,
    {
      page,
      limit,
    },
    {
      queryKey: `GET_ALL_FACILITATORS`,
    }
  );
  return {
    ...context,
    facilitators: context.data,
    error: formatQueryError(context),
  };
}

function useGetFacilitatorsCount({ search = '' }) {
  const context = useFetch(
    `${FACILITATORS_BASE}/count`,
    {
      search,
    },
    {
      queryKey: `GET_FACILITATORS_COUNT`,
    }
  );
  return {
    ...context,
    facilitatorCount: context.data,
    error: formatQueryError(context),
  };
}

function useGetFacilitators(
  { searchValue, sortField, sortOrder, page, limit, statusType } = {},
  options
) {
  const context = useFetch(
    `${FACILITATORS_BASE}/`,
    {
      search: searchValue,
      sortField,
      sortOrder,
      page,
      limit,
      statusType,
    },
    {
      queryKey: `GET_FACILITATORS`,
      ...options,
    }
  );

  return {
    ...context,
    facilitators: context.data,
    error: formatQueryError(context),
  };
}

const useAddFacilitators = () => {
  const context = usePost(`${FACILITATORS_BASE}`, undefined, {
    queryKey: `ADD_FACILITATORS`,
    options: {
      invalidateKey: [`GET_FACILITATORS`],
    },
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useGetFacilitatorsById = (id, options) => {
  const context = useFetch(`${FACILITATORS_BASE}/${id}`, undefined, {
    queryKey: [`GET_FACILITATOR_BY_ID`, { id }],
    ...options,
  });
  return {
    ...context,
    facilitator: context.data,
    error: formatQueryError(context),
  };
};

const useUpdateFacilitatorOrganisations = (id, options) => {
  const context = useMutation(
    (data) => axios.patch(`${FACILITATORS_BASE}/${id}`, data),
    {
      queryKey: [`GET_FACILITATOR_BY_ID`, { id }],
      options: {
        invalidateAll: true,
        ...options,
      },
    }
  );
  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useMakeFacilitatorAsCoach = (options) => {
  const mutate = useMutation((data) => {
    return axios.patch(`${FACILITATORS_BASE}/${data.id}/roles`, data);
  }, options);

  return mutate;
};

export {
  useUpdateCoachInterviewDate,
  useUpdateCoachType,
  useFacilitatorUpdateCoachNote,
  useGetDefaultFacilitator,
  useGetFacilitators,
  useAddFacilitators,
  useGetAllFacilitators,
  useGetFacilitatorsById as useGetFacilitatorById,
  useUpdateFacilitatorOrganisations,
  useGetFacilitatorsCount,
  useMakeFacilitatorAsCoach,
};

import {
  Grid,
  Typography as T,
  ProgressCard,
  Loading,
} from '../../../components';
import useCustomTrainingStepHook from './useCustomTrainingStepHook';
const { Row, Col } = Grid;

const Training = () => {
  const { isAPILoading, activeStep, trainingContent, userId } =
    useCustomTrainingStepHook();

  if (isAPILoading) {
    return <Loading />;
  }

  return (
    <Row dir="column">
      <Col w={[4, 12, 6]}>
        <T.H1 weight="bold" color="neutralMain">
          Coach training
        </T.H1>
      </Col>
      <Col w={[4, 12, 12]} mt="2" mb="6">
        <T.P>
          Here is everything you need to know in order to deliver Turning Pages
        </T.P>
      </Col>
      <Col w={[4, 12, 8]}>
        <ProgressCard
          loading={isAPILoading}
          id={userId}
          activeStep={activeStep}
          data={trainingContent}
          completionMessage="🎉 Training complete"
        />
      </Col>
    </Row>
  );
};

export default Training;

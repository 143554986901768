import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 24px 16px;
  background-color: ${({ theme, isCompleted }) =>
    isCompleted ? theme.colors.quaternaryLight : theme.colors.primaryLight};
  position: sticky;
  top: 112px;
  z-index: 20;
  ${({ isFirstItem }) =>
    !isFirstItem &&
    `
    // position: relative;
    margin-top: -220px;
    margin-bottom: 130px;
    `}
  ${({ theme }) => theme.media.mobile} {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

import { useState } from 'react';

import { Grid, Typography as T, Inputs, Button } from '../../../components';
import { getMediaUrl } from '../../../helpers';
import { useGeneralState } from '../../../context/general-state';

import { defaultGoals } from './helpers';
import { audio } from '../../../constants';
import { useCreateLearnerGoal } from '../../../api-calls/learnersGoals.queries';

import * as S from './style';
import AddGoalList from './AddGoalList';

const { Col, Row } = Grid;

const defaultGoalsArr = Object.values(defaultGoals).map((g) => ({
  label: g.title,
  value: g.title,
  audio: g.audio,
  imageUrl: g.image,
}));

const AddGoal = ({ goals, setGoals, setOpenAddModal, refetch }) => {
  const [canAddList, setCanAddList] = useState(false);

  const [selectedGoal, setSelectedGoal] = useState('');
  const [otherGoal, setOtherGoal] = useState('');
  const [showInputs, setShowInputs] = useState(false);
  const { mutateAsync: createGoal } = useCreateLearnerGoal({
    title: selectedGoal || otherGoal,
    list: [],
  });
  const handleCreateGoal = async ({ title, list }) => {
    await createGoal({
      title,
      list: list.map((item) => item.title),
    });

    setShowInputs(false);

    setOtherGoal('');

    setSelectedGoal('');

    setCanAddList(false);

    setCanAddList(false);

    refetch();
  };

  const { state: generalState } = useGeneralState();
  const defaultGoalsWithOthers = [
    ...defaultGoalsArr.filter(
      (item) => !goals.find((goal) => goal.title === item.value)
    ),
    {
      label: 'Others',
      value: 'Others',
      audio: getMediaUrl(audio.others, true, generalState?.preferredVoice),
    },
  ];

  if (canAddList) {
    return (
      <AddGoalList
        selectedGoal={otherGoal || selectedGoal}
        handleCreateGoal={handleCreateGoal}
        setOpenAddModal={setOpenAddModal}
        handleCancel={() => {
          setCanAddList(false);

          if (showInputs) {
            setShowInputs(false);
          }
          if (otherGoal) {
            setOtherGoal('');
          }
          if (selectedGoal) {
            setSelectedGoal('');
          }
        }}
      />
    );
  }

  return (
    <div style={{ marginTop: 24 }}>
      {showInputs ? (
        <>
          <S.PlayButtonWrapper mb="5">
            <Button.PlayButton
              width="56px"
              height="56px"
              audioUrl={getMediaUrl(
                null, // TODO: update the sound when it's ready
                true,
                generalState?.preferredVoice
              )}
              iconProps={{ width: 27.2 }}
              mr={3}
              fallbackText="What is your new goal?"
            />

            <Col w={[3, 7, 7]} mlM="3">
              <T.H2>What is your new goal?</T.H2>
            </Col>
          </S.PlayButtonWrapper>
          <Row inner={true}>
            {defaultGoalsWithOthers.map((item, index) => {
              return (
                <Col w={[4, 4, 4]} mt="4" key={item.title + item.value}>
                  <S.GoalCardWrapper
                    onClick={() => {
                      if (otherGoal) {
                        setOtherGoal('');
                      }
                      setSelectedGoal(item.value);
                    }}
                    selected={selectedGoal === item.value}
                  >
                    <Button.PlayButton
                      width="56px"
                      height="56px"
                      audioUrl={getMediaUrl(
                        item?.audio,
                        true,
                        generalState?.preferredVoice
                      )}
                      iconProps={{ width: 27.2 }}
                      mr={3}
                      fallbackText={item.label}
                    />
                    <T.P mb={2} weight="bold">
                      {item.label}
                    </T.P>
                  </S.GoalCardWrapper>
                </Col>
              );
            })}
          </Row>
          {selectedGoal === 'Others' && (
            <Row inner={true} mt={'6'}>
              <Col w={[4, 12, 12]}>
                <Inputs.BasicInput
                  placeholder="A personal goal..."
                  type="text"
                  label={'Something else? Please write it down'}
                  margins={{ mt: '2', mb: '1' }}
                  value={otherGoal}
                  autoFocus
                  handleChange={(input) => {
                    return setOtherGoal(input);
                  }}
                />
              </Col>
            </Row>
          )}

          <Row inner={true} mt={5}>
            <Col w={[4, 12, 12]}>
              <Button.BasicButton
                variant="primary"
                handleClick={() => setCanAddList(true)}
                disabled={
                  !((selectedGoal && selectedGoal !== 'Others') || otherGoal)
                }
                showSound
                width={'100%'}
                audioUrl={getMediaUrl(
                  audio?.addANewGoal,
                  true,
                  generalState?.preferredVoice
                )}
                fallbackText="Add a new goal"
              >
                <T.P color="white" weight="bold">
                  Add a new goal
                </T.P>
              </Button.BasicButton>
            </Col>
          </Row>
        </>
      ) : (
        <Button.BasicButton
          variant="primary"
          handleClick={() => setShowInputs(true)}
          showSound
          width={'100%'}
          audioUrl={getMediaUrl(
            audio?.addANewGoal,
            true,
            generalState?.preferredVoice
          )}
          fallbackText="Add a new goal"
        >
          <T.P color="white" weight="bold">
            Add a new goal
          </T.P>
        </Button.BasicButton>
      )}
      <S.Divider />
    </div>
  );
};

export default AddGoal;

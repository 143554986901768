import { useEffect, useState } from 'react';

import * as T from '../../../../components/Typography';
import { PlayButton } from '../../../../components/Button';
import { Markdown } from '../../../../components';
import { createH1 } from '../../../../utils/markdown';
import { useLocation } from 'react-router-dom';
import StepFooterButtons from '../StepFooterButtons';

import InputsWrapper from './InputsWrapper';
import * as S from './style';

const TypeEndings = ({
  data: { title, textAudio, textAudioUrl, correctOptions } = {},
  handleNext,
  stuckAtReview,
  disabled,
  preview,
}) => {
  const [enableNext, setEnableNext] = useState(false);

  const location = useLocation();
  useEffect(() => {
    setEnableNext(false);
  }, [location]);

  return (
    <S.Wrapper>
      <S.ContentWrapper>
        {textAudio ? (
          <S.TextAudioWrapper mt={1} mb={5}>
            {textAudioUrl ? (
              <>
                <PlayButton
                  audioUrl={textAudioUrl}
                  width="56px"
                  height="56px"
                  iconProps={{ width: '32px', height: '32px' }}
                />
                <T.P ml={4} weight="regular" size="med">
                  {textAudio}
                </T.P>
              </>
            ) : (
              <T.P weight="regular" size="med">
                {textAudio}
              </T.P>
            )}
          </S.TextAudioWrapper>
        ) : null}
        {title ? (
          <Markdown
            text={createH1(title)}
            customStyles={{
              h1: {
                size: 'extraLarge',
                ta: 'center',
                weight: 'bold',
                mb: 0,
                color: 'neutralMain',
              },
            }}
          />
        ) : null}
        {correctOptions && (
          <InputsWrapper
            correctOptions={correctOptions}
            enableNext={enableNext}
            setEnableNext={setEnableNext}
          />
        )}
      </S.ContentWrapper>

      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={handleNext}
        preview={preview}
        disabled={disabled}
        enableNext={enableNext}
      />
    </S.Wrapper>
  );
};

export default TypeEndings;

import { Col, Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';
import * as S from './style';
import { useGetCoachNotes } from '../../../api-calls/coaches.queries';
import NoteCard from '../../../components/NoteCard';
import { navRoutes } from '../../../constants';
import { useParams } from 'react-router-dom';
import AddNote from './Notes/AddNote';

const Notes = () => {
  const { coachId, statusType } = useParams();
  const { notes = [], error: getCoachNotesError } = useGetCoachNotes({
    coachId,
  });

  const viewAllNotesLink = navRoutes.COMMON.COACH_NOTES.replace(
    ':statusType',
    statusType
  ).replace(':coachId', coachId);

  if (getCoachNotesError) {
    return (
      <T.P mb={2} mt={2} color="error">
        {getCoachNotesError}
      </T.P>
    );
  }

  return (
    <Col w={[4, 12, 12]} mt="7" key={`column-notes`}>
      <S.ColWrapper>
        <Row inner mb="3">
          <Col w={[2, 6, 6]}>
            <T.H2 size="med">Notes</T.H2>
          </Col>
          <Col w={[2, 6, 6]} display="flex" jc="flex-end">
            {!!notes.length && (
              <T.Link to={viewAllNotesLink} color="neutralMain">
                View all
              </T.Link>
            )}
          </Col>
        </Row>

        {notes.length ? (
          notes
            .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
            .slice(0, 3)
            .map(({ note, id, updatedAt }) => (
              <Row mb={4}>
                <NoteCard
                  createdAt={updatedAt}
                  editPath={navRoutes.COMMON.EDIT_COACH_NOTE}
                  editState={{
                    note,
                    noteId: id,
                    coachId,
                  }}
                  note={note}
                />
              </Row>
            ))
        ) : (
          <Row inner>
            <Col w={[4, 12, 6]}>
              <T.P>There is no notes yet</T.P>
            </Col>
          </Row>
        )}

        <AddNote />
      </S.ColWrapper>
    </Col>
  );
};

export default Notes;

import { useNavigate } from 'react-router-dom';
import { useGeneralState } from '../../../context/general-state';
import { Typography as T, Button, Icon } from '../../../components';
import { getMediaUrl } from '../../../helpers';
import { navRoutes as R, audio } from '../../../constants';
import { useAuth } from 'context/auth';
import * as S from './style';
import { BackButton } from 'components/BackButton';
import { LEARNER } from 'constants/nav-routes';

const Menu = () => {
  const navigate = useNavigate();
  const { state: generalState } = useGeneralState();
  const { logout } = useAuth();

  return (
    <S.Wrapper>
      <BackButton link={LEARNER.DASHBOARD} />
      <S.Content>
        <S.PlayButtonWrapper>
          <Button.BasicButton
            icon="exercises"
            variant="primary"
            handleClick={() => navigate(R.LEARNER.DASHBOARD)}
            showSound
            audioUrl={getMediaUrl(
              audio.myExercises,
              true,
              generalState?.preferredVoice
            )}
            fallbackText="My exercises"
          >
            My exercises
          </Button.BasicButton>
        </S.PlayButtonWrapper>

        <S.PlayButtonWrapper>
          <Button.BasicButton
            icon="goals"
            variant="secondary"
            handleClick={() => navigate(R.LEARNER.GOALS)}
            showSound
            audioUrl={getMediaUrl(
              audio.myGoals,
              true,
              generalState?.preferredVoice
            )}
            fallbackText="My goals"
          >
            <T.P color="white" weight="bold">
              My goals
            </T.P>
          </Button.BasicButton>
        </S.PlayButtonWrapper>

        <S.PlayButtonWrapper>
          <Button.BasicButton
            icon="profile"
            variant="tertiary"
            iconProps={{ width: '23px', height: '23px' }}
            handleClick={() => navigate(R.LEARNER.PROFILE)}
            showSound
            audioUrl={getMediaUrl(
              audio.myProfile,
              true,
              generalState?.preferredVoice
            )}
            fallbackText="My profile"
          >
            My profile
          </Button.BasicButton>
        </S.PlayButtonWrapper>
        <S.PlayButtonWrapper>
          <Button.BasicButton
            icon="profile"
            variant="tertiary"
            iconProps={{ width: '23px', height: '23px' }}
            handleClick={() => navigate(R.LEARNER.WELCOME)}
            showSound
            audioUrl={getMediaUrl(
              audio.howToUse,
              true,
              generalState?.preferredVoice
            )}
            fallbackText="How to use"
          >
            How to use
          </Button.BasicButton>
        </S.PlayButtonWrapper>
        <S.PlayButtonWrapper>
          <Button.PlayButton
            width="56px"
            height="56px"
            audioUrl={getMediaUrl(
              audio.logout,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: 27.2 }}
            mr={3}
            backgroundColor="neutralSurface"
            fallbackText="Logout"
          />

          <S.LogoutButton onClick={logout}>
            <Icon icon="logout" width="32px" height="32px" />
            Logout
          </S.LogoutButton>
        </S.PlayButtonWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

export default Menu;

import React, { useEffect, useState } from 'react';
import Icon from '../Icon';
import * as S from './style';
import * as T from '../Typography';

const InlineCopy = ({ text, textToCopy, color, copyIcon }) => {
  const [copied, setCopied] = useState(false);

  const copyCodeToClipboard = async () => {
    setCopied(false);
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopied(true);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Failed to copy text: ', err);
    }
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 750);
    }
  }, [copied]);

  return (
    <>
      <S.InlineWrapper>
        <S.InlineCopyBtn onClick={copyCodeToClipboard}>
          <Icon
            icon={copied ? 'tick' : copyIcon || 'copy'}
            color={copied ? 'green' : color || 'neutral90'}
            width="16"
            height="16"
            mr="1"
          />
          <T.P color={color || 'gray8'} weight="bold" ellipsis={true}>
            {text}
          </T.P>
        </S.InlineCopyBtn>
      </S.InlineWrapper>
    </>
  );
};

export default InlineCopy;

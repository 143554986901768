import { useEffect, createContext, useState, useContext } from 'react';
import {
  useParams,
  useNavigate,
  useLocation,
  matchPath,
} from 'react-router-dom';
import { useAuth } from './auth';
import { userRoles, navRoutes } from '../constants';
import { getExerciseById } from 'api-calls/exercises';
import ExerciseSuccessModal from '../components/ExerciseSuccessModal';
import useSteps from 'Hooks/useSteps';

const ExerciseContext = createContext({
  exercise: {},
  setExercise: () => {},
  setSubProgress: () => {},
});

const ExerciseProvider = (props) => {
  const { user } = useAuth();
  const location = useLocation();
  const [exercise, setExercise] = useState({});
  const match = matchPath(
    { path: navRoutes.EXERCISES.SINGLE_STEP },
    location.pathname
  );
  const { stepId } = match?.params || {};
  const steps = useSteps(exercise.steps, stepId);

  const [progressPercent, setProgressPercent] = useState(0);
  const [subProgress, setSubProgress] = useState(0);
  const _progressPercent = steps?.currentStepIndex / steps?.stepsArray?.length;

  const learnerId =
    user.role === userRoles.LEARNER ? user.id : user.selectedLearnerId;
  const { exerciseId: _exerciseId } = useParams();
  const exerciseId = Number(_exerciseId);

  const navigate = useNavigate();

  useEffect(() => {
    setProgressPercent(_progressPercent);
    setSubProgress(0);
  }, [_progressPercent]);

  useEffect(() => {
    const getExercise = async () => {
      const { data } = await getExerciseById({ id: exerciseId, learnerId });
      if (data) {
        setExercise(data);
      }
    };

    if (exerciseId) {
      getExercise();
    }
  }, [exerciseId, learnerId]);

  if (stepId === 'start' && steps?.stepsArray?.length > 0) {
    const startStep =
      steps.stepsArray.find((step) => step.stuck)?.id ||
      steps?.stepsArray?.[0]?.id;

    navigate(
      navRoutes.EXERCISES.SINGLE_STEP.replace(
        ':exerciseId',
        exerciseId
      ).replace(':stepId', startStep),
      { replace: true }
    );
  }
  const value = {
    exercise,
    ...steps,
    progressPercent: progressPercent + subProgress / steps?.stepsArray?.length,
    setSubProgress,
  };

  return (
    <>
      <ExerciseSuccessModal {...steps} />
      <ExerciseContext.Provider value={value} {...props} />
    </>
  );
};

const useExercise = () => {
  const value = useContext(ExerciseContext);
  return value;
};

export { ExerciseProvider, useExercise };
export default ExerciseContext;

import { BasicButton } from 'components/Button';
import { Col, Row } from 'components/Grid';
import * as T from 'components/Typography';
import * as S from './style';
import { useCallback, useState } from 'react';
import { useUpdateActiveStatus } from 'api-calls/learners.queries';
import { useParams } from 'react-router-dom';
import { Dropdown } from 'components/Inputs';

const ModifyLearnerStatus = ({ learner }) => {
  const { userId } = useParams();
  const [inactiveReason, setInactiveReason] = useState(null);

  const {
    mutateAsync: updateActiveStatus,
    isLoading,
    error,
  } = useUpdateActiveStatus({
    learnerId: userId,
    activeStatus: learner.active,
    activeStatusDate: new Date(),
  });
  const [showConfirmForm, setShowConfirmForm] = useState(false);
  const markLearnerAsInactive = useCallback(() => {
    setShowConfirmForm(true);
  }, []);

  const dismissConfirmForm = useCallback(() => {
    setShowConfirmForm(false);
  }, []);

  const handleUpdateActiveStatus = useCallback(() => {
    updateActiveStatus(
      {
        learnerId: userId,
        activeStatus: !learner.active,
        activeStatusDate: new Date(),
        inactiveReason,
      },
      {
        onSuccess: () => {
          dismissConfirmForm();
        },
      }
    );
  }, [
    dismissConfirmForm,
    updateActiveStatus,
    learner.active,
    userId,
    inactiveReason,
  ]);
  return (
    <>
      <T.P mb="6">
        Here you can mark this learner as active / inactive. Inactive learners
        will not be included in any activity stats and will not be visible to
        their assigned coaches.
      </T.P>
      {showConfirmForm ? (
        <S.CardWrapper>
          <T.H2 size="med" mb="2">
            Are you sure, you want to mark{' '}
            {learner?.firstName || 'this learner'} as{' '}
            {learner.active ? 'inactive' : 'active'}?
          </T.H2>
          {!!error && (
            <T.P color="error" mb="6">
              There was an error managing this learner's activity status. Please
              try again later.
            </T.P>
          )}
          {learner.active ? (
            <>
              <T.P mb="6">
                By doing this {learner?.firstName || 'this learner'} will not be
                able to complete their learning journey unless you reactivate
                their account.
              </T.P>
              {learner.active && (
                <Dropdown
                  label="Please select a reason"
                  placeholder="Select..."
                  options={[
                    {
                      value: 'Chose to stop - Goal met',
                      label: 'Chose to stop - Goal met',
                    },
                    {
                      value: "Chose to stop - Didn't like",
                      label: "Chose to stop - Didn't like",
                    },
                    {
                      value: 'Completed',
                      label: 'Completed',
                    },
                    { value: 'Did not attend', label: 'did not attend' },
                    { value: 'Other', label: 'Other' },
                  ]}
                  selected={
                    inactiveReason && {
                      value: inactiveReason,
                      label: inactiveReason,
                    }
                  }
                  handleChange={(inactiveReason) =>
                    setInactiveReason(inactiveReason)
                  }
                  m={{ mt: 5, mb: 3 }}
                />
              )}
            </>
          ) : null}
          <Row jc={'flex-end'} mt="6" inner>
            <Col w={[4, 5, 5]} display="flex" jc="flex-end">
              <BasicButton handleClick={dismissConfirmForm} variant="secondary">
                Cancel
              </BasicButton>
            </Col>
            <Col w={[4, 5, 5]} display="flex" jc="flex-end">
              <BasicButton
                handleClick={handleUpdateActiveStatus}
                variant="primary"
                loading={isLoading}
                disabled={learner.active && !inactiveReason}
              >
                Mark learner as {learner.active ? 'inactive' : 'active'}
              </BasicButton>
            </Col>
          </Row>
        </S.CardWrapper>
      ) : (
        <BasicButton
          handleClick={markLearnerAsInactive}
          variant="primary"
          maxWidth="285px"
        >
          Mark learner as {!learner.active ? 'active' : 'inactive'}
        </BasicButton>
      )}
    </>
  );
};

export default ModifyLearnerStatus;

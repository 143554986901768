import ManualCard from 'components/ManualCard';
import React from 'react';
import { useMemo } from 'react';
import * as S from './style';
import * as T from 'components/Typography';
import moment from 'moment';
const FirstRow = ({ e }) => {
  // moved by trainer
  if (e.status === 'current' || e.id === e.currentMilestoneId) {
    if (!e.createdAt && e.id !== 1 && e.currentMilestoneLastUpdateDate) {
      return (
        <>
          <T.P color="neutral90">
            level moved to here on{' '}
            {moment(e.currentMilestoneLastUpdateDate).format('DD MMMM YYYY')}
          </T.P>
          {e.firstCompletedStepDate && (
            <T.P color="neutral90">
              <strong>Started </strong>
              {moment(e.firstCompletedStepDate).format('DD MMMM YYYY')}
            </T.P>
          )}
        </>
      );
    }

    // reached by practicing
    if (e.firstCompletedStepDate) {
      return (
        <T.P color="neutral90">
          <strong>Started </strong>
          {moment(e.firstCompletedStepDate).format('DD MMMM YYYY')}
        </T.P>
      );
    }

    return <T.P color="neutral90">Not started yet</T.P>;
  } else if (e.status === 'skipped') {
    return (
      <>
        {e.firstCompletedStepDate && (
          <T.P color="neutral90">
            <strong>Started </strong>
            {moment(e.firstCompletedStepDate).format('DD MMMM YYYY')}
          </T.P>
        )}
        <T.P color="neutral90">Skipped</T.P>
      </>
    );
  }

  if (e.id >= e.currentMilestoneId + 1) {
    if (e.id === 1) {
      return <T.P color="neutral90">Not started yet</T.P>;
    }
    return <T.P color="neutral90">Not reached yet</T.P>;
  }

  if (e.firstCompletedStepDate) {
    return (
      <T.P color="neutral90">
        <strong>Started </strong>
        {moment(e.firstCompletedStepDate).format('DD MMMM YYYY')}
        <br />
      </T.P>
    );
  }

  return null;
};

const SecondRow = ({ e }) => {
  if (e.status === 'completed') {
    return (
      <T.P color="neutral90">
        <strong>Achieved </strong> {moment(e.createdAt).format('DD MMMM YYYY')}
      </T.P>
    );
  }

  return null;
};

const ThirdRow = ({ e }) => {
  if (e.status === 'completed') {
    return (
      <T.P color="neutral90">
        <strong>Code: </strong> {e.code}
      </T.P>
    );
  }

  return null;
};

const Milestones = ({ stats }) => {
  const items = useMemo(() => {
    const tempArr = [];
    let manNum = 0;
    for (let i = 0; i < stats?.milestonesReached?.length; i += 3) {
      manNum++;
      tempArr.push(
        <S.Wrapper>
          <ManualCard manualNumber={manNum} />
          <S.OuterWrapper>
            {stats?.milestonesReached?.slice(i, i + 3).map((e) => (
              <S.MilestoneWrapper>
                <T.P weight="bold" mb="2">
                  {e.title}
                </T.P>
                <FirstRow e={e} />
                <SecondRow e={e} />
                <ThirdRow e={e} />
              </S.MilestoneWrapper>
            ))}
          </S.OuterWrapper>
        </S.Wrapper>
      );
    }

    return tempArr;
  }, [stats]);

  return <div>{items}</div>;
};

export default Milestones;

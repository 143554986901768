import { useEffect } from 'react';

import {
  useDeleteUserPersonalInfo,
  useGetUserPersonalInfo,
  useUpdateUserPersonalInfo,
} from '../../../api-calls/users.queries';

const initialState = {
  form: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    organisationId: '',
    name: '',
  },
  httpError: '',
  validationErrs: {},
};
export const useApiCallQueries = ({ setState, state }) => {
  const {
    mutateAsync: updateUserPersonalInfo,
    isLoading: isUpdatingUserPersonalInfoLoading,
    error: isUpdatingUserPersonalInfoError,
  } = useUpdateUserPersonalInfo({
    ...state.form,
  });

  const {
    mutateAsync: deleteUserPersonalInfo,
    isLoading: isDeletingUserPersonalInfoLoading,
  } = useDeleteUserPersonalInfo({});

  const { data: userPersonalInfo, isLoading: isLoadingUserPersonalInfo } =
    useGetUserPersonalInfo(
      {},
      {
        initialData: initialState.form,
        onError: (e) => {
          setState({ httpError: e?.message || 'Something went wrong' });
        },
        onSuccess: (data) =>
          setState((prevState) => ({ form: { ...prevState.form, ...data } })),
      }
    );

  useEffect(() => {
    // we use here useEffect to set the form data after the userPersonalInfo is loaded
    setState((prevState) => ({
      form: {
        ...prevState.form,
        ...userPersonalInfo,
      },
    }));
  }, [setState, userPersonalInfo]);

  return {
    userPersonalInfo,
    isLoadingUserPersonalInfo,
    updateUserPersonalInfo,
    isUpdatingUserPersonalInfoLoading,
    deleteUserPersonalInfo,
    isDeletingUserPersonalInfoLoading,
    isUpdatingUserPersonalInfoError,
  };
};

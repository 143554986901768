import axios from 'axios';
import handleError from './format-error';
import { queryClient } from '../api-calls/queryClient';
const LEARNERS_BASE = '/learners';

const login = async ({ learnerUniqueId, options } = {}) => {
  try {
    const { data } = await axios.post(`${LEARNERS_BASE}/login`, {
      learnerUniqueId,
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const createLearner = async ({ form, options } = {}) => {
  try {
    const { data } = await axios.post(`${LEARNERS_BASE}`, form);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getLearnerUniqueId = async ({ learnerUserId, options } = {}) => {
  try {
    const { data } = await axios.get(`${LEARNERS_BASE}/${learnerUserId}`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getLearnerSelfLearningByUserId = async ({ id, options } = {}) => {
  try {
    const { data } = await axios.get(`${LEARNERS_BASE}/${id}/self-learning`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getLearnerByUniqueId = async ({ learnerUniqueId, options } = {}) => {
  try {
    const { data } = await axios.get(
      `${LEARNERS_BASE}/unique-id/${learnerUniqueId}`
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getLearners = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(`${LEARNERS_BASE}`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const markCompletedStepsAsReviewedByCoach = async ({
  learnerId,
  type,
  options,
} = {}) => {
  try {
    const { data } = await axios.patch(
      `${LEARNERS_BASE}/${learnerId}/completed-steps`,
      {
        type,
      }
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const addLearnerPersonalGoals = async ({
  learnerId,
  personalGoals,
  options,
} = {}) => {
  try {
    const { data } = await axios.patch(
      `${LEARNERS_BASE}/${learnerId}/personal-goals`,
      {
        personalGoals,
      }
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const createProgressReport = async ({ form, milestoneId, options } = {}) => {
  try {
    const { data } = await axios.post(`${LEARNERS_BASE}/fill-progress-report`, {
      ...form,
      milestoneId,
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

// handled by useGetLearnerStats in learners.queries

const addLearnerInterests = async ({ learnerId, form, options } = {}) => {
  try {
    const { data } = await axios.patch(
      `${LEARNERS_BASE}/${learnerId}/interests`,
      form
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const selectLearner = async ({ learnerId, options } = {}) => {
  try {
    const { data } = await axios.post(
      `${LEARNERS_BASE}/${learnerId}/select-learner`
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getLearnerStuckAt = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(`${LEARNERS_BASE}/stuck-at`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getLearnerReferralByLearner = async ({ learnerUserId, options } = {}) => {
  try {
    const { data } = await axios.get(
      `${LEARNERS_BASE}/${learnerUserId}/referral`
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const updateLearnerReferral = async ({ learnerUserId, form, options } = {}) => {
  try {
    const { data } = await axios.patch(
      `${LEARNERS_BASE}/${learnerUserId}/referral`,
      form
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const updateLearnerControlTreatmentType = async ({
  learnerUserId,
  form,
  options,
} = {}) => {
  try {
    const { data } = await axios.patch(
      `${LEARNERS_BASE}/${learnerUserId}/control-treatment-type`,
      form
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const updateLearnerCoach = async ({
  learnerUserId,
  coachId,
  form,
  options,
} = {}) => {
  try {
    const { data } = await axios.patch(
      `${LEARNERS_BASE}/${learnerUserId}/assign/${coachId}`,
      form
    );

    queryClient.invalidateQueries(`${LEARNERS_BASE}/`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const updateLearnerPreferredVoice = async ({
  learnerUserId,
  preferredVoice,
  options,
} = {}) => {
  try {
    const { data } = await axios.patch(
      `${LEARNERS_BASE}/${learnerUserId}/preferred-voice`,
      {
        preferredVoice,
      }
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export {
  login,
  createLearner,
  getLearnerUniqueId,
  getLearners,
  getLearnerByUniqueId,
  markCompletedStepsAsReviewedByCoach,
  getLearnerSelfLearningByUserId,
  addLearnerPersonalGoals,
  createProgressReport,
  addLearnerInterests,
  selectLearner,
  getLearnerStuckAt,
  getLearnerReferralByLearner,
  updateLearnerReferral,
  updateLearnerControlTreatmentType,
  updateLearnerCoach,
  updateLearnerPreferredVoice,
};

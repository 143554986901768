import { Typography as T } from '../../components';
import { useGeneralState } from 'context/general-state';
import ExerciseTimeline from '../../components/ExerciseTimeline';
import { useSearchParams } from 'react-router-dom';
import { useGetExercises } from '../../api-calls/exercises.queries';
import Loading from '../../components/Loading';
import { useEffect } from 'react';
import * as S from './style';

const LearnerDashboard = ({ forPublic }) => {
  const { isLoading, isError, error, isSuccess, milestones } = useGetExercises(
    {},
    { forPublic }
  );
  const { setState: setGeneralState } = useGeneralState();

  const [searchParams] = useSearchParams();
  const milestoneIdToMoveTo = searchParams.get('milestoneId');

  useEffect(() => {
    if (isSuccess) {
      setGeneralState({
        exerciseFlow: forPublic ? 'PLAYGROUND' : 'LEARNING',
      });
    }
  }, [isSuccess, forPublic, setGeneralState]);

  if (isLoading) return <Loading />;
  return (
    <S.Wrapper>
      {isSuccess || forPublic ? (
        <ExerciseTimeline
          milestones={milestones}
          forPublic={forPublic}
          milestoneIdToMoveTo={milestoneIdToMoveTo}
        />
      ) : null}
      {isError ? (
        <T.P mb="2" color="error">
          {error?.message}
        </T.P>
      ) : null}
    </S.Wrapper>
  );
};

export default LearnerDashboard;

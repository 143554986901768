import { useReducer } from 'react';

import * as T from '../../../../components/Typography';
import { BasicInput } from '../../../../components/Inputs';
import * as S from './style';
import { Icon } from '../../../../components';
import { string, number, array, object } from 'yup';

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }

  return { ...state, ...value };
}

const getInitialState = (data) => ({
  form: data
    ? {
        ...data,
        options: data?.options?.map((e, i) => ({ ...e, id: i })) || [
          { id: 0, option: '' },
        ],
        correctOptions: data?.correctOptions?.map((e, i) => ({
          ...e,
          id: i,
        })) || [{ id: 0, pre: '', answers: [''] }],
      }
    : {
        options: [{ id: 0, option: '' }],
        audioUrl: '',
        correctOptions: [{ id: 0, pre: '', answers: [''] }],
      },
  httpError: '',
  validationErrs: {},
  loading: false,
  submitLoading: false,
});

const WordJoin2 = ({ data, onChange, errors }) => {
  const [state, setState] = useReducer(reducer, getInitialState(data));

  const setFormData = (data) =>
    setState((prevState) => {
      const _correctOptions =
        data?.correctOptions || prevState?.form?.correctOptions;
      const form = {
        ...prevState.form,
        ...data,
      };
      onChange({
        ...form,
        correctOptions: _correctOptions,
      });
      return { form };
    });

  const addOption = () => {
    const maxId = Math.max(...state.form.options.map((e) => e.id), 0);
    setFormData({
      options: [...state.form.options, { id: maxId + 1, option: '' }],
    });
  };
  const removeOption = (id) => {
    setFormData({
      options: state.form.options.filter((e) => e.id !== id),
    });
  };

  const addCorrectOptions = () => {
    const maxId = Math.max(...state.form.correctOptions.map((e) => e.id), 0);
    setFormData({
      correctOptions: [
        ...state.form.correctOptions,
        { id: maxId + 1, option: '', answers: [''] },
      ],
    });
  };
  const removeCorrectOptions = (id) => {
    setFormData({
      correctOptions: state.form.correctOptions.filter((e) => e.id !== id),
    });
  };
  const onOptionChange = (value, id) => {
    setFormData({
      options: state.form.options.map((e) => {
        if (e.id !== id) return e;
        return { ...e, option: value };
      }),
    });
  };

  const onCorrectOptionsPreChange = (value, id) => {
    setFormData({
      correctOptions: state.form.correctOptions.map((e) => {
        if (e.id !== id) return e;
        return { ...e, pre: value };
      }),
    });
  };

  const onCorrectOptionsAnswerChange = (value, ansI, id) => {
    setFormData({
      correctOptions: state.form.correctOptions.map((e) => {
        if (e.id !== id) return e;
        return {
          ...e,
          answers: e.answers.map((e, i) => (ansI === i ? value : e)),
        };
      }),
    });
  };
  const addCorrectAnswer = (id) => {
    setFormData({
      correctOptions: state.form.correctOptions.map((e) => {
        if (e.id !== id) return e;
        return {
          ...e,
          answers: [...e.answers, ''],
        };
      }),
    });
  };
  const removeLastAnswer = (id) => {
    setFormData({
      correctOptions: state.form.correctOptions.map((e) => {
        if (e.id !== id) return e;
        return {
          ...e,
          answers: e.answers.slice(0, -1),
        };
      }),
    });
  };

  return (
    <div>
      <T.P mt={8} size="large" weight="bold">
        Correct combinations
      </T.P>
      {errors?.correctOptions && typeof errors.correctOptions === 'string' && (
        <T.P mb={2} mt={2} color="error">
          {errors.correctOptions}
        </T.P>
      )}
      {state.form.correctOptions.map((e, i) => {
        return (
          <>
            <BasicInput
              label={`Pre text ${i + 1}`}
              value={e.pre}
              handleChange={(value) => {
                onCorrectOptionsPreChange(value, e.id);
              }}
              m={{ mb: 2, mt: 5 }}
              helper='the prefix  e.g. "un +"'
              error={errors?.correctOptions && errors?.correctOptions[i]?.pre}
            />

            <div
              style={{
                width: '95%',
                marginLeft: 'auto',
              }}
            >
              <T.P size="med" mt="6" weight="bold">
                Possible Answers
              </T.P>
              {e?.answers?.map((ans, ansI) => (
                <>
                  <BasicInput
                    value={ans}
                    handleChange={(value) => {
                      onCorrectOptionsAnswerChange(value, ansI, e.id);
                    }}
                    m={{ mb: 2, mt: 3 }}
                    error={
                      errors?.correctOptions &&
                      errors?.correctOptions[i] &&
                      errors?.correctOptions[i][`answers[${ansI}]`]
                    }
                  />
                  {ansI === e.answers.length - 1 && ansI !== 0 && (
                    <S.Button
                      type="link"
                      danger
                      ghost
                      mt={1}
                      onClick={() => removeLastAnswer(e.id)}
                      ml="auto"
                    >
                      <Icon icon="cross" /> delete answer
                    </S.Button>
                  )}
                </>
              ))}
            </div>

            <S.Button
              type="link"
              ghost
              mt={5}
              mb={5}
              onClick={() => addCorrectAnswer(e.id)}
              disabled={e?.answers?.filter((_an) => !_an).length}
              ml="auto"
              mr="auto"
            >
              <Icon icon="plus" /> Add Answer
            </S.Button>

            {state.form.correctOptions.length === 1 && i === 0 ? null : (
              <S.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => removeCorrectOptions(e.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </S.Button>
            )}
          </>
        );
      })}

      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={addCorrectOptions}
        disabled={state.form.correctOptions.find(
          (e) => !e.pre || !e?.answers?.length
        )}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add a new row
      </S.Button>

      <T.P mt={8} size="large" weight="bold">
        Options
      </T.P>
      {errors?.options && typeof errors.options === 'string' && (
        <T.P mb={2} mt={2} color="error">
          {errors.options}
        </T.P>
      )}
      {state.form.options.map((e, i) => {
        return (
          <>
            <BasicInput
              label="Text"
              value={e.option}
              handleChange={(value) => {
                onOptionChange(value, e.id);
              }}
              m={{ mt: 5 }}
              helper='single word e.g. "stand"'
              error={errors?.options && errors?.options[i]?.option}
            />

            {state.form.options.length === 1 && i === 0 ? null : (
              <S.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => removeOption(e.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </S.Button>
            )}
          </>
        );
      })}
      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={addOption}
        disabled={state.form.options.find((e) => !e.option)}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add Option
      </S.Button>
    </div>
  );
};

const validationSchema = {
  options: array()
    .of(
      object()
        .shape({
          id: number().notRequired(),
          option: string().required('required field'),
        })
        .required()
    )
    .required('You must provide options')
    .min(1, 'You must provide options'),
  correctOptions: array()
    .of(
      object()
        .shape({
          id: number().required(),
          pre: string().required('required field'),
          answers: array()
            .of(string().required('required field'))
            .required('Should add answers'),
        })
        .required()
    )
    .required('You must provide correct words')
    .min(1, 'You must provide correct words'),
};
export { validationSchema };

export default WordJoin2;

import { useReducer, useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useGeneralState } from 'context/general-state';
import {
  BasicInput,
  BasicSelect,
  Dropdown,
  Radio,
} from '../../../components/Inputs';
import { ImageSelect, ImageWithText } from '../../../components/Inputs';
import { BasicButton } from '../../../components/Button';
import { Col, Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';
import Modal from '../../../components/Modal';
import Icon from '../../../components/Icon';
import { questionsArray as fullArray } from './Data';

import { useCreateLearnerGoal } from '../../../api-calls/learnersGoals.queries';
import { progressReport as validate } from '../../../validation/schemas';
import { userRoles, marks } from './../../../constants/data-types';
import { LEARNER } from '../../../constants/nav-routes';
import { useAuth } from '../../../context/auth';
import { Learners } from '../../../api-calls';

import * as S from './style';

const initialState = {
  form: {
    confidentInReadingAbility: '',
    canManageEverydayLife: '',
    communicateWellWithOthers: '',
    feelingCloseToOtherPeople: '',
    ableToReadLetters: '',
    dealWithConflict: '',
    haveGotWithReadingGoals: '',
    optimisticAboutFuture: '',
    happyWithProgress: '',
    education: '',
    employment: '',
    // form 2
    firstLanguage: '',
    firstLanguageSecond: '',
    ethnicity: '',
    ethnicitySecond: '',
    genders: [],
    gendersSecond: [],
    interests: [],
    personalGoals: [],
    personalGoalsSecond: '',
    hasDeviceInternetAccess: '',
    usesDeviceType: [],
    usesDeviceTypeSecond: '',
    confidentUsingTechnology: '',
  },
  httpError: '',
  validationErrs: { hasError: false },
  loading: false,
};

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }
  return { ...state, ...value };
}

const Questions = () => {
  const [state, setState] = useReducer(reducer, initialState);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const submitAttempt = useRef(false);
  const questionsArray = fullArray.filter((q) => q.key !== 'happyWithProgress');
  const { state: generalState } = useGeneralState();

  const { user } = useAuth();
  const learnerId =
    user.role === userRoles.COACH ? user.selectedLearnerId : user.id;

  const { id } = useParams();

  const index = id - 1;

  const key = questionsArray[index].key;

  const navigate = useNavigate();

  const {
    form: {
      confidentInReadingAbility,
      canManageEverydayLife,
      communicateWellWithOthers,
      feelingCloseToOtherPeople,
      ableToReadLetters,
      dealWithConflict,
      haveGotWithReadingGoals,
      optimisticAboutFuture,
      happyWithProgress,
      education,
      employment,

      hasDeviceInternetAccess,
      usesDeviceType,
      usesDeviceTypeSecond,
      confidentUsingTechnology,

      firstLanguage,
      firstLanguageSecond,
      ethnicity,
      ethnicitySecond,
      genders,
      gendersSecond,
      interests,
      personalGoals,
      personalGoalsSecond,
    },
    validationErrs,
  } = state;

  const { mutateAsync: createGoal } = useCreateLearnerGoal({
    title: personalGoals[0] || personalGoalsSecond,
    learnerId,
  });

  const setFormData = (value, isSecQuestion) => {
    const _key = isSecQuestion ? `${key}Second` : key;
    if (key === 'firstLanguage' && state.form[key] === 'YES') {
      return setState((prevState) => ({
        form: { ...prevState.form, [_key]: value, firstLanguageSecond: '' },
      }));
    }
    if (key === 'genders' && !isSecQuestion) {
      return setState((prevState) => ({
        form: {
          ...prevState.form,
          [_key]: value,
          gendersSecond: '',
        },
      }));
    }
    if (key === 'ethnicity' && isSecQuestion) {
      return setState((prevState) => ({
        form: { ...prevState.form, [_key]: value, ethnicity: '' },
      }));
    }
    if (key === 'ethnicity' && !isSecQuestion) {
      return setState((prevState) => ({
        form: { ...prevState.form, [_key]: value, ethnicitySecond: '' },
      }));
    }
    if (key === 'personalGoals' && !isSecQuestion) {
      return setState((prevState) => ({
        form: { ...prevState.form, [_key]: value, personalGoalsSecond: '' },
      }));
    }

    setState((prevState) => ({ form: { ...prevState.form, [_key]: value } }));
  };

  const goalsArr = personalGoalsSecond
    ? [...personalGoals, personalGoalsSecond]
    : personalGoals;
  const validateForm = () => {
    try {
      validate({
        ...state.form,
        genders: genders.length ? genders : gendersSecond,
        personalGoals: goalsArr,
        usesDeviceType: usesDeviceType.includes('Other – please specify')
          ? usesDeviceTypeSecond
          : usesDeviceType,
      });
      setState({ validationErrs: { hasError: false } });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({
          validationErrs: { ...error.inner, hasError: true },
        });
      }
      return false;
    }
  };
  const addFormData = async () => {
    if (generalState?.isPlayground) return;
    await Learners.createProgressReport({
      learnerId,
      form: {
        ...state.form,
      },
    });
  };

  const addForm2Data = async () => {
    await Learners.addLearnerInterests({
      learnerId,
      form: {
        communicateWellWithOthers,
        firstLanguage:
          firstLanguage === 'YES' ? 'English' : firstLanguageSecond,
        ethnicity: ethnicity || ethnicitySecond,
        interests,
        genders:
          genders?.length !== 0 || gendersSecond?.length !== 0
            ? [...genders?.filter((e) => e !== 'Let me type'), gendersSecond]
            : [],
        personalGoals: goalsArr,
        usesDeviceType: usesDeviceType.includes('Other – please specify')
          ? [
              ...usesDeviceType.filter(
                (t) => t && t !== 'Other – please specify'
              ),
              usesDeviceTypeSecond,
            ].join(', ')
          : usesDeviceType,
        hasDeviceInternetAccess,
        confidentUsingTechnology,
      },
    });
    goalsArr.forEach((g) => {
      createGoal({
        title: g,
        learnerId,
      });
    });
  };
  const handleSubmit = () => {
    if (key === 'personalGoals') {
      setIsModalVisible(true);
    }
    if (index === questionsArray.length - 1) {
      submitAttempt.current = true;
      const isValid = validateForm();
      if (isValid) {
        addFormData();
        addForm2Data();
        navigate(
          LEARNER.SELECT_STARTING_POINT.replace(':learnerId', learnerId)
        );
      }
    } else {
      navigate(
        LEARNER.PROGRESS_REPORT_QUESTIONS.replace(':id', Number(id) + 1)
      );
    }
  };

  useEffect(() => {
    if (id !== 1) {
      navigate(LEARNER.PROGRESS_REPORT_QUESTIONS.replace(':id', 1), {
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    confidentInReadingAbility,
    canManageEverydayLife,
    communicateWellWithOthers,
    feelingCloseToOtherPeople,
    ableToReadLetters,
    dealWithConflict,
    haveGotWithReadingGoals,
    optimisticAboutFuture,
    happyWithProgress,
    education,
    employment,

    firstLanguage,
    firstLanguageSecond,
    ethnicity,
    ethnicitySecond,
    genders,
    gendersSecond,
    interests,
    personalGoals,
    personalGoalsSecond,
    hasDeviceInternetAccess,
    usesDeviceType,
    usesDeviceTypeSecond,
    confidentUsingTechnology,
  ]);

  const isNextDisabled = () => {
    if (
      key === 'firstLanguage' &&
      state.form[key] === 'NO' &&
      !state.form.firstLanguageSecond
    ) {
      return true;
    }
    if (key === 'genders' && state.form[key]?.length === 0) {
      return true;
    }
    if (key === 'ethnicity') {
      return !ethnicity && !ethnicitySecond;
    }
    if (key === 'personalGoals') {
      return state.form[key]?.length === 0 && !personalGoalsSecond;
    }
    if (key === 'interests' || key === 'genders') {
      return state.form[key]?.length === 0;
    }
    if (key === 'usesDeviceType') {
      if (usesDeviceType.includes('Other – please specify')) {
        return !usesDeviceTypeSecond;
      }
      return state.form[key]?.length === 0;
    }

    return !state.form[key];
  };

  return (
    <>
      <Row style={{ maxWidth: '600px' }}>
        <Col w={[4, 9, 9]}>
          <T.H2 color="neutralMain">{questionsArray[index].question}</T.H2>
        </Col>
      </Row>
      {questionsArray[index].note && (
        <Row style={{ maxWidth: '600px' }}>
          <Col w={[4, 9, 9]} mt="4">
            <T.P color="neutralMain">{questionsArray[index].note}</T.P>
          </Col>
        </Row>
      )}
      <Row style={{ maxWidth: '600px' }}>
        <Col w={[4, 9, 9]} mt="3">
          {(questionsArray[index].type === 'select' ||
            questionsArray[index].type === 'yesNo') &&
            !questionsArray[index].skip && (
              <BasicSelect
                key={key}
                options={questionsArray[index].options}
                selected={state.form[key]}
                handleChange={(value) => setFormData(value)}
                error={validationErrs[key]}
              />
            )}
          {questionsArray[index].type === 'slider' && (
            <S.StyledSliderInput
              value={state.form[key]}
              onChange={(value) => {
                setFormData(value.target.value);
              }}
              aria-label="Default"
              valueLabelDisplay="auto"
              marks={marks}
              max="10"
            />
          )}
          {questionsArray[index].type === 'dropdown' && (
            <Dropdown
              label={questionsArray[index].label}
              key={key}
              m={!questionsArray[index].label && { mt: 4 }}
              w="100%"
              options={questionsArray[index].options}
              selected={state.form[key]}
              handleChange={(value) => setFormData(value)}
              error={validationErrs[key]}
            />
          )}
          {questionsArray[index].type === 'multiDropdown' && (
            <Dropdown
              label={questionsArray[index].label}
              key={key}
              w="100%"
              selected={state.form[key].map((e) => ({
                label: e,
                value: e,
              }))}
              m={!questionsArray[index].label && { mt: 4 }}
              handleChange={(value) => setFormData(value)}
              options={questionsArray[index].options}
              multi
              error={validationErrs[key]}
            />
          )}
          {questionsArray[index].type === 'radio' && (
            <Radio
              label={questionsArray[index].label}
              key={key}
              m={!questionsArray[index].label && { mt: 4 }}
              onChange={(value) => setFormData(value)}
              value={state.form.hasDeviceInternetAccess}
              options={questionsArray[index].options}
              error={validationErrs[key]}
            />
          )}
          {questionsArray[index].type === 'imageWithText' && (
            <ImageWithText
              options={questionsArray[index].options}
              value={state.form.personalGoals}
              setValue={(value) => setFormData(value)}
              error={validationErrs.personalGoals}
            />
          )}
          {questionsArray[index].type === 'imageSelect' && (
            <ImageSelect
              options={questionsArray[index].options}
              values={state.form[key]}
              handleChange={(value) => setFormData(value)}
              error={validationErrs[key]}
            />
          )}
          {questionsArray[index].skip &&
            questionsArray[index].type === 'yesNo' && (
              <BasicSelect
                key={key}
                options={questionsArray[index].options}
                selected={key}
                handleChange={(value) => setFormData(value)}
                error={validationErrs[key]}
              />
            )}
        </Col>
      </Row>

      {((questionsArray[index].secQuestion &&
        (key === 'firstLanguage' ? state.form[key] === 'NO' : true)) ||
        (key === 'usesDeviceType'
          ? state.form[key].includes('Other – please specify')
          : false) ||
        (key === 'genders'
          ? state.form[key]?.includes('Let me type')
          : false)) && (
        <Row style={{ maxWidth: '600px' }}>
          <Col w={[4, 9, 9]} mt="6">
            <BasicInput
              key={key}
              label={questionsArray[index].secQuestion}
              placeholder={questionsArray[index].placeholder}
              value={state.form[key + 'Second']}
              name="question2"
              handleChange={(value) => setFormData(value, true)}
              error={validationErrs[key]}
            />
          </Col>
        </Row>
      )}
      {questionsArray[index].helper && (
        <Row style={{ maxWidth: '600px' }}>
          <Col w={[4, 10, 10]}>
            <T.P mb={3} mt={3} color="neutral90">
              {questionsArray[index].helper}
            </T.P>
          </Col>
        </Row>
      )}
      {validationErrs.hasError && (
        <Row style={{ maxWidth: '600px' }}>
          <Col w={[4, 10, 10]}>
            <T.P color="error" mb={3}>
              Please go back and fill all the required fields
            </T.P>
          </Col>
        </Row>
      )}
      <S.ButtonWrapper mt="6" style={{ maxWidth: '600px' }}>
        <Col w={[4, 9, 9]}>
          <BasicButton
            variant="primary"
            icon="next"
            iconProps={{ width: '24px', height: '24px' }}
            handleClick={handleSubmit}
            disabled={isNextDisabled()}
          />
        </Col>
      </S.ButtonWrapper>
      {questionsArray[index].skip && (
        <Row mt="6" style={{ maxWidth: '600px' }}>
          <Col w={[4, 9, 9]} jc="center">
            <T.Link
              color="neutralMain"
              to={LEARNER.PROGRESS_REPORT_QUESTIONS.replace(
                ':id',
                Number(id) + 1
              )}
              underline
            >
              Skip question
            </T.Link>
          </Col>
        </Row>
      )}
      <Modal visible={isModalVisible} setIsModalVisible={setIsModalVisible}>
        <Row>
          <Col w={[4, 12, 12]} jc="center">
            <Icon icon="like" color="white" width="80" height="80" />
          </Col>
          <Col w={[4, 12, 12]} jc="center" mt={3}>
            <T.P size="med" color="white" weight="regular">
              Goal(s) added
            </T.P>
          </Col>
          <Col w={[4, 12, 12]} mt={4}>
            <BasicButton
              handleClick={() => {
                setIsModalVisible(false);
              }}
              icon="tick"
              variant="secondary"
              iconProps={{ width: '13', height: '11.5' }}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default Questions;

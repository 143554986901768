import * as T from '../../../components/Typography';
import { IconWithParagraph } from '../../../components';

const AddCoachesTips = () => {
  return (
    <>
      <T.H2>Tips for recruiting coaches</T.H2>
      <IconWithParagraph icon="smallTick" ai="flex-start" mt="4">
        <T.P>
          Support all learners to develop their reading skills with the Shannon
          Trust Turning Pages programme.
        </T.P>
      </IconWithParagraph>

      <IconWithParagraph icon="smallTick" ai="flex-start" mt="4">
        <T.P>
          Support all learners to develop their reading skills with the Shannon
          Trust Turning Pages programme.
        </T.P>
      </IconWithParagraph>

      <IconWithParagraph icon="smallTick" ai="flex-start" mt="4">
        <T.P>
          Support all learners to develop their reading skills with the Shannon
          Trust Turning Pages programme.
        </T.P>
      </IconWithParagraph>

      <IconWithParagraph icon="smallTick" ai="flex-start" mt="4">
        <T.P>
          Support all learners to develop their reading skills with the Shannon
          Trust Turning Pages programme.
        </T.P>
      </IconWithParagraph>

      <IconWithParagraph icon="smallTick" ai="flex-start" mt="4">
        <T.P>
          Support all learners to develop their reading skills with the Shannon
          Trust Turning Pages programme.
        </T.P>
      </IconWithParagraph>

      <IconWithParagraph icon="smallTick" ai="flex-start" mt="4">
        <T.P>
          Support all learners to develop their reading skills with the Shannon
          Trust Turning Pages programme.
        </T.P>
      </IconWithParagraph>
    </>
  );
};

export default AddCoachesTips;

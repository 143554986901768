import { useReducer, useRef, useEffect } from 'react';
import { types } from '../../../constants';
import * as T from '../../../components/Typography';
import { Row, Col } from '../../../components/Grid';
import Checkbox from '../../../components/Inputs/Checkbox/';
import * as helper from '../../../helpers';
import { BasicInput } from '../../../components/Inputs';
import { BasicButton } from '../../../components/Button';
import validate from '../../../validation/schemas/coach-update-data';
import { useUpdateCoachInfo } from '../../../api-calls/coaches.queries';
import { cleanEmail } from '../../../helpers';

import * as S from './style';
import { useOrganisationDetails } from 'context/organisation-details';

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }
  return { ...state, ...value };
}

const EditSection = ({
  availableHoursPerWeek,
  bestTime,
  fName,
  lName,
  coachEmail,
  setShowEditSection,
  addressLine1: initAddressLine1,
  addressLine2: initAddressLine2,
  city: initCity,
  phoneNumber: initPhoneNumber,
}) => {
  const { organisationDetails } = useOrganisationDetails();

  const submitAttempt = useRef(false);

  const initialState = {
    form: {
      firstName: fName,
      lastName: lName,
      email: coachEmail,
      availabilityHoursPerWeek: availableHoursPerWeek,
      bestDayTime: bestTime,
      city: initCity,
      phoneNumber: initPhoneNumber,
    },

    validationErrs: {},
    loading: false,
  };
  const [state, setState] = useReducer(reducer, initialState);
  const {
    form: {
      firstName,
      lastName,
      email,
      availabilityHoursPerWeek,
      bestDayTime,
      city,
      phoneNumber,
    },
    validationErrs,
  } = state;

  const {
    mutate: updateCoachInfo,
    error: updateCoachInfoHttpError,
    isLoading,
  } = useUpdateCoachInfo({
    firstName,
    lastName,
    email,
    availabilityHoursPerWeek,
    bestDayTime,
    city,
    phoneNumber,
  });

  const setFormData = (data) => {
    setState((prevState) => ({
      form: { ...prevState.form, ...data },
    }));
  };

  const validateForm = () => {
    try {
      validate(state.form);
      setState({ validationErrs: { hasError: false } });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({
          validationErrs: { ...error.inner, hasError: true },
        });
      }
      return false;
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateForm();
    if (isValid) {
      updateCoachInfo(
        {
          firstName,
          lastName,
          email: cleanEmail(email),
          availabilityHoursPerWeek,
          bestDayTime,
          city,
          phoneNumber,
        },
        {
          onSuccess: () => {
            setShowEditSection(false);
          },
        }
      );
    }
  };

  const handleHoursPerWeek = (checked, value) => {
    setFormData({ availabilityHoursPerWeek: checked ? value : '' });
  };

  const handleTimeOfDay = (checked, value) => {
    setFormData({ bestDayTime: checked ? value : '' });
  };

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.form]);

  return (
    <>
      <Row jc={'center'}>
        <Col w={[4, 6, 6]}>
          <BasicInput
            label="Enter your first name"
            placeholder="Name..."
            type="text"
            value={firstName}
            autoFocus
            handleChange={(firstName) => setFormData({ firstName })}
            error={validationErrs.firstName}
          />
        </Col>
        <Col w={[4, 6, 6]}>
          <BasicInput
            label="Enter your last name"
            placeholder="Name..."
            type="text"
            value={lastName}
            handleChange={(lastName) => setFormData({ lastName })}
            error={validationErrs.lastName}
          />
        </Col>
      </Row>
      <Row jc={'center'}>
        <Col w={[4, 12, 12]} mt="5">
          <BasicInput
            label="Enter your email "
            placeholder="Email..."
            value={email}
            name="email"
            handleChange={(email) => setFormData({ email })}
            error={
              validationErrs.email ||
              updateCoachInfoHttpError?.response?.data?.message
            }
          />
        </Col>
      </Row>
      <Row jc={'center'}>
        <Col w={[4, 12, 12]} mt="5">
          <BasicInput
            label="Organisation name"
            value={organisationDetails?.name}
            disabled
          />
        </Col>
      </Row>
      <Row jc={'center'}>
        <Col w={[4, 6, 6]} mt="5">
          <BasicInput
            label="City"
            value={city}
            name="city"
            handleChange={(city) => setFormData({ city })}
            error={validationErrs.city}
          />
        </Col>
        <Col w={[4, 6, 6]} mt="5">
          <BasicInput
            label="Phone"
            value={phoneNumber}
            name="phoneNumber"
            handleChange={(phoneNumber) => setFormData({ phoneNumber })}
            error={validationErrs.phoneNumber}
          />
        </Col>
      </Row>
      <Row jc={'center'}>
        <Col w={[4, 12, 12]} mt="5">
          <Row jc={'center'}>
            <Col w={[4, 6, 6]}>
              <Row jc={'center'}>
                <Col w={[4, 12, 12]} mb={2}>
                  <T.H3 color="neutralMain">
                    How many hours per week are you available?
                  </T.H3>
                  <Row jc={'center'}>
                    <Col w={[4, 12, 12]}>
                      <Checkbox
                        ai="center"
                        plain
                        font="regular"
                        handleChange={(checked) =>
                          handleHoursPerWeek(
                            checked,
                            types.availabilityHoursPerWeek.ONE_TO_TWO_HOURS
                          )
                        }
                        checked={
                          availabilityHoursPerWeek ===
                          types.availabilityHoursPerWeek.ONE_TO_TWO_HOURS
                        }
                        label={
                          <S.StyledP>
                            {types.availabilityHoursPerWeek.ONE_TO_TWO_HOURS}
                          </S.StyledP>
                        }
                        error={validationErrs.availabilityHoursPerWeek}
                        hideErrorMsg
                      />

                      <Checkbox
                        ai="center"
                        plain
                        bgColor="white"
                        font="regular"
                        handleChange={(checked) =>
                          handleHoursPerWeek(
                            checked,
                            types.availabilityHoursPerWeek.THREE_TO_FOUR_HOURS
                          )
                        }
                        checked={
                          availabilityHoursPerWeek ===
                          types.availabilityHoursPerWeek.THREE_TO_FOUR_HOURS
                        }
                        label={
                          <S.StyledP>
                            {types.availabilityHoursPerWeek.THREE_TO_FOUR_HOURS}
                          </S.StyledP>
                        }
                        error={validationErrs.availabilityHoursPerWeek}
                        hideErrorMsg
                      />

                      <Checkbox
                        ai="center"
                        plain
                        font="regular"
                        handleChange={(checked) =>
                          handleHoursPerWeek(
                            checked,
                            types.availabilityHoursPerWeek.FOUR_PLUS_HOURS
                          )
                        }
                        checked={
                          availabilityHoursPerWeek ===
                          types.availabilityHoursPerWeek.FOUR_PLUS_HOURS
                        }
                        label={
                          <S.StyledP>
                            {types.availabilityHoursPerWeek.FOUR_PLUS_HOURS}
                          </S.StyledP>
                        }
                        error={validationErrs.availabilityHoursPerWeek}
                        hideErrorMsg
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              {validationErrs.availabilityHoursPerWeek && (
                <Row>
                  <T.P ml="3">{validationErrs.availabilityHoursPerWeek}</T.P>
                </Row>
              )}
            </Col>

            <Col w={[4, 6, 6]}>
              <Row jc={'center'}>
                <Col w={[4, 12, 12]} mb="2">
                  <T.H3 color="neutralMain">
                    What time of day is best for you?
                  </T.H3>
                </Col>
              </Row>

              <Row jc={'center'}>
                <Col w={[4, 12, 12]}>
                  <Checkbox
                    ai="center"
                    plain
                    font="regular"
                    handleChange={(checked) =>
                      handleTimeOfDay(checked, types.bestDayTime.MORNING)
                    }
                    checked={bestDayTime === types.bestDayTime.MORNING}
                    label={
                      <S.StyledP>
                        {helper.toTitleCase(types.bestDayTime.MORNING)}
                      </S.StyledP>
                    }
                    error={validationErrs.bestDayTime}
                    hideErrorMsg
                  />
                  <Checkbox
                    ai="center"
                    plain
                    font="regular"
                    handleChange={(checked) =>
                      handleTimeOfDay(checked, types.bestDayTime.AFTERNOON)
                    }
                    checked={bestDayTime === types.bestDayTime.AFTERNOON}
                    label={
                      <S.StyledP>
                        {helper.toTitleCase(types.bestDayTime.AFTERNOON)}
                      </S.StyledP>
                    }
                    error={validationErrs.bestDayTime}
                    hideErrorMsg
                  />
                  <Checkbox
                    ai="center"
                    plain
                    font="regular"
                    handleChange={(checked) =>
                      handleTimeOfDay(checked, types.bestDayTime.EVENING)
                    }
                    checked={bestDayTime === types.bestDayTime.EVENING}
                    label={
                      <S.StyledP>
                        {helper.toTitleCase(types.bestDayTime.EVENING)}
                      </S.StyledP>
                    }
                    error={validationErrs.bestDayTime}
                    hideErrorMsg
                  />
                  <Checkbox
                    ai="center"
                    plain
                    font="regular"
                    handleChange={(checked) =>
                      handleTimeOfDay(checked, types.bestDayTime.ANYTIME)
                    }
                    checked={bestDayTime === types.bestDayTime.ANYTIME}
                    label={
                      <S.StyledP>
                        {helper.toTitleCase(types.bestDayTime.ANYTIME)}
                      </S.StyledP>
                    }
                    error={validationErrs.bestDayTime}
                    hideErrorMsg
                  />
                </Col>
              </Row>
              {(validationErrs.bestDayTime ||
                updateCoachInfoHttpError?.response?.data?.message) && (
                <Row>
                  <T.P ml="3">
                    {validationErrs.bestDayTime ||
                      updateCoachInfoHttpError?.response?.data?.message}
                  </T.P>
                </Row>
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      <Row jc="center" mt="5">
        <Col w={[4, 6, 6]} mbM="4">
          <BasicButton
            variant="secondary"
            handleClick={() => {
              setShowEditSection(false);
            }}
          >
            <T.P color="white" weight="bold">
              Cancel
            </T.P>
          </BasicButton>
        </Col>
        <Col w={[4, 6, 6]}>
          <BasicButton
            variant="primary"
            // disabled={validationErrs}
            loading={isLoading}
            type="submit"
            handleClick={handleSubmit}
          >
            <T.P color="white" weight="bold">
              Save
            </T.P>
          </BasicButton>
        </Col>
      </Row>
    </>
  );
};

export default EditSection;

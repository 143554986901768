import styled from '@emotion/styled';
import { Col } from '../../../components/Grid';
import MuiModal from '@mui/material/Modal';

export const Modal = styled(MuiModal)`
  max-width: 100%;
  height: auto;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  margin-left: ${({ theme }) => theme.spacings[6]};
  margin-right: ${({ theme }) => theme.spacings[6]};
  @media (max-width: 580px) {
    max-width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    width: 30%;
  }

  @media (max-width: 580px) {
    button {
      width: 100%;
    }
  }
`;

export const Container = styled.div`
  max-width: 100%;
  background-color: white;
  border: none;
  box-shadow: none;
  padding: 32px;
  @media (max-width: 580px) {
    max-width: 100%;
  }
`;

export const VideoWrapper = styled('div')`
  width: 100%;
  max-width: 548px;
  margin-top: ${({ theme }) => theme.spacings[5]};
`;

export const TableTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 400px;
  width: 100%;

  .ant-table {
    overflow: auto;
  }
  .ant-table table {
    font-size: 1rem;
    font-weight: 400;
  }

  .ant-picker-input > input::placeholder {
    font-size: 1rem;
  }
`;

export const CoachesInvitesTable = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  .ant-table {
    overflow: auto;
  }
  .ant-table table {
    font-size: 1rem;
    font-weight: 400;
  }

  .ant-picker-input > input::placeholder {
    font-size: 1rem;
  }
`;

export const CopyLinkWrapper = styled('button')`
  margin-top: ${({ theme }) => theme.spacings[3]};
  display: flex;
  cursor: pointer;
  border: none;
  background: none;
`;

export const CopyIconWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.neutralLight100};
  box-shadow: 0px 1px 3px -1px rgba(4, 13, 38, 0.1),
    0px 2px 5px -1px rgba(4, 13, 38, 0.11);
  border-radius: 8px;
`;

export const CopyTextWrapper = styled('div')`
  margin-left: ${({ theme }) => theme.spacings[2]};
`;

export const CardWrapper = styled('div')`
  width: 100%;
  padding: ${({ theme }) => theme.spacings[6]};
  border: 1px solid ${({ theme }) => theme.colors.neutral50};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.secondaryLight};
  margin-bottom: ${({ theme }) => theme.spacings[4]};
  textarea {
    background-color: transparent;
  }
`;

export const RightWrapper = styled(Col)`
  padding-left: ${({ theme }) => theme.spacings[6]};
`;

export const LeftWrapper = styled(Col)`
  padding-right: ${({ theme }) => theme.spacings[6]};
`;

export const RecruitmentFormWrapper = styled('div')`
  background-color: white;
  border-radius: 8px;
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const IconsWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

export const LinkTextWrapper = styled.div`
  width: 100%;
`;

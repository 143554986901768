import styled from '@emotion/styled';
import { setMargin } from '../../../helpers';

export const Wrapper = styled.div`
  width: 100%;
  padding: 0 32px;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 64px;
  gap: 24px;
`;

export const PlayButtonWrapper = styled.div`
  ${setMargin}
  display: flex;
  align-items: center;
  max-width: 326px;
  width: 100%;
`;

export const LogoutButton = styled.button`
  all: unset;
  color: ${({ theme }) => theme.colors.tertiaryMain};
  font-size: 16px;
  font-weight: bold;
  margin-top: ${({ theme }) => theme.spacings[1]};
  text-align: center;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
`;

export const LogoutButtonWrapper = styled.div`
  ${setMargin}
  display: flex;
  align-items: center;
  min-width: 326px;
  height: 64px;

  ${({ theme }) => theme.media.mobile} {
    min-width: 217px;
  }
`;

import { useState, useMemo } from 'react';
import { debounce } from 'lodash';
import * as T from '../../../components/Typography';
import { useNavigate } from 'react-router-dom';
import { getLearnerTableColumns } from './columns';
import { navRoutes, userRoles } from '../../../constants';
import { Row, Col } from 'components/Grid';
import getCountText from 'utils/getCountText';

import {
  useGetLearnersCount,
  useGetLearnersQuery,
} from '../../../api-calls/learners.queries';
import {
  Button,
  Inputs,
  StatsChart,
  StatsChartLayout,
} from '../../../components';

import { BasicButton } from 'components/Button';
import useCsvDownload from './../../../Hooks/useCsvDownload';
import * as S from './style';
import { useAuth } from 'context/auth';
import TabsCommonTable from '../../../components/CommonTables/TabsCommonTable';
import { useGetStats } from 'api-calls/stats.queries';

import SelectSortOptionComponent from 'components/SelectSortOptionComponent';

const learnerTableEmptyState = {
  title: 'You currently do NOT have any learners',
  subTitle:
    'Currently, there are no learners registered. Begin inviting learners to embark on their learning journey with us',
  buttonText: 'Setup  a new learner',
  buttonLink: navRoutes.COMMON.ADD_LEARNER,
  disabled: false,
};

const pageSize = 20;
const AllLearners = () => {
  const [page, setPage] = useState(1);

  const navigate = useNavigate();

  const {
    user: { role },
  } = useAuth();

  const [learnerStatus, setLearnerStatus] = useState('active_status');

  const [searchValue, setSearchValue] = useState('');
  const [debounceSearchValue, setDebounceSearchValue] = useState('');

  const { stats } = useGetStats();

  const {
    learningTime: { totalSessionTimeHours, selfLearningPercentage },
    totalCompletedMilestones,
  } = stats || {
    learningTime: {
      totalSessionTimeHours: 0,
      totalSelfLearningTimeHours: 0,
    },
    totalCompletedMilestones: 0,
  };

  const [sortInfo, setSortInfo] = useState({
    key: 'created_at',
    order: 'ASC',
  });

  const isAdmin = role === userRoles.ADMIN;

  const {
    data: { data: learners = [] } = {},
    error: getLearnersError,
    isLoading: loading,
  } = useGetLearnersQuery({
    page,
    limit: pageSize,
    searchValue: debounceSearchValue,
    learnerStatus,
    sortField: sortInfo.key,
    sortOrder: sortInfo.order,
  });

  const {
    data: {
      data: learnersCount = {
        processingCount: '',
        activeCount: '',
        inactiveCount: '',
        total: '',
        searchCount: {
          processingCount: '',
          activeCount: '',
          inactiveCount: '',
        },
      },
    } = {},
    error: getLearnersCountError,
    isLoading: loadingCount,
  } = useGetLearnersCount({
    search: debounceSearchValue,
  });

  const processingCount = learnersCount?.processingCount || 0;
  const activeCount = learnersCount?.activeCount || 0;
  const inactiveCount = learnersCount?.inactiveCount || 0;
  const totalCount = learnersCount?.total || 0;

  const searchCount = learnersCount?.searchCount || {};

  const searchProcessingCount = searchCount?.processingCount || 0;
  const searchActiveCount = searchCount?.activeCount || 0;
  const searchInactiveCount = searchCount?.inactiveCount || 0;

  const shouldShowEmptyState = totalCount === 0;
  const apiLoading = loading || loadingCount;

  const commonTableProps = {
    loading: apiLoading,
    emptyState: shouldShowEmptyState && learnerTableEmptyState,
    setPage,
    data: learners,
    totalRecords: learners?.[0]?.total || 0,
  };

  const [data, handleClick] = useCsvDownload('/csv/learners');

  const searchValueFunctionDebounced = useMemo(
    () => debounce((value) => setDebounceSearchValue(value), 500),
    [setDebounceSearchValue]
  );

  if (getLearnersError?.message || getLearnersCountError?.message) {
    return (
      <T.P mb={2} mt={2} color="error">
        {getLearnersError?.message || getLearnersCountError?.message}
      </T.P>
    );
  }

  return (
    <S.Wrapper>
      <Row mb={7} mbT={5} inner jc="space-between" ai="center">
        <S.TableButtonWrapper>
          <S.TableTitleDescriptionWrapper>
            <T.H1 mb="8px">Learners</T.H1>
            <T.P>Your Learning Hub: Explore, Learn, and Grow with Us!</T.P>
          </S.TableTitleDescriptionWrapper>

          {!isAdmin && (
            <BasicButton
              width="230px"
              ml="16px"
              mlM="0"
              mtM="16px"
              icon="userWithArrow"
              iconProps={{ width: '32px', height: '32px' }}
              handleClick={() => navigate(navRoutes.COMMON.ADD_LEARNER)}
            >
              Setup a new learner
            </BasicButton>
          )}
        </S.TableButtonWrapper>
      </Row>

      <S.StatsWrapper>
        <T.H2 mb="16px">Statistics</T.H2>

        <StatsChartLayout variant="left">
          <StatsChart
            statChartType="hours"
            value={totalSessionTimeHours}
            backgroundMode="dark"
          />
          <StatsChart
            statChartType="totalCount"
            value={totalCount}
            backgroundMode="light"
          />

          <StatsChart
            statChartType="selfLearningTime"
            value={`${selfLearningPercentage}%`}
            backgroundMode="light"
          />
          <StatsChart
            statChartType="usersAwaitingCount"
            value={processingCount}
            backgroundMode="light"
          />
          <StatsChart
            statChartType="milestone"
            value={Number(totalCompletedMilestones)}
            backgroundMode="light"
          />
        </StatsChartLayout>
      </S.StatsWrapper>

      <Row ai="center" mb={5} mtT={5}>
        <Col w={[4, 12, 4.5]} mbT={4}>
          <T.H2 color="neutralMain" size="medLarge">
            Learners
          </T.H2>
        </Col>

        <Col w={[4, 4, 2.5]} mbM={2}>
          <SelectSortOptionComponent
            sortOptions={[
              {
                label: 'Assigned Coach First',
                value: 'assigned-coach-first',
              },
              {
                label: 'Unassigned Coach First',
                value: 'unassigned-coach-first',
              },
              {
                label: 'Oldest to Newest',
                value: 'oldest-to-newest',
              },
              {
                label: 'Newest to Oldest',
                value: 'newest-to-oldest',
              },
            ]}
            onChange={(selectedValue) => {
              if (selectedValue === 'assigned-coach-first') {
                setSortInfo({
                  key: 'coach',
                  order: 'ASC',
                });
              } else if (selectedValue === 'unassigned-coach-first') {
                setSortInfo({
                  key: 'coach',
                  order: 'DESC',
                });
              } else if (selectedValue === 'oldest-to-newest') {
                setSortInfo({
                  key: 'created_at',
                  order: 'ASC',
                });
              } else if (selectedValue === 'newest-to-oldest') {
                setSortInfo({
                  key: 'created_at',
                  order: 'DESC',
                });
              }
            }}
          />
        </Col>

        <Col w={[4, 4, 2.5]} mbM={2}>
          <Inputs.BasicInput
            w="100%"
            value={searchValue}
            searchIcon
            handleChange={(value) => {
              setSearchValue(value);
              setPage(1);
              searchValueFunctionDebounced(value);
            }}
            placeholder="Search..."
            m={{ ml: '0' }}
          />
        </Col>

        <Col w={[4, 4, 2.5]} mbM={2}>
          <Button.BasicButton
            width="100%"
            handleClick={handleClick}
            loading={data.loading}
            variant="tertiary"
            icon={'export'}
          >
            Export data
          </Button.BasicButton>
        </Col>
      </Row>

      <TabsCommonTable
        page={page}
        tabs={[
          {
            key: '1',
            title: 'Approved',
            count: activeCount
              ? getCountText(
                  Number(activeCount),
                  Number(searchActiveCount),
                  !!debounceSearchValue,
                  loading
                )
              : 0,
            columns: getLearnerTableColumns({
              showAdminFields: isAdmin,
              showSingleStatus: true,
              showAssignedCoachField: true,
              page,
            }),
            ...commonTableProps,
            totalRecords: Number(searchActiveCount) || Number(activeCount),
          },
          {
            key: '2',
            title: 'Awaiting',
            count: processingCount
              ? getCountText(
                  Number(processingCount),
                  Number(searchProcessingCount),
                  !!debounceSearchValue,
                  loading
                )
              : 0,
            columns: getLearnerTableColumns({
              showAdminFields: isAdmin,
              showSingleStatus: true,
              showStatusFiled: true,
              page,
            }),
            ...commonTableProps,
            totalRecords:
              Number(searchProcessingCount) || Number(processingCount),
          },
          {
            key: '3',
            title: 'Deactivated',
            columns: getLearnerTableColumns({
              showAdminFields: isAdmin,
              showSingleStatus: true,
              showAssignedCoachField: true,
              page,
            }),
            count: inactiveCount
              ? getCountText(
                  Number(inactiveCount),
                  Number(searchInactiveCount),
                  !!debounceSearchValue,
                  loading
                )
              : 0,
            ...commonTableProps,
            totalRecords: Number(searchInactiveCount) || Number(inactiveCount),
          },
        ]}
        tabsProps={{
          onChange: (key) => {
            setPage(1);
            if (key === '1') return setLearnerStatus('active_status');
            if (key === '2') return setLearnerStatus('processing_status');
            return setLearnerStatus('inactive_status');
          },
        }}
      />
    </S.Wrapper>
  );
};

export default AllLearners;

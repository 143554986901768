import { Grid, Typography as T, ProgressCard } from '../../../components';
import { useAuth } from '../../../context/auth';
import { navRoutes } from '../../../constants';

import { TRAINING_DATA } from '../../Coach/Training/training-content';

import { BasicButton } from 'components/Button';
import * as S from './style';
import {
  useGetAllCustomTrainingSteps,
  useGetAllCustomTrainingResources,
} from '../../../api-calls/custom-training.queries';
import StepsList from './StepsList';
import { FurtherResources } from 'components/FurtherResources';
import PlayWithTheTool from 'components/PlayWithTheTool';

const { Row, Col } = Grid;

const FacilitatorTrainingCustomization = () => {
  const {
    user: { id, organisationId },
  } = useAuth();

  const steps = useGetAllCustomTrainingSteps({ organisationId });
  const { customTrainingResources } = useGetAllCustomTrainingResources({
    organisationId,
  });

  return (
    <>
      <Row mb="3">
        <Col w={[4, 12, 7]} mb="6">
          <T.H1 weight="bold" color="neutralMain">
            Coach training
          </T.H1>
          <T.P mb="3">
            Here is everything you need to know in order to deliver Turning
            Pages in your organisation
          </T.P>
          <ProgressCard
            isEditModeActive={true}
            id={id}
            data={TRAINING_DATA}
            completionMessage="🎉 Training complete"
          />
          <T.H2 weight="bold" color="neutralMain" mt="6">
            Custom steps
          </T.H2>
          <T.P mb="6">
            Do you have further training that is essential for your volunteers
            to complete? Here you can add and manage further steps specific to
            your organisation.
          </T.P>
          <StepsList
            id={id}
            data={steps.customTrainingSteps?.sort((a, b) => a.order - b.order)}
          />

          <Row inner>
            <Col w={[4, 12, 12]} mt="6">
              <BasicButton
                variant="primary"
                to={navRoutes.FACILITATOR.TRAINING_CUSTOMIZATION_ADD_STEP}
                aria-label="Add step"
                type="button"
                id="add-step"
                maxWidth="300px"
              >
                Add step
              </BasicButton>
            </Col>
          </Row>
        </Col>
        <Col w={[4, 12, 5]}>
          <PlayWithTheTool />

          <S.Card bg="quaternaryLight" mt="4">
            <Row inner>
              <Col w={[4, 12, 12]}>
                <T.H2 weight="bold" color="neutralMain" mb="4">
                  Join our Community of Practice webinars
                </T.H2>
                <T.P>
                  If your coaches would like any further training, get direct
                  advice from the Turning Pages, ask questions, or speak to
                  fellow coaches from other organisations, please remember they
                  can attend our monthly Community of Practice webinar. They are
                  totally free to join and they can come to any or none!
                </T.P>
              </Col>
            </Row>
            <Row mt="4" inner>
              <Col w={[4, 6, 8]}>
                <BasicButton
                  variant="primary"
                  to={navRoutes.EXTERNAL.JOIN_OUR_COMMUNITY}
                  aria-label="Find out more"
                  type="button"
                  id="find-out-more"
                  external
                >
                  Find out more
                </BasicButton>
              </Col>
            </Row>
          </S.Card>
          <Row inner mt="6">
            <Col w={[4, 12, 12]}>
              <FurtherResources
                customTrainingResources={customTrainingResources}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default FacilitatorTrainingCustomization;
